import React from 'react';
import { createCn } from 'bem-react-classname';

import { Typography } from '@alfalab/core-components/typography';

import InvestmentsCardAccount from '#/src/components/investments/investmens-authorization/investments-card-account';

const cn = createCn('investments');

const InvestmentsRegistration: React.FC = () => (
    <div className={cn('container')}>
        <Typography.Title
            color='primary'
            tag='h2'
            font='system'
            view='small'
            weight='medium'
            defaultMargins={true}
        >
            Введите номер счёта
            <br />
            или карты Альфа-Банка
        </Typography.Title>
        <Typography.Text className={cn('text')} color='secondary' tag='div' view='primary-medium'>
            Чтобы открыть брокерский счёт
        </Typography.Text>
        <InvestmentsCardAccount />
    </div>
);

export default InvestmentsRegistration;
