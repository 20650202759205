import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Input } from '@alfalab/core-components/input';
import { Typography } from '@alfalab/core-components/typography';

import ServerErrorsNotificationNew from '#/src/components/ui/server-errors-notification/server-errors-notification-new';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { LOGIN_INPUT_MAX_LENGTH } from '#/src/lib/form-controls-const';
import { Routes } from '#/src/models';
import { useRequestWebAuthnRegistrationMutation } from '#/src/store/api/webauthn-api';
import { selectIsWebAuthnRegEnabled } from '#/src/store/redux/app/selectors';
import { webAuthnStatusSet } from '#/src/store/redux/app/slice';
import { getWebAuthnFlag, selectWebAuthnError } from '#/src/store/redux/webauthn/selectors';
import { webAuthnErrorCleared } from '#/src/store/redux/webauthn/slice';

import './webauthn-reg.css';

const cn = createCn('webauthn-reg');

const WebAuthnReg: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const [requestWebAuthnRegistration] = useRequestWebAuthnRegistrationMutation();

    const error = useAppSelector(selectWebAuthnError);
    const webAuthnRegEnabled = useAppSelector(selectIsWebAuthnRegEnabled);
    const webAuthnFlag = getWebAuthnFlag();

    const [name, setName] = useState('');
    const [displayName, setDisplayName] = useState('');

    useEffect(() => {
        if (!webAuthnFlag || !webAuthnRegEnabled) {
            navigate({
                pathname: Routes.LOGIN,
                search,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleButtonClick = (route: string, clearState?: boolean) => {
        if (clearState) {
            dispatch(webAuthnStatusSet(false));
        }
        navigate({
            pathname: route,
            search,
        });
    };

    const requestRegistration = (name: string, displayName: string) => {
        const credentials = {
            username: name,
            displayName,
        };

        requestWebAuthnRegistration(credentials);
    };

    const handleInputChange = (value: string) => {
        setName(value);
    };

    const handleInputChange2 = (value: string) => {
        setDisplayName(value);
    };

    return (
        <div className={cn('container')}>
            {error && (
                <ServerErrorsNotificationNew
                    errorMessage={error}
                    onClose={() => {
                        dispatch(webAuthnErrorCleared());
                    }}
                />
            )}
            <Typography.TitleResponsive view='large' font='styrene' tag='h1'>
                Добро пожаловать
                <br />
                в&nbsp;Альфа-Онлайн
            </Typography.TitleResponsive>
            <Typography.Text
                view='primary-medium'
                color='secondary'
                className={cn('text')}
                tag='div'
            >
                Вам доступен быстрый вход.
            </Typography.Text>
            <Input
                value={name}
                autoFocus={true}
                maxLength={LOGIN_INPUT_MAX_LENGTH}
                label='Name'
                clear={true}
                size='xl'
                block={true}
                className={cn('button')}
                onChange={(e, { value }) => handleInputChange(value)}
            />
            <Input
                value={displayName}
                autoFocus={true}
                maxLength={LOGIN_INPUT_MAX_LENGTH}
                label='DisplayName'
                clear={true}
                size='xl'
                block={true}
                className={cn('button')}
                onChange={(e, { value }) => handleInputChange2(value)}
            />
            <Button
                view='primary'
                block={true}
                onClick={() => requestRegistration(name, displayName)}
                className={cn('button')}
            >
                Регистрация
            </Button>
            <Button
                view='secondary'
                block={true}
                onClick={() => handleButtonClick(Routes.LOGIN, true)}
                className={cn('button')}
            >
                Войти другим способом
            </Button>
        </div>
    );
};

export default WebAuthnReg;
