import { createSelector } from 'reselect';

import { ApplicationState } from '#/src/store/types';

export const getFingerprintState = (state: ApplicationState) => state.Fingerprint;

export const selectFingerPrintCredentials = createSelector([getFingerprintState], (fingerprint) => {
    if (!fingerprint) return null;

    return {
        fingerprint: {
            canvas_fingerprint_v1: fingerprint.canvasFingerprintV1,
            webgl_fingerprint_v1: fingerprint.webglFingerprintV1,
        },
    };
});
