import React from 'react';

export type ModalType = 'inform' | 'connections' | 'chat' | 'voip' | 'alt-login';

export type ModalsContextType = {
    currentModal: ModalType | null;
    onModalOpen: (modalName: ModalType | null) => void;
    chatAuthSessionId?: string;
    onSetChatSessionId: (session: string | undefined) => void;
    openChat: VoidFunction;
    openVoip: VoidFunction;
};

export const ModalsContext = React.createContext<ModalsContextType | null>(null);
