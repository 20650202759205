import React, { ChangeEvent } from 'react';
import { createCn } from 'bem-react-classname';

import { Input as NewInput, InputProps } from '@alfalab/core-components/input';
import Input from 'arui-feather/input';
import Label from 'arui-feather/label';

import { checkPhone, formatPhoneNumber } from '#/src/lib/formatters';

import './phone-input.css';

interface Props extends Omit<InputProps, 'onChange'> {
    phone: string;
    onChange?(value?: string | undefined, event?: ChangeEvent<any> | undefined): void;
    onClear?(): void;
    resetError?: boolean;
    label?: string;
    isNewUi?: boolean;
    hideLabel?: boolean;
}

const cn = createCn('phone-input');

const fixPhoneValue = (phoneValue: string) => {
    if (!phoneValue || phoneValue.length === 0) {
        return ('+7');
    }

    return phoneValue;
};

const PhoneInput: React.FC<Props> = ({
    width,
    size,
    phone,
    maxLength,
    error,
    resetError = false,
    label,
    isNewUi,
    clear,
    onClear,
    hideLabel,
    onChange,
}) => {
    const handlePhoneChange = (value?: string | undefined) => {
        if (onChange && value) {
            onChange(formatPhoneNumber(value));
        }
    };

    const handlePhonePaste = (event?: React.ClipboardEvent) => {
        event?.preventDefault();
        if (onChange) {
            const phoneValue = event?.clipboardData.getData('text');

            if (phoneValue) {
                onChange(checkPhone(phoneValue));
            }
        }
    };

    const inputId = 'phoneInput';

    return isNewUi ? (
        <div className={cn('wrapper')}>
            {!hideLabel && (
                <div className={cn('label')}>
                    <Label>{label}</Label>
                </div>
            )}
            <NewInput
                id={inputId}
                inputClassName={cn()}
                width={width}
                block={true}
                clear={clear}
                size={size}
                value={fixPhoneValue(phone)}
                maxLength={maxLength}
                onClear={onClear}
                onPaste={handlePhonePaste}
                onChange={(event, { value }) => handlePhoneChange(value)}
                error={error}
                type='tel'
                placeholder='+7'
            />
        </div>
    ) : (
        <Input
            className={cn()}
            label={label}
            size={size as 's' | 'm' | 'l' | 'xl'}
            id={inputId}
            type='tel'
            width='available'
            value={fixPhoneValue(phone)}
            maxLength={maxLength}
            onChange={handlePhoneChange}
            onPaste={handlePhonePaste}
            error={error}
            resetError={resetError || false}
            placeholder='+7'
        />
    );
}

export default PhoneInput;
