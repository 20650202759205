import { AuthorizationState } from '#/src/store/redux/authorization/types';

export const AuthorizationInitialState: AuthorizationState = {
    login: '',
    password: '',
    requestId: '',
    isLimitsClick: false,
    formStatus: '',
    formError: {
        login: '',
        password: '',
        id: '',
        message: '',
        status: '',
    },
    otp: {
        referenceId: '',
    },
    serverErrors: [],
    authUrl: '',
    maskedLogin: '',
    passwordType: '',
    type: '',
    pcUserId: '',
}
