import { Middleware } from 'redux';

export function updateCookie(name: string, value: string, expires?: string) {
    let updatedCookie = `${name}=${value}`;
    let expiresCookie = expires;

    if (typeof expiresCookie === 'undefined') {
        const date = new Date();

        date.setFullYear(date.getFullYear() + 1);
        expiresCookie = date.toUTCString();
    }

    updatedCookie += `;expires=${expiresCookie}`;

    document.cookie = updatedCookie;
}

/* eslint-enable prefer-template */

export const CAPS_COOKIE = 'capsLockPushed';

let prevState: string;

/* eslint-disable consistent-return, arrow-parens */

export const updateCookieMiddleware: Middleware  = (store) => (next) => (action) => {
    if (typeof window === 'undefined') {
        return;
    }

    const result = next(action);
    const state = store.getState();

    if (!prevState) {
        updateCookie(CAPS_COOKIE, 'false');
    }

    if (state.App.capsLockPushed) {
        updateCookie(CAPS_COOKIE, 'true');
    }

    prevState = state;

    return result;
};

type AttributesType = {
    expires?: string;
    domain?: string;
    path?: string;
    secure?: boolean;
    sameSite?: string;
    maxAge?: string;
}

export const setCookie = (name: string, value: string, attributes: AttributesType = {} ) => {
    let cookie = `${name}=${value}`;

    if (attributes.expires) {
        cookie += `; Expires=${attributes.expires}`;
    }
    if (attributes.domain) {
        cookie += `; Domain=${attributes.domain}`;
    }
    if (attributes.path) {
        cookie += `; Path=${attributes.path}`;
    }
    if (attributes.secure) {
        cookie += '; Secure';
    }
    if (attributes.sameSite) {
        cookie += `; SameSite=${attributes.sameSite}`;
    }
    if (attributes.maxAge) {
        cookie += `; max-age=${attributes.maxAge}`;
    }
    document.cookie = cookie;
};

export const removeCookie = (name: string, attributes?: AttributesType) => {
    setCookie(name, '', { ...attributes, maxAge: '-1' });
};

/* eslint-enable consistent-return, arrow-parens */
