export const checkContextRoot = (location: any, contextRoot?: string) => {
    const newLocation = JSON.parse(JSON.stringify(location));
    const array = newLocation.pathname.split('/');
    const path = array.pop();

    if (path) {
        const basename = array.join('/')

        if (basename !== contextRoot) {
            newLocation.pathname = `${contextRoot}/${path}`
        }
    }

    return newLocation;
}
