/** Точка добавление глобальных обработчиков нативных событий. */
const addGlobalEventListeners = () => {
  // Объявление function(){} вызывает сразу обработчик не дожидаясь первого срабатывания события.
  // eslint-disable-next-line prefer-arrow-callback, func-names
  window.addEventListener('resize', (function () {
    const calcVertialHeight = () => { document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`) };

    calcVertialHeight();

    return calcVertialHeight;
  })());
};

export default addGlobalEventListeners;
