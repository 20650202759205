
import React, { ComponentType } from 'react';

interface Attribute {
    name: string;
    value: string;
}

// eslint-disable-next-line func-names
export default function<P>(WrappedComponent: ComponentType<P>, attrs: Attribute[]) {
    return class extends React.Component<P> {
        componentNode= React.createRef<any>();

        componentDidMount() {
            attrs.forEach(this.addAttribute.bind(this));
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={(node: any) => {
                        this.componentNode = node;
                    }}
                />
            );
        }

        addAttribute({ name, value }: Attribute) {
            // TODO проверить - возможно this.componentNode.control избыточен и можно удалить его
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const node = this.componentNode.control || this.componentNode;

            node.setAttribute(name, value);
        }
    };
}
