import React from 'react';

import PincodeBrowser from '#/src/components/pincode/pincode-browser';
import PincodeMobile from '#/src/components/pincode/pincode-mobile';
import DesignCheck from '#/src/components/ui/design-check';

const Pincode = () => (
    <DesignCheck defaultComponent={<PincodeBrowser />} defaultMobileComponent={<PincodeMobile />} />
);

export default Pincode;
