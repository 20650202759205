import { createApi } from '@reduxjs/toolkit/query/react';

import { customFetchBaseQuery } from '#/src/store/base-query';
import { ResponseWithData } from '#/src/store/base-query/types';
import { BaseInitializeCredentials } from '#/src/store/types';
import { BiometryCredentials } from '#/src/types/interfaces';
import { Endpoint, HttpMethod } from '#/src/utils';

export const biometryApi = createApi({
    reducerPath: 'biometryApi',
    baseQuery: customFetchBaseQuery(),
    endpoints: (build) => ({
        initializeBiometry: build.mutation<
            { data: Record<string, never> },
            BaseInitializeCredentials
        >({
            query: (data) => ({
                url: Endpoint.OID_INITIALIZE,
                method: HttpMethod.POST,
                body: data,
            }),
        }),
        requestBiometryAuthorization: build.mutation<ResponseWithData['data'], BiometryCredentials>(
            {
                query: (data) => ({
                    url: Endpoint.BIOMETRY,
                    method: HttpMethod.POST,
                    body: data,
                }),
            },
        ),
    }),
});

export const endpointBiometryAuthorization =
    biometryApi.endpoints.requestBiometryAuthorization.initiate;

export const { useInitializeBiometryMutation, useRequestBiometryAuthorizationMutation } =
    biometryApi;
