import React, { FC, memo } from 'react';
import { createCn } from 'bem-react-classname';

import { CameraMIcon } from '@alfalab/icons-glyph/CameraMIcon';

import './index.css';

type Props = {
    openScanner: () => void;
};

const cn = createCn('scanner-button');

const ScannerButtonInner: FC<Props> = ({ openScanner }) => (
    <CameraMIcon className={cn('icon')} onClick={openScanner} />
);

export const ScannerButton = memo(ScannerButtonInner);
