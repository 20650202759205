import { PincodeInitialState } from '#/src/store/pincode/constants';
import { AKeyAuthorizationInitialState } from '#/src/store/redux/akey/constants';
import { AppInitialState } from '#/src/store/redux/app/constants';
import { AuthorizationInitialState } from '#/src/store/redux/authorization/constants';
import { BiometryAuthorizationInitialState } from '#/src/store/redux/biometry/constants';
import { CustomDesignInitialState } from '#/src/store/redux/custom-design/constants';
import { FingerprintInitialState } from '#/src/store/redux/fingerprint/constants';
import { PasscodeAuthorizationInitialState } from '#/src/store/redux/passcode/constants';
import { QRAuthorizationInitialState } from '#/src/store/redux/qr-authorization/constants';
import { RegistrationInitialState } from '#/src/store/redux/registration/constants';
import { SettingsInitialState } from '#/src/store/redux/setting/constants';
import { SmsVerificationInitialState } from '#/src/store/redux/sms-verification/constants';
import { TempPasswordAuthorizationInitialState } from '#/src/store/redux/temp-password/constants';
import { WebAuthnAuthorizationInitialState } from '#/src/store/redux/webauthn/constants';
import { ApplicationState } from '#/src/store/types';

export const ApplicationInitialState: Partial<ApplicationState> = {
    AKeyAuthorization: AKeyAuthorizationInitialState,
    App: AppInitialState,
    Authorization: AuthorizationInitialState,
    CustomDesign: CustomDesignInitialState,
    Fingerprint: FingerprintInitialState,
    PasscodeAuthorization: PasscodeAuthorizationInitialState,
    Pincode: PincodeInitialState,
    QRAuthorization: QRAuthorizationInitialState,
    Registration: RegistrationInitialState,
    SmsVerification: SmsVerificationInitialState,
    TempPasswordAuthorization: TempPasswordAuthorizationInitialState,
    BiometryAuthorization: BiometryAuthorizationInitialState,
    WebAuthnAuthorization: WebAuthnAuthorizationInitialState,
    Settings: SettingsInitialState,
};

export const FixedCacheKeys = {
    pincodeActivate: 'pincodeActivate',
};
