import React from 'react';
import { createCn } from 'bem-react-classname';

import Link from 'arui-feather/link';

import UpdateBanner from '#/src/components/ui/update-banner';
import { FORM_LINK_SIZE_M } from '#/src/lib/form-controls-const';
import { BusinessRegistrationLink, OpenBusinessAccountLink } from '#/src/models';

import './business-links.css';

const cn = createCn('business-links');

type BusinessLinksProps = {
    mobileAppDownloadLink: string;
}

const BusinessLinks: React.FC<BusinessLinksProps> = ({ mobileAppDownloadLink }) => (
    <div className={cn()}>
        <div className={cn('wrapper')}>
            <Link
                className={cn('link')}
                text='Зарегистрировать бизнес'
                size={FORM_LINK_SIZE_M}
                url={BusinessRegistrationLink}
                target='_blank'
            />
            <Link
                className={cn('link')}
                text='Открыть счет'
                size={FORM_LINK_SIZE_M}
                url={OpenBusinessAccountLink}
                target='_blank'
            />
        </div>
        <div className={cn('banner')}>
            <UpdateBanner url={mobileAppDownloadLink} />
        </div>
    </div>
);

export default BusinessLinks;
