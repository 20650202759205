import { ClientIds, MetricsClientId, MetricsRoute } from '../models';

import getPathnameEndpoint from './get-pathname-endpoint';

export function trackUserEvent(
    event_category: string,
    event_action: string,
    event_label: string,
    client_id: string,
    event_value = '',
) {
    const pathname = getPathnameEndpoint(window.location.pathname);
    const routeDescription = MetricsRoute[pathname];

    if (window.sp && client_id !== ClientIds.investmentsMobile) {
        const clientId = MetricsClientId[client_id] ? MetricsClientId[client_id] : 'OTHER'

        window.sp('trackStructEvent', event_category, event_action, event_label, null, null, {
            schema: 'iglu:com.alfabank/custom_dimension/jsonschema/1-0-0',
            data: {
                1: clientId,
                2: routeDescription,
                3: event_value,
            },
        });
    }
}
