import React, { FC, useState } from 'react';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { List } from '@alfalab/core-components/list';
import { SidePanelMobile, SidePanelMobileProps } from '@alfalab/core-components/side-panel/mobile';
import { Skeleton } from '@alfalab/core-components/skeleton';
import { Typography } from '@alfalab/core-components/typography';

import { BsBankContacts } from '#/src/components/ui/dialog-bank-contacts';
import { useAppSelector } from '#/src/hooks';
import { openSupportInfoScreen } from '#/src/lib/passport-mobile-bridge';
import { FormStatus } from '#/src/models';
import {
    isAppVersionHasSupportInfoScreen,
    selectGetDeviceOSName,
    selectIsAccountAuthEnabled,
} from '#/src/store/redux/app/selectors';
import { selectBiometryServerErrorId } from '#/src/store/redux/biometry';

import InformationBlock from '../../inform-block';
import { TextInform } from '../../text';
import { cn } from '..';

const authOptions = [
    {
        id: 'passcode',
        text: 'По\u00A0секретному коду',
        caption:
            'Это код от\u00A0приложения или\u00A0Альфа-Онлайн, с\u00A0которым вы\u00A0входили последний раз на\u00A0этом или\u00A0другом устройстве',
    },
    {
        id: 'biometry',
        text: 'По\u00A0биометрии',
        caption: 'Если вы\u00A0её\u00A0уже подключили, просто посмотрите в\u00A0камеру смартфона',
    },
    {
        id: 'tempPassword',
        text: 'По\u00A0временному паролю',
        caption:
            'Чтобы\u00A0получить, позвоните в\u00A0поддержку, обратитесь в\u00A0офис банка или\u00A0спросите у\u00A0сотрудника на\u00A0встрече',
    },
];

const getAuthVariants = (biometryPasswordErrorId: string) =>
    biometryPasswordErrorId === 'NOT_AVAILABLE' ? [authOptions[0], authOptions[2]] : authOptions;

const supportInfoScreenUrl =
    'alfabank:///universal_sdui_screen?endpoint=v1/communication/support-info&presentationType=PUSH&shouldUseBottomSafeArea=true';

const ModalInform: FC<SidePanelMobileProps> = ({ open, onClose }) => {
    const isSupportInfoScreen = useAppSelector(isAppVersionHasSupportInfoScreen);
    const deviceOS = useAppSelector(selectGetDeviceOSName);
    const biometryServerErrorId = useAppSelector(selectBiometryServerErrorId);
    const biometryFormStatus = useAppSelector(selectBiometryServerErrorId);
    const isAccountAuthEnabled = useAppSelector(selectIsAccountAuthEnabled);

    const [openBs, setOpenBs] = useState(false);
    const authVariants = getAuthVariants(biometryServerErrorId);

    let blockText = (
        <InformationBlock
            title={isAccountAuthEnabled ? TextInform.loginCardAccount : TextInform.loginCard}
        >
            Номер карты можно посмотреть на&nbsp;самой карте, в&nbsp;приложении и&nbsp;Альфа-Онлайн:
            нажмите на&nbsp;карту и&nbsp;выберите <b>Реквизиты</b>.
        </InformationBlock>
    );

    if (isAccountAuthEnabled) {
        blockText = (
            <InformationBlock
                title={isAccountAuthEnabled ? TextInform.loginCardAccount : TextInform.loginCard}
            >
                Номер карты можно посмотреть на&nbsp;самой карте, в&nbsp;приложении
                и&nbsp;Альфа-Онлайн: нажмите на&nbsp;карту и&nbsp;выберите <b>Реквизиты</b>.
                Со&nbsp;счётом то&nbsp;же самое: на&nbsp;экране счёта →&nbsp;<b>Реквизиты</b>.
            </InformationBlock>
        );
    }

    const handleChatClick = () => {
        if (isSupportInfoScreen) {
            if (deviceOS === 'Android') {
                openSupportInfoScreen();
            } else {
                window.location.assign(supportInfoScreenUrl);
            }
        } else {
            setOpenBs(true);
        }
    };

    return (
        <React.Fragment>
            <SidePanelMobile
                open={open}
                onClose={onClose}
                className={cn('modal-mobile')}
                dataTestId='sidepanel-mobile'
            >
                <SidePanelMobile.Header hasCloser={true} sticky={true}>
                    <Typography.TitleMobile view='medium' tag='div'>
                        {TextInform.login}
                    </Typography.TitleMobile>
                </SidePanelMobile.Header>
                <SidePanelMobile.Content>
                    {blockText}

                    <InformationBlock title='Другие способы входа 💳'>
                        Нажмите <b>Войти другим способом</b> и&nbsp;выберите удобный вариант:
                    </InformationBlock>
                    <Skeleton
                        visible={biometryFormStatus === FormStatus.SubmitProcess}
                        dataTestId='skeleton'
                    >
                        <List tag='ul'>
                            {authVariants.map(({ id, caption, text }) => (
                                <List.Item caption={caption} key={id}>
                                    <Typography.Text view='primary-medium'>{text}</Typography.Text>
                                </List.Item>
                            ))}
                        </List>
                    </Skeleton>

                    <Gap size='s' direction='vertical' />
                    <InformationBlock title={TextInform.noOneLogin}>
                        Позвоните нам — мы обязательно поможем&nbsp;❤️
                    </InformationBlock>
                </SidePanelMobile.Content>
                <SidePanelMobile.Footer>
                    <Button size='m' block={true} onClick={handleChatClick}>
                        Связаться с банком
                    </Button>
                </SidePanelMobile.Footer>
                {deviceOS === 'iOS' && <Gap size='2xl' direction='vertical' />}
            </SidePanelMobile>
            <BsBankContacts title='Контакты банка' open={openBs} onClose={() => setOpenBs(false)} />
        </React.Fragment>
    );
};

export default ModalInform;
