import React from 'react';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { ModalDesktop } from '@alfalab/core-components/modal/desktop';
import { ModalMobileProps } from '@alfalab/core-components/modal/typings';
import { Typography } from '@alfalab/core-components/typography';

interface ModalProps extends ModalMobileProps {
    /** Обработчик "Войти другим способом" */
    onClick(): void;
}

export const Modal = ({ open, onClose, onClick }: ModalProps) => (
    <ModalDesktop open={open} onClose={onClose}>
        <ModalDesktop.Header>Забыли код?</ModalDesktop.Header>
        <ModalDesktop.Content>
            <Typography.Text>
                Войдите в Альфа-Онлайн другим способом и обновите секретный код
            </Typography.Text>
            <Gap direction='vertical' size='xl' />
            <Button
                block={true}
                view='primary'
                onClick={onClick}
            >
                Войти другим способом
            </Button>
            <Gap direction='vertical' size='xl' />
            <Button block={true} view='secondary' onClick={onClose}>
                Вернуться
            </Button>
        </ModalDesktop.Content>
    </ModalDesktop>
);
