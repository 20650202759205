import React, { FC } from 'react';

import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Link } from '@alfalab/core-components/link';
import { PureCell } from '@alfalab/core-components/pure-cell';
import MobilePhoneMIcon from '@alfalab/icons-glyph/MobilePhoneMIcon';

import { useAppSelector } from '#/src/hooks';
import { isNewUi } from '#/src/store/redux/app/selectors';

export interface PhoneNumberCellProps {
    id: number;
    phoneNumber: string;
    text: string;
    hrefPhone: string;
}

const PhoneNumberCell: FC<Omit<PhoneNumberCellProps, 'id'>> = ({
    phoneNumber,
    text,
    hrefPhone,
}) => {
    const newUi = useAppSelector(isNewUi);

    return (
        <PureCell direction='horizontal' verticalPadding='default'>
            <PureCell.Graphics>
                <SuperEllipse
                    size={48}
                    border={!newUi}
                    backgroundColor='var(--color-light-neutral-translucent-100)'
                >
                    <MobilePhoneMIcon color='var(--color-light-neutral-translucent-1300)' />
                </SuperEllipse>
            </PureCell.Graphics>
            <PureCell.Content>
                <PureCell.Main>
                    <Link href={hrefPhone} underline={false} style={{ outline: 'none' }}>
                        {phoneNumber}
                    </Link>

                    <PureCell.Text titleColor='secondary' view='primary-small'>
                        {text}
                    </PureCell.Text>
                </PureCell.Main>
            </PureCell.Content>
        </PureCell>
    );
};

export default PhoneNumberCell;
