import React from 'react';

interface Props {
    className?: string;
    width?: number;
    height?: number;
}

export const LoaderHeart = ({ className, width = 18, height = 18 }: Props) => (
    <svg
        className={className}
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        viewBox='0 0 18 17'
        fill='none'
    >
        <path
            d='M9.25 16.23 2.19 9.12l-.06-.07a5.05 5.05 0 0 1 0-7.1 4.97 4.97 0 0 1 7.06 0l.06.05.06-.05a4.97 4.97 0 0 1 7.06 0 5.05 5.05 0 0 1 0 7.1l-7.12 7.18Z'
            fill='#EF3124'
        />
    </svg>
);
