import React from 'react';
import { createCn } from 'bem-react-classname';

import IconAlfa from 'arui-feather/icon/brand/bank-alfa';
import Link from 'arui-feather/link';

import './logo-icon.css';

const cn = createCn('logo-icon');

const IconSvg = () => (
    <div className={cn()}>
        <IconAlfa size='xxl' theme='alfa-on-color' colored={false} />
    </div>
);

const LogoIcon: React.FC = () => <Link size='xl' icon={<IconSvg />} url='https://alfabank.ru' />

export default LogoIcon;
