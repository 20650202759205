import { EventBus } from '@alfa-bank/newclick-core/utils/window-utils/event-bus';

export const useSetupEventBus = () => {
    const IS_BROWSER_ENV = !!(
        typeof window !== 'undefined' &&
        window.document &&
        window.document.createElement
    );

    if (IS_BROWSER_ENV && !window.eventBus) {
        const eventBus = new EventBus();

        window.eventBus = eventBus;
    }
};
