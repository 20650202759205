import { getAuthType, getPcUserId } from '#/src/store/redux/akey/selectors';

import { getBrowserAndOS } from '../lib/browser-detect';
import { AKeyDeeplinkCredentials, QueryRedirectParams } from '../types/interfaces';

export const getInitializeParams = (queryRedirectParams: QueryRedirectParams, acrValues: string): AKeyDeeplinkCredentials => {
    const { client_id: clientId = '', scope = '' } = queryRedirectParams;

    const authType = getAuthType();
    const pcUserId = getPcUserId();
    const { browser, os } = getBrowserAndOS();

    const params: AKeyDeeplinkCredentials = {
        client_id: clientId,
        scope,
        auth_type: authType,
        pc_user_id: pcUserId,
        browser_name: browser,
        os_name: os,
        acr_values: acrValues,
        enroll: acrValues,
    };

  return params;
};
