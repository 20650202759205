import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { selectClientId } from '#/src/store/redux/app/selectors';

import { useModalsContext } from '../../alternative-login/modals/use-modals-context';

import { AUTH_TYPE_PROPS, AuthCellBase, AuthType } from './auth-cell-base';

export const AuthCellDesktop: FC<{ authType: AuthType }> = ({ authType }) => {
    const { route, typeEvent } = AUTH_TYPE_PROPS[authType];
    const navigate = useNavigate();
    const { search } = useLocation();
    const { onModalOpen } = useModalsContext();

    const clientId = useAppSelector(selectClientId);

    const onClick = () => {
        trackUserEvent('Auth Page', 'Click', typeEvent, clientId);
        navigate({ pathname: route, search });
        onModalOpen(null);
    };

    return <AuthCellBase authType={authType} onClick={onClick} />;
};
