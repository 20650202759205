export async function webauthnAuthorization(
    params: any,
    responseFunction: (res: any) => void,
    rejectFunction: () => void,
) {
    await navigator.credentials
        .get({
            publicKey: {
                challenge: params.challenge,
                timeout: params.timeout,
                rpId: params.rpId,
                allowCredentials: [],
            },
        })
        .then((res) => {
            responseFunction(res);
        })
        .catch(() => {
            rejectFunction();
        });
}
