import { createSelector } from 'reselect';

import { validatePhoneNumber } from '#/src/lib/client-validation/registration';
import { clearFormatting } from '#/src/lib/formatters';
import { ServerErrors } from '#/src/models';
import { selectClientId } from '#/src/store/redux/app/selectors';
import { getRegistrationPhone } from '#/src/store/redux/registration/selectors';
import { ApplicationState } from '#/src/store/types';

export const selectRequestDataInit = (enroll: string) =>
    createSelector([getRegistrationPhone, selectClientId], (phone, clientId) => {
        const phoneNumber = validatePhoneNumber(phone) === null ? phone : null;

        return {
            enroll,
            phone: clearFormatting(phoneNumber),
            client_id: clientId,
        };
    });

export const selectBiometryState = (state: ApplicationState) => state.BiometryAuthorization;

export const selectBiometryAttemptsLeft = createSelector(
    [selectBiometryState],
    (biometry) => biometry.attemptsLeft,
);

export const selectBiometryServerErrorId = createSelector(
    [selectBiometryState],
    (biometry) => biometry.serverErrorId,
);

export const selectBiometryFormStatus = createSelector(
    [selectBiometryState],
    (biometry) => biometry.formStatus,
);

export const selectBiometryIsBiometryAuth = createSelector(
    [selectBiometryState],
    (biometry) => biometry.isBiometryAuth,
);

export const selectBiometryErrorPage = createSelector(
    selectBiometryServerErrorId,
    (serverErrorId) => serverErrorId === ServerErrors.PHOTO_VERIFICATION_IS_FAILED,
);

export const selectBiometryPhoto = createSelector(
    selectBiometryState,
    (biometry) => biometry.photo,
);
export const selectBiometryErrorTitle = createSelector(
    selectBiometryState,
    (biometry) => biometry.errorTitle,
);
export const selectBiometryErrorSubtitle = createSelector(
    selectBiometryState,
    (biometry) => biometry.errorSubtitle,
);

export const selectBiometryErrorText = createSelector(
    [selectBiometryErrorTitle, selectBiometryErrorSubtitle],
    (errorTitle, errorSubtitle) => ({ errorTitle, errorSubtitle }),
);
