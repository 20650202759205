import { createSelector } from 'reselect';

import { PINCODE_MAX_LENGTH, PINCODE_MIN_LENGTH } from '#/src/lib/form-controls-const';
import { hasPincodeCookies } from '#/src/lib/has-pincode-cookies';
import { FormStatus, LocalStorageName } from '#/src/models';
import {
    isClickDesign as selectIsClickDesign,
    selectIsPincodeEnabled,
} from '#/src/store/redux/app/selectors';
import { PincodeActivateResponse } from '#/src/types/interfaces';

import { FixedCacheKeys } from '../constants';
import { ApplicationState } from '../types';

import { pincodeApi } from './api';

export const getPincodeState = (state: ApplicationState) => state.Pincode;

export const selectPincodeActivateResponse = (
    state: ApplicationState,
): PincodeActivateResponse | undefined => {
    const pincodeDataSelector = pincodeApi.endpoints.pincodeActivate.select(
        FixedCacheKeys.pincodeActivate,
    );
    const { data: pincodeData } = pincodeDataSelector(state);

    return pincodeData;
};

export const selectPincodeAttemptsLeft = createSelector(
    [getPincodeState],
    (pincode) => pincode.attemptsLeft,
);
export const selectServerPublicKey = createSelector(
    [getPincodeState],
    (pincode) => pincode.serverPublicKey,
);
export const selectPincodeNonce = createSelector([getPincodeState], (pincode) => pincode.nonce);

export const selectPincode = createSelector([getPincodeState], (pincode) => pincode.code);
export const selectPincodeShowError = createSelector(
    [getPincodeState],
    (pincode) => pincode.showError,
);
export const selectPincodeError = createSelector([getPincodeState], (pincode) => pincode.error);
export const selectPincodeFormStatus = createSelector(
    [getPincodeState],
    (pincode) => pincode.formStatus,
);
export const selectSignatureBase64 = createSelector(
    [getPincodeState],
    (pincode) => pincode.signatureBase64,
);
export const selectPincodeErrorMessage = createSelector(
    [getPincodeState],
    (pincode) => pincode.message,
);
export const selectIsPincodeVisited = createSelector(
    [getPincodeState],
    (pincode) => pincode.isVisited,
);
export const selectNewBrowserSecret = createSelector(
    [getPincodeState],
    (pincode) => pincode.newBrowserSecret,
);
export const selectNewBrowserSecretEnc = createSelector(
    [getPincodeState],
    (pincode) => pincode.newBrowserSecretEnc,
);

export const selectBrowserSecret = () => {
    const browserSecret = localStorage.getItem(LocalStorageName.browserSecret);

    return browserSecret;
};

export const selectBrowserSecretDate = () => {
    const browserSecretDate = localStorage.getItem(LocalStorageName.browserSecretDate);

    return browserSecretDate;
};

export const selectIsPincodeReady = createSelector(
    [selectIsClickDesign, selectIsPincodeEnabled],
    (isClickDesign, isPincodeEnabled) =>
        Boolean(isPincodeEnabled && isClickDesign && hasPincodeCookies()),
);

export const selectIsPincodeAvailable = createSelector(
    [selectIsPincodeReady, selectIsPincodeVisited],
    (isReady, isVisited) => isReady && !isVisited,
);

export const selectHasPincodeActivateData = createSelector(
    [selectPincodeAttemptsLeft, selectPincodeNonce, selectServerPublicKey],
    (attemptsLeft, nonce, serverPublicKey) => Boolean(attemptsLeft > 0 && nonce && serverPublicKey),
);

export const selectIsPincodeButtonDisabled = createSelector(
    [selectPincode, selectPincodeFormStatus],
    (code, formStatus) =>
        Boolean(
            code.length < PINCODE_MIN_LENGTH ||
                code.length > PINCODE_MAX_LENGTH ||
                formStatus === FormStatus.SubmitProcess,
        ),
);
