import React from 'react';
import { useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';
import noop from 'lodash/noop';

import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowBackMIcon } from '@alfalab/icons-glyph/ArrowBackMIcon';

import { ButtonNames } from '#/src/models';
import { BaseComponent } from '#/src/types/interfaces';

import './back-button.css';

interface Props {
    onClick?(): void;
    backUrl?: string;
}

const Icon: React.FC<BaseComponent> = ({ className }) => (
    <span className={className}>
        <ArrowBackMIcon />
    </span>
);

const cn = createCn('back-button');

const BackButton: React.FC<Props> = ({ onClick = noop, backUrl }) => {
    const navigate = useNavigate();

    const onClickHandler = () => {
        if (backUrl) {
            window.location.replace(backUrl);
        } else {
            onClick();
            navigate(-1);
        }
    };

    return (
        <button type='button' data-test-id='back-button' className={cn()} onClick={onClickHandler}>
            <Icon className={cn('icon')} />
            <Gap size='xs' direction='horizontal' />
            <Typography.Text view='primary-medium' weight='medium'>
                {ButtonNames.back}
            </Typography.Text>
        </button>
    );
};

export default BackButton;
