const validationDictionary = {
    REQUIRED: 'Заполните это поле',
    FULLNAME: 'Введите полное ФИО',
    FULLNAME_LIMIT: 'Количество символов превышено',
    LOGIN_EQUAL_PASSWORD: 'Новый пароль не может совпадать с логином',
    OLD_PASSWORD_EQUAL_NEW: 'Новый пароль не может совпадать со старым',
    CORP_PASSWORD_LENGTH: 'Пароль должен содержать от 6 до 40 символов',
    PASSWORD_LENGTH: 'Пароль должен содержать от 6 до 64 символов',
    PASSWORD_EQUAL: 'Пароли не совпадают',
    CARD: 'Номер карты введён неверно',
    BIRTH_DATE_EXPIRED: 'Дата введена неверно',
    ACCOUNT: 'Номер счёта указан неправильно',
    PHONE_NUMBER: 'Номер телефона введён неверно',
    LOGIN_CONSIST: 'Логин введён неверно',
    LOGIN_LENGTH: 'Логин должен содержать от 6 символов',
    LOGIN_NUMBERS: 'Логин не может состоять из 7 или 8 цифр',
    LOGIN_SYMBOL: 'Логин не может начинаться с @',
    CORP_LOGIN_LENGTH: 'Логин должен содержать от 8 до 256 символов',
    PASSWORD_CONSIST:
        'Пароль может включать в себя заглавные и строчные буквы латинского ' +
        'алфавита, цифры и спецсимволы',
    PASSWORD_CONSISTS:
        'От 6 до 16 символов на латинице. Должны быть ' +
        'цифра, заглавная и строчная буквы, можно спецсимволы',
    CORP_PASSWORD_CONSIST:
        'Пароль может включать в себя заглавные и строчные буквы латинского ' +
        'алфавита, цифры и спецсимволы !:.,-_ @#$%^&',
    PASSWORD_CONTAINS_NUMBER: 'Пароль должен содержать хотя бы одну цифру',
    PASSWORD_DENIED_EQUALITY: 'Пароль не может совпадать с основным или дополнительным логином',
    PARENTAL_ADVISORY: 'Регистрация доступна для лиц, достигших 14 лет',
    PASSWORD_FROM_STOP_LIST: 'Такой пароль легко взломать. Пожалуйста, придумайте другой',
    FULLNAME_INVALID_SYMBOLS: 'Некорректные символы',
    UNIQUE_LOGIN_MESSAGE: 'Такой логин уже существует',
    PASSWORD_MINIMAL_GROUPS:
        'Пароль должен содержать цифры, буквы и спецсимволы. Не используйте ' +
        'более двух одинаковых символов подряд',
    INVESTMENTS_LOGIN: 'Подойдут только цифры и латинские буквы',
    INVESTMENTS_PASSWORD:
        'Подойдут цифры и латинские буквы в разных регистрах — от 6 до 20 символов',
    PINCODE_LENGTH: 'Заполните поле от 4 до 8 цифр',
    TEMP_PASS_REQUIRED: 'Введите пароль',
    TEMP_PASSWORD: 'Должен состоять из 6 цифр',
    TEMP_PASSWORD_NUMBERS: 'Должен состоять из цифр',
    PASSCODE_REQUIRED: 'Введите секретный код',
    PASSCODE_NUMERIC_LENGTH: 'Должен состоять от 4 до 8 цифр',
    PASSCODE_GRAPHIC_PATTERN_LENGTH: 'Соедините от 5 до 8 точек',
    PASSCODE_ALFANUMERIC_LENGTH: 'Должен состоять от 6 до 8 символов',
} as const;

export default validationDictionary;
