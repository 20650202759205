import React from 'react';
import { useNavigate } from 'react-router';

import AttentionBoldSWhiteIcon from '@alfalab/icons-classic/AttentionBoldSWhiteIcon';
import { ToolsMIcon } from '@alfalab/icons-glyph/ToolsMIcon';

import { ErrorBlock } from './error-block';

type Props = {
    networkError?: boolean;
    initError?: boolean;
};

const iconColor = 'var(--super-ellipse-attention-icon-color)';

export const ScannerError: React.FC<Props> = ({ networkError, initError }) => {
    const navigate = useNavigate();
    const retry = () => {
        navigate(0);
    };

    if (initError) {
        return (
            <ErrorBlock
                title='Сканирование номера временно недоступно'
                text='Уже решаем проблему.'
                icon={<ToolsMIcon fill={iconColor} />}
            />
        );
    }

    if (networkError) {
        return (
            <ErrorBlock
                title='Ошибка загрузки'
                text='Не удалось загрузить данные.'
                buttonText='Обновить'
                icon={<AttentionBoldSWhiteIcon fill={iconColor} />}
                onClick={retry}
            />
        );
    }

    return null;
};
