import { RegistrationType } from '#/src/models';
import { RegistrationState } from '#/src/store/redux/registration/types';
import { DEFAULT_PHONE_COUNTRY_CODE } from '#/src/utils';

export const RegistrationInitialState: RegistrationState = {
    account: '',
    card: '',
    fullName: '',
    birthDate: '',
    formError: {
        account: '',
        card: '',
        alternativeLogin: '',
        newLogin: '',
        newPassword: '',
        newRepeatedPassword: '',
        passwordsEqual: '',
        phone: '',
        fullName: '',
        birthDate: '',
    },
    formStatus: '',
    isPhoneRequired: true,
    alternativeLogin: '',
    newLogin: '',
    newPassword: '',
    repeatedNewPassword: '',
    oidRedirectURL: '',
    phone: DEFAULT_PHONE_COUNTRY_CODE,
    countryIso2: '',
    reference: '',
    serverErrors: [],
    error_update: null,
    type: RegistrationType.Card,
    registrationType: RegistrationType.Card,
}
