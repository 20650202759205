import { clientErrorLog } from '#/src/lib/client-logger';

interface WebSocketOptions {
    url: string;
    onClose: WebSocket['onclose'];
    onError: WebSocket['onerror'];
    onMessage: WebSocket['onmessage'];
    onOpen: WebSocket['onopen'];
}

export default function webSocketCreator({ url, onOpen, onClose, onMessage, onError }: WebSocketOptions): WebSocket | undefined  {
    let websocket;

    try {
        websocket = new WebSocket(url);
        websocket.onopen = onOpen;
        websocket.onclose = onClose;
        websocket.onmessage = onMessage;
        websocket.onerror = onError;
    } catch (error) {
        const err = new Error(`connection with ${url} by websocket is down with: ${error}`);

        clientErrorLog(err);
    }

    return websocket;
}
