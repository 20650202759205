import React from 'react';

import DesignCheck from '#/src/components/ui/design-check';

import CardAccount from './card-account';
import CardAccountBrowser from './card-account-browser';
import NewCardAccount from './card-account-new';
import MessengerCardAccount from './messenger-card-account';
import X5WebCardAccount from './x5-web-card-account';

export default () => (
    <DesignCheck
        defaultComponent={<CardAccountBrowser />}
        defaultMobileComponent={<CardAccountBrowser />}
        mobileComponent={<NewCardAccount />}
        corpComponent={<CardAccount />}
        bankMessengerComponent={<MessengerCardAccount />}
        X5WebComponent={ <X5WebCardAccount /> }
    />
);
