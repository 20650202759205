import React from 'react';

import DesignCheck from '../ui/design-check';

import MessengerBankSms from './messenger-bank-sms';
import Sms from './sms';
import SmsV2 from './sms-v2';
import SmsV2Mobile from './sms-v2-mobile';
import X5WebSms from './x5-web-sms';

export default (props: any) => (
    <DesignCheck
        defaultComponent={ <SmsV2 { ...props } /> }
        defaultMobileComponent={ <SmsV2Mobile { ...props } /> }
        mobileComponent={ <SmsV2Mobile { ...props } /> }
        corpComponent={ <Sms { ...props } /> }
        bankMessengerComponent={ <MessengerBankSms { ...props } /> }
        X5WebComponent={ <X5WebSms { ...props } /> }
    />
);
