import React, { ReactNode } from 'react';
import { createCn } from 'bem-react-classname';

import './input-case.css';

interface Props {
    icon?: ReactNode;
    children?: ReactNode;
};

const cn = createCn('input-case');

const InputCase: React.FC<Props> = ({
    children,
    icon
}) => (
    <div className={cn({ 'with-icon': !!icon })}>
        {children}
        {icon && <div className={cn('icon')}>{icon}</div>}
    </div>
)

export default InputCase;
