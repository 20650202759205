import React, { FC, useEffect } from 'react';
import loadable from '@loadable/component';
import { createCn } from 'bem-react-classname';

import Page from 'arui-private/core/page';

import { AppLoader } from '#/src/components/ui/loader';
import { useAppDispatch, useAppSelector, useAuthFactorCheck } from '#/src/hooks';
import { useSetupEventBus } from '#/src/hooks/use-setup-event-bus';
import { getQueryRedirectParams, selectIsMobile } from '#/src/store/redux/app/selectors';
import {
    getAlfaPayCookies,
    getCustomDesignCookies,
} from '#/src/store/redux/custom-design/selectors';
import { customDesignParamSet } from '#/src/store/redux/custom-design/slice';
import { ApplicationState } from '#/src/store/types';

import { ModalsContextProvider } from '../../alternative-login/modals/modals-context-provider';
import PlateNotification from '../../ui/plate-notification/plate-notification';

import { Header } from './header';

import './app-browser.css';

const VoipPanel = loadable(() => import('../../voip'));

const cn = createCn('custom-page');

interface Props {
    children?: React.ReactNode;
}

const AppBrowser: FC<Props> = ({ children }) => {
    const dispatch = useAppDispatch();
    const isMobile = useAppSelector(selectIsMobile);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const isVoipEnabled = useAppSelector((state: ApplicationState) => state.App.isVoipEnabled);

    useAuthFactorCheck();
    useSetupEventBus();

    useEffect(() => {
        // Старая реализация (удалить в случаен переноса кук APay)
        const alfaPayCookies = getAlfaPayCookies(isMobile);

        if (alfaPayCookies.title && alfaPayCookies.backUrl) {
            dispatch(
                customDesignParamSet({
                    client: 'apay',
                    title: alfaPayCookies.title,
                    subtitle: alfaPayCookies.subtitle,
                    authorizationUrl: alfaPayCookies.authorizationUrl,
                    backUrl: alfaPayCookies.backUrl,
                }),
            );
        }

        const { title, subtitle, authorizationUrl, backUrl, client } = getCustomDesignCookies();

        if (title) {
            let customDesignBackUrl = backUrl;

            if (client === 'alfaId' && queryRedirectParams.redirect_uri) {
                customDesignBackUrl = `${queryRedirectParams.redirect_uri}&error=auth_cancel`;
            }

            if (customDesignBackUrl) {
                dispatch(
                    customDesignParamSet({
                        title,
                        subtitle,
                        authorizationUrl,
                        backUrl: customDesignBackUrl,
                        client,
                    }),
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppLoader>
            <ModalsContextProvider>
                <Page className={cn()} header={<Header data-testid='page' />}>
                    <PlateNotification />
                    {isVoipEnabled && <VoipPanel isMobile={isMobile} />}
                    <div className={cn('content')}>{children}</div>
                </Page>
            </ModalsContextProvider>
        </AppLoader>
    );
};

export default AppBrowser;
