import React from 'react';

import ExclamationMIcon from '@alfalab/icons-glyph/ExclamationMIcon';
import InternetOffMIcon from '@alfalab/icons-glyph/InternetOffMIcon';
import ScrewdriverPaintBrushMIcon from '@alfalab/icons-glyph/ScrewdriverPaintBrushMIcon';

const iconColor = 'var(--color-light-neutral-translucent-1300)';

export const statusDataMobile = {
    online: {
        title: 'Не получилось загрузить',
        subtitle: 'Уже знаем, в чём дело, и\u00A0чиним.\u000A     Попробуйте\u00A0зайти позже',
        icon: <ScrewdriverPaintBrushMIcon color={iconColor} />,
    },
    offline: {
        title: 'Потеряли связь с\u00A0вами',
        subtitle: 'Проверьте подключение к\u00A0интернету и попробуйте снова',
        icon: <InternetOffMIcon color={iconColor} />,
    },
};

export const statusDataDesktop = {
    online: {
        title: 'Ошибка загрузки',
        subtitle: 'Не удалось загрузить данные.',
        icon: <ExclamationMIcon color={iconColor} />,
    },
    offline: {
        title: 'Нет подключения к сети',
        subtitle: 'Проверьте ваш доступ к интернету',
        icon: <InternetOffMIcon color={iconColor} />,
    },
};
