import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import AKey from '#/src/components/akey';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import getCookie from '#/src/lib/get-cookie';
import { CookiesName, Routes } from '#/src/models';
import { useAkeyAuthorizationMutation } from '#/src/store/api/akey-api';
import { getAuthType, getPcUserId } from '#/src/store/redux/akey/selectors';
import { getQueryRedirectParams } from '#/src/store/redux/app/selectors';
import { preloaderShown } from '#/src/store/redux/app/slice';

const AKeyAuth: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const authType = getAuthType();
    const pcUserId = getPcUserId();
    const [akeyAuthorization] = useAkeyAuthorizationMutation();

    useEffect(() => {
        trackUserEvent('AKey Page', 'Impression', 'Viewing Page', queryRedirectParams.client_id);

        if (queryRedirectParams.pc_session_id) {
            const browserId = getCookie(CookiesName.browserId);

            dispatch(preloaderShown());
            trackUserEvent(
                'AKey Page',
                'Impression',
                'AKey authorization',
                queryRedirectParams.client_id,
            );
            akeyAuthorization({
                client_id: queryRedirectParams.client_id,
                scope: queryRedirectParams.scope,
                pc_session_id: queryRedirectParams.pc_session_id,
                browser_id: browserId,
            });
        } else if (!authType || !pcUserId) {
            trackUserEvent('AKey Page', 'Click', 'Go to login page', queryRedirectParams.client_id);
            navigate({
                pathname: Routes.LOGIN,
                search: location.search,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return queryRedirectParams.pc_session_id ? null : <AKey />;
};

export default AKeyAuth;
