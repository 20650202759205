import { QRStatus } from '#/src/models';
import { QRAuthorizationState } from '#/src/store/redux/qr-authorization/types';

const QR_REQUEST_INTERVAL = process.env.QR_REQUEST_INTERVAL
    ? +process.env.QR_REQUEST_INTERVAL
    : 5_000;

const QR_ENABLED = process.env.QR_ENABLED === '1';
const QR_LINK = process.env.QR_LINK ? process.env.QR_LINK : '';

export const QRAuthorizationInitialState: QRAuthorizationState = {
    QRCode: '',
    QRWebSocketConnectionStatus: QRStatus.FirstConnection,
    serverErrors: [],
    QRIntervalRequest: QR_REQUEST_INTERVAL,
    isQREnabled: QR_ENABLED,
    QRLink: QR_LINK,
}
