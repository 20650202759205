import React, { useState } from 'react';

import { GenericWrapper } from '@alfalab/core-components/generic-wrapper';
import { PureCell } from '@alfalab/core-components/pure-cell';
import { Typography } from '@alfalab/core-components/typography';
import InformationCircleMIcon from '@alfalab/icons-glyph/InformationCircleMIcon';

import { cn } from '..';

import ModalInform from './modal-inform';

export const ButtonInform = () => {
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <PureCell
                className={cn('button-info-mobile')}
                dataTestId='purecell'
                type='button'
                onClick={() => {
                    setOpen(true);
                }}
            >
                <GenericWrapper alignItems='center'>
                    <GenericWrapper padding={{ right: 'xs' }}>
                        <InformationCircleMIcon fill='#747474' />
                    </GenericWrapper>
                    <GenericWrapper>
                        <GenericWrapper column={true}>
                            <Typography.Text view='primary-medium' weight='medium' color='primary'>
                                Как ещё можно войти
                            </Typography.Text>
                        </GenericWrapper>
                    </GenericWrapper>
                </GenericWrapper>
            </PureCell>

            <ModalInform open={open} onClose={() => setOpen(false)} />
        </React.Fragment>
    );
};
