import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { GenericWrapper } from '@alfalab/core-components/generic-wrapper';
import { DoorOpenMIcon } from '@alfalab/icons-glyph/DoorOpenMIcon';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { clientInfoLog } from '#/src/lib/client-logger';
import { removeCookie } from '#/src/lib/update-cookie';
import { CookiesName, LocalStorageName, Routes } from '#/src/models';
import { pincodeVisitedSet } from '#/src/store/pincode/slice';
import { selectIsMobile } from '#/src/store/redux/app/selectors';
import { SUB_ALFABANK_DOMAIN } from '#/src/utils';

export const ChangeUserButton = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const isMobile = useAppSelector(selectIsMobile);

    const handleUserChange = () => {
        window.localStorage.removeItem(LocalStorageName.browserSecret);
        window.localStorage.removeItem(LocalStorageName.browserSecretDate);
        window.localStorage.removeItem(LocalStorageName.xUserInfo);
        clientInfoLog('ForgottenPasscode - remove browserSecret and browserSecretDate');

        removeCookie(CookiesName.webAuthnFlag, { domain: SUB_ALFABANK_DOMAIN, path: '/' });
        removeCookie(CookiesName.pcUserID, { domain: SUB_ALFABANK_DOMAIN, path: '/' });
        removeCookie(CookiesName.authType, { domain: SUB_ALFABANK_DOMAIN, path: '/' });
        removeCookie(CookiesName.authTypePincode, { domain: SUB_ALFABANK_DOMAIN, path: '/' });

        dispatch(pincodeVisitedSet(true));
        navigate({
            pathname: Routes.LOGIN,
            search: location.search,
        });
    };

    return (
        <Button view='text' onClick={handleUserChange} dataTestId='header-button'>
            <GenericWrapper alignItems='center'>
                <DoorOpenMIcon />
                {!isMobile && (
                    <React.Fragment>
                        <Gap size='xs' direction='horizontal' /> Сменить пользователя
                    </React.Fragment>
                )}
            </GenericWrapper>
        </Button>
    );
};
