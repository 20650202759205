import React, { FC, useState } from 'react';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import { CategoryPasswordMIcon } from '@alfalab/icons-glyph/CategoryPasswordMIcon';

import { ErrorCode } from '#/src/components/error/error-code';
import { ModalBankContacts } from '#/src/components/ui/dialog-bank-contacts';
import { useAppSelector } from '#/src/hooks';
import { selectPincodeErrorMessage } from '#/src/store/pincode/selectors';

import { cn } from '..';

interface ErrorScreenProps {
    /** Обработчик "Войти другим способом" */
    onChange(): void;
}

const ErrorScreenPage: FC<ErrorScreenProps> = ({ onChange }) => {
    const message = useAppSelector(selectPincodeErrorMessage);

    const [open, setOpen] = useState(false);

    return (
        <div className={cn('error-screen')}>
            <Gap size='2xl' direction='vertical' />
            <SuperEllipse size={80}>
                <CategoryPasswordMIcon fontSize={5} />
            </SuperEllipse>
            <Gap size='xl' direction='vertical' />
            <Typography.Title tag='h2' view='xsmall' defaultMargins={true}>
                Вход по коду пока недоступен
            </Typography.Title>
            <Typography.Text view='primary-medium'>{message}</Typography.Text>
            <ErrorCode shortTrace={true} />
            <Gap size='xl' direction='vertical' />

            <div className={cn('error-screen-footer')}>
                <Button block={true} view='primary' onClick={onChange}>
                    Войти другим способом
                </Button>

                <Button
                    block={true}
                    view='transparent'
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    Позвонить в банк
                </Button>
            </div>

            <ModalBankContacts
                title='Позвонить в банк'
                open={open}
                onClose={() => setOpen(false)}
            />
        </div>
    );
};

export const ErrorScreen = ErrorScreenPage;
