import React, { FC, FormEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import Form from 'arui-feather/form';
import FormField from 'arui-feather/form-field';
import IconSubmit from 'arui-feather/icon/ui/submit';
import IconButton from 'arui-feather/icon-button';
import Input from 'arui-feather/input';
import Spin from 'arui-feather/spin';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { validateLogin, validatePassword } from '#/src/lib/client-validation/authorization';
import {
    CORP_LOGIN_INPUT_MIN_LENGTH,
    CORP_PASSWORD_INPUT_MAX_LENGTH,
    CORP_PASSWORD_INPUT_MIN_LENGTH,
    FORM_BUTTON_SIZE,
    FORM_FIELD_SIZE,
    FORM_INPUT_SIZE,
    LOGIN_INPUT_MAX_LENGTH,
    LOGIN_INPUT_MIN_LENGTH,
    PASSWORD_INPUT_MAX_LENGTH,
    PASSWORD_INPUT_MIN_LENGTH,
} from '#/src/lib/form-controls-const';
import isSecuredPassword from '#/src/lib/is-secured-password';
import validationDictionary from '#/src/lib/validation-dictionary';
import { FormStatus, ValidationType } from '#/src/models';
import {
    useGetAlternativeLoginMutation,
    useGetLoginInformationMutation,
    useRequestRecoveryMutation,
    useUpdateAlternativeLoginOrPassMutation,
} from '#/src/store/api/registration-api';
import {
    getQueryRedirectParams,
    selectIsCorporateClientId,
    selectMultiFactorResponseParams,
    selectPassword,
} from '#/src/store/redux/app/selectors';
import { getAuthorizationLogin } from '#/src/store/redux/authorization/selectors';
import {
    getRegistrationFormError,
    getRegistrationFormStatus,
    getRegistrationServerErrors,
    selectRegistrationErrorUpdate,
    selectRegistrationNewLogin,
    selectRegistrationNewPassword,
    selectRegistrationRepeatedNewPassword,
} from '#/src/store/redux/registration/selectors';
import {
    registrationErrorUpdated,
    registrationFormReset,
    registrationFormUpdated,
    serverErrorNotificationClosed,
} from '#/src/store/redux/registration/slice';
import addAttributes from '#/src/utils/decorators/add-attributes';

import InputCase from '../../ui/input-case';
import InputPassword from '../../ui/input-password/input-password';
import Keyboard from '../../ui/keyboard';
import ServerErrorNotifications from '../../ui/server-errors-notification/server-errors-notification';

import './recovery.css';

const AttributedIconButton = addAttributes(IconButton, [{ name: 'aria-label', value: 'Войти' }]);

const cn = createCn('recovery');

const Recovery: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [getAlternativeLogin] = useGetAlternativeLoginMutation();
    const [getLoginInformation] = useGetLoginInformationMutation();
    const [requestRecovery] = useRequestRecoveryMutation();
    const [updateAlternativeLoginOrPass] = useUpdateAlternativeLoginOrPassMutation();

    const errorUpdate = useAppSelector(selectRegistrationErrorUpdate);
    const error = useAppSelector(getRegistrationFormError);
    const formStatus = useAppSelector(getRegistrationFormStatus);
    const login = useAppSelector(getAuthorizationLogin);
    const newLogin = useAppSelector(selectRegistrationNewLogin);
    const newPassword = useAppSelector(selectRegistrationNewPassword);
    const oldPassword = useAppSelector(selectPassword);
    const repeatedNewPassword = useAppSelector(selectRegistrationRepeatedNewPassword);
    const serverErrors = useAppSelector(getRegistrationServerErrors);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const previousMultiFactorResponseParams = useAppSelector(selectMultiFactorResponseParams);
    const isCorporateClientId = useAppSelector(selectIsCorporateClientId);

    const minLoginLength = isCorporateClientId
        ? CORP_LOGIN_INPUT_MIN_LENGTH
        : LOGIN_INPUT_MIN_LENGTH;
    const minPasswordLength = isCorporateClientId
        ? CORP_PASSWORD_INPUT_MIN_LENGTH
        : PASSWORD_INPUT_MIN_LENGTH;
    const maxPasswordLength = isCorporateClientId
        ? CORP_PASSWORD_INPUT_MAX_LENGTH
        : PASSWORD_INPUT_MAX_LENGTH;
    const [showKeyboard, setShowKeyboard] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [capsLockPushed, setCapsLockPushed] = useState(0);
    const [layoutName, setLayoutName] = useState('default');
    const [idFocusedPasswordInput, setIdFocusedPasswordInput] = useState('');
    const loginRef: React.RefObject<typeof Input> = useRef(null);
    const newPasswordRef: React.RefObject<HTMLInputElement> = useRef(null);
    const repeatNewPasswordRef: React.RefObject<HTMLInputElement> = useRef(null);

    const inputPasswordBlur = (event?: React.FocusEvent<any>) => {
        if (event?.relatedTarget?.id === 'keyboard-icon') {
            event?.target.focus();
        }
    };

    const handleValidateLogin = (loginValue: string) => {
        const validationStatus = validateLogin(loginValue);

        if (validationStatus !== null) {
            dispatch(
                registrationErrorUpdated({
                    newLogin: validationStatus,
                }),
            );
        }
    };

    const handleChangeLogin = (newLoginValue?: string) => {
        if (error.newLogin) {
            dispatch(
                registrationErrorUpdated({
                    newLogin: '',
                }),
            );
        }

        handleValidateLogin(newLoginValue || '');

        dispatch(
            registrationFormUpdated({
                newLogin: newLoginValue,
            }),
        );
    };

    const isEqualWithAlternativeLogin = (loginValue: string) =>
        previousMultiFactorResponseParams.alternativeLogin === loginValue ||
        previousMultiFactorResponseParams.username === loginValue;

    const handleValidatePassword = (password: string, validationType: string) => {
        const validationStatus = validatePassword(password, isCorporateClientId);

        if (validationStatus !== null) {
            if (ValidationType.NewPassword === validationType) {
                dispatch(
                    registrationErrorUpdated({
                        newPassword: validationStatus,
                    }),
                );
            } else {
                dispatch(
                    registrationErrorUpdated({
                        repeatedNewPassword: validationStatus,
                    }),
                );
            }
        }
    };

    const validateNewPassword = (password: string) => {
        if (error.newPassword) {
            dispatch(
                registrationErrorUpdated({
                    newPassword: '',
                }),
            );
        }

        handleValidatePassword(password, ValidationType.NewPassword);

        if (login === password || newLogin === password) {
            dispatch(
                registrationErrorUpdated({
                    passwordsEqual: validationDictionary.LOGIN_EQUAL_PASSWORD,
                }),
            );

            return;
        }

        if (password === oldPassword) {
            dispatch(
                registrationErrorUpdated({
                    passwordsEqual: validationDictionary.OLD_PASSWORD_EQUAL_NEW,
                }),
            );

            return;
        }

        if (password.length <= repeatedNewPassword.length && password !== repeatedNewPassword) {
            dispatch(
                registrationErrorUpdated({
                    passwordsEqual: validationDictionary.PASSWORD_EQUAL,
                }),
            );
        } else {
            dispatch(
                registrationErrorUpdated({
                    passwordsEqual: '',
                }),
            );
        }
    };

    const validateRepeatedNewPassword = (password: string) => {
        if (error.repeatedNewPassword) {
            dispatch(
                registrationErrorUpdated({
                    repeatedNewPassword: '',
                }),
            );
        }

        handleValidatePassword(password, ValidationType.RepeatedNewPassword);
        if (newPassword) {
            if (!isSecuredPassword(newPassword) && isCorporateClientId) {
                dispatch(
                    registrationErrorUpdated({
                        passwordsEqual: validationDictionary.PASSWORD_MINIMAL_GROUPS,
                    }),
                );

                return;
            }

            if (login === newPassword || newLogin === newPassword) {
                dispatch(
                    registrationErrorUpdated({
                        passwordsEqual: validationDictionary.LOGIN_EQUAL_PASSWORD,
                    }),
                );

                return;
            }

            if (newPassword === oldPassword) {
                dispatch(
                    registrationErrorUpdated({
                        passwordsEqual: validationDictionary.OLD_PASSWORD_EQUAL_NEW,
                    }),
                );

                return;
            }

            if (newPassword.length <= password.length && newPassword !== password) {
                dispatch(
                    registrationErrorUpdated({
                        passwordsEqual: validationDictionary.PASSWORD_EQUAL,
                    }),
                );
            } else {
                dispatch(
                    registrationErrorUpdated({
                        passwordsEqual: '',
                    }),
                );
            }
        }
    };

    const handleBlurLogin = (event?: React.FocusEvent<any>) => {
        if (
            newLogin &&
            newLogin.length >= minLoginLength &&
            !error.newLogin &&
            !isEqualWithAlternativeLogin(event?.target.value)
        ) {
            getLoginInformation({ login: newLogin });
        }
    };

    const handleChangeNewPassword = (password: string) => {
        validateNewPassword(password);

        dispatch(
            registrationFormUpdated({
                newPassword: password,
            }),
        );
    };

    const handleChangeRepeatedNewPassword = (password: string) => {
        validateRepeatedNewPassword(password);

        dispatch(
            registrationFormUpdated({
                repeatedNewPassword: password,
            }),
        );
    };

    const handleOnKeyPress = (button: string) => {
        if (button === 'caps') {
            setCapsLockPushed((prevState) => prevState + 1);
        }

        if (button === 'shift' || button === 'caps') {
            const layout = layoutName === 'default' ? 'shift' : 'default';

            setLayoutName(layout);

            return;
        }

        if (idFocusedPasswordInput.indexOf('repeated') < 0) {
            if (button === 'backspace') {
                dispatch(
                    registrationFormUpdated({
                        newPassword: newPassword.slice(0, -1),
                    }),
                );
            } else if (button.length === 1 || button === '.com') {
                let password = '';

                password = newPassword + button;

                validateNewPassword(password);

                dispatch(
                    registrationFormUpdated({
                        newPassword: password,
                    }),
                );
            }
        }

        if (idFocusedPasswordInput.indexOf('repeated') >= 0) {
            if (button === 'backspace') {
                dispatch(
                    registrationFormUpdated({
                        repeatedNewPassword: repeatedNewPassword.slice(0, -1),
                    }),
                );
            } else if (button.length === 1 || button === '.com') {
                let password = '';

                password = repeatedNewPassword + button;

                validateRepeatedNewPassword(password);

                dispatch(
                    registrationFormUpdated({
                        repeatedNewPassword: password,
                    }),
                );
            }
        }

        if (capsLockPushed % 2 === 0) {
            setLayoutName('default');
        }
    };

    const handleFocus = (event?: React.FocusEvent<any>) => {
        const inputId = ((event || {}).target || {}).id || '';

        setIdFocusedPasswordInput(inputId);
        if (inputId === 'repeated-new-password-input' && isCorporateClientId) {
            if (newPassword) {
                if (!isSecuredPassword(newPassword)) {
                    dispatch(
                        registrationErrorUpdated({
                            passwordsEqual: validationDictionary.PASSWORD_MINIMAL_GROUPS,
                        }),
                    );
                }
            } else {
                dispatch(
                    registrationErrorUpdated({
                        passwordsEqual: validationDictionary.REQUIRED,
                    }),
                );
            }
        }
    };

    const handleShowKeyboard = () => {
        setShowKeyboard((prevState) => !prevState);
    };

    const handleSubmit = (event?: FormEvent) => {
        event?.preventDefault();
        if (!isEqualWithAlternativeLogin(newLogin)) {
            getLoginInformation({ login: newLogin });
        }
        if (isCorporateClientId) {
            requestRecovery({
                login: newLogin || login,
                newPassword,
                oldPassword,
                skip: false,
                queryRedirectParams,
                previousMultiFactorResponseParams,
            });
        } else {
            updateAlternativeLoginOrPass({
                login: previousMultiFactorResponseParams.username || '',
                alt_login: newLogin,
                new_password: newPassword,
                relying_party: queryRedirectParams.client_id,
                alt_log_changed: login !== newLogin,
                skip: false,
                queryRedirectParams,
                previousMultiFactorResponseParams,
            });
        }
    };

    const isSubmitButtonDisabled = () => {
        const isDisabled =
            newPassword !== repeatedNewPassword ||
            login === newPassword ||
            newPassword === oldPassword ||
            (newLogin || login).length < minLoginLength ||
            error.newLogin ||
            newPassword.length < minPasswordLength ||
            error.newPassword ||
            repeatedNewPassword.length < newPassword.length ||
            repeatedNewPassword.length < minPasswordLength ||
            error.repeatedNewPassword ||
            error.passwordsEqual;

        return Boolean(isDisabled);
    };

    const setInputState = (login: string, username: string, input: React.RefObject<any>) => {
        if (!login) {
            dispatch(
                registrationFormUpdated({
                    newLogin: username,
                }),
            );
        }
        setIsProcessing(false);
        input.current.focus();
    };

    useEffect(() => {
        if (!isCorporateClientId) {
            try {
                getAlternativeLogin({
                    login: previousMultiFactorResponseParams.username,
                });
                if (newLogin) {
                    setInputState(
                        newLogin,
                        previousMultiFactorResponseParams.username || '',
                        newPasswordRef,
                    );
                } else {
                    setIsProcessing(false);
                    loginRef.current?.focus();
                }
            } catch (err) {
                setInputState(
                    newLogin,
                    previousMultiFactorResponseParams.username || '',
                    newPasswordRef,
                );
            }
        }

        setTimeout(() => {
            // setTimout необходим для корректной фокусировки на android 5.1
            if (isCorporateClientId || newLogin) {
                setIsProcessing(false);
                newPasswordRef.current?.focus();
            } else {
                loginRef.current?.focus();
            }
        }, 0);

        return () => {
            dispatch(registrationFormReset());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (errorUpdate) {
            navigate(-1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorUpdate]);

    return (
        <Form noValidate={true} onSubmit={handleSubmit}>
            <ServerErrorNotifications
                serverErrors={serverErrors}
                onnotificationClosed={() => {
                    dispatch(serverErrorNotificationClosed(0));
                }}
            />
            <FormField size={FORM_FIELD_SIZE}>
                {isCorporateClientId ? null : (
                    <InputCase>
                        <Input
                            error={error.newLogin}
                            resetError={false}
                            id='new-login-input'
                            label='Логин'
                            maxLength={LOGIN_INPUT_MAX_LENGTH}
                            name='new-login'
                            ref={loginRef}
                            size={FORM_INPUT_SIZE}
                            value={newLogin}
                            width='available'
                            onChange={(value) => handleChangeLogin(value)}
                            onBlur={handleBlurLogin}
                            disabled={isProcessing}
                        />
                    </InputCase>
                )}
                <InputCase>
                    <InputPassword
                        className={cn('new-password')}
                        error={error.newPassword || error.passwordsEqual}
                        resetError={false}
                        id='new-password-input'
                        label='Пароль'
                        maxLength={maxPasswordLength}
                        name='new-password'
                        inputRef={newPasswordRef}
                        size={FORM_INPUT_SIZE}
                        type='password'
                        visibleIcon={false}
                        visibleKeyboard={handleShowKeyboard}
                        width='available'
                        onInputChange={handleChangeNewPassword}
                        onBlur={inputPasswordBlur}
                        onFocus={handleFocus}
                        disabled={isProcessing}
                        password={newPassword}
                        showEye={isCorporateClientId}
                    />
                </InputCase>
                <InputCase>
                    <InputPassword
                        error={error.repeatedNewPassword}
                        resetError={false}
                        id='repeated-new-password-input'
                        label='Повторите пароль'
                        maxLength={maxPasswordLength}
                        name='repeated-new-password'
                        inputRef={repeatNewPasswordRef}
                        visibleIcon={false}
                        visibleKeyboard={handleShowKeyboard}
                        rightAddons={
                            <AttributedIconButton
                                disabled={isSubmitButtonDisabled()}
                                icon={
                                    formStatus === FormStatus.SubmitProcess ? (
                                        <Spin size={FORM_BUTTON_SIZE} visible={true} />
                                    ) : (
                                        <IconSubmit size={FORM_BUTTON_SIZE} />
                                    )
                                }
                                id='password-submit'
                                type='submit'
                                onClick={handleSubmit}
                            />
                        }
                        size={FORM_INPUT_SIZE}
                        type='password'
                        width='available'
                        onInputChange={handleChangeRepeatedNewPassword}
                        onBlur={inputPasswordBlur}
                        onFocus={handleFocus}
                        disabled={isProcessing}
                        password={repeatedNewPassword}
                        showEye={isCorporateClientId}
                    />
                </InputCase>
                {showKeyboard ? (
                    <Keyboard layoutName={layoutName} onClick={handleOnKeyPress} />
                ) : null}
            </FormField>
        </Form>
    );
};

export default Recovery;
