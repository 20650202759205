import React, { useEffect } from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Typography } from '@alfalab/core-components/typography';
import Form from 'arui-feather/form';
import FormField from 'arui-feather/form-field';
import AttentionMark from 'arui-feather/icon/ui/attention-mark';
import Paragraph from 'arui-feather/paragraph';

import ServerErrorNotificationsNew from '#/src/components/ui/server-errors-notification/server-errors-notification-new';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { validatePhoneNumber } from '#/src/lib/client-validation/registration';
import {
    FORM_FIELD_SIZE,
    FORM_PHONE_INPUT_SIZE,
    PHONE_INPUT_MAX_LENGTH,
    PHONE_INPUT_MIN_LENGTH_RU,
} from '#/src/lib/form-controls-const';
import { ButtonNames, FormStatus } from '#/src/models';
import { useRequestPhoneRegistrationMutation } from '#/src/store/api/registration-api';
import {
    getRegistrationAccount,
    getRegistrationCard,
    getRegistrationFormError,
    getRegistrationFormStatus,
    getRegistrationPhone,
    getRegistrationServerErrors,
    getRegistrationType,
} from '#/src/store/redux/registration/selectors';
import {
    phoneRegistrationSubmit,
    registrationErrorUpdated,
    registrationFormUpdated,
    registrationServerErrorsCleared,
    serverErrorNotificationClosed,
} from '#/src/store/redux/registration/slice';

import BackButton from '../../ui/back-button';
import InputCase from '../../ui/input-case';
import PhoneInput from '../../ui/phone-input';

import './phone-new.css';

const PHONE_INPUT_MIN_LENGTH_RU_CHECKED = PHONE_INPUT_MIN_LENGTH_RU;

const cn = createCn('form-basic');

interface Props {
    isBrowser?: boolean;
}

const PhoneLogin: React.FC<Props> = ({ isBrowser }) => {
    const dispatch = useAppDispatch();
    const [requestPhoneRegistration] = useRequestPhoneRegistrationMutation();

    const type = useAppSelector(getRegistrationType);
    const phone = useAppSelector(getRegistrationPhone);
    const formStatus = useAppSelector(getRegistrationFormStatus);
    const formError = useAppSelector(getRegistrationFormError);
    const serverErrors = useAppSelector(getRegistrationServerErrors);
    const card = useAppSelector(getRegistrationCard);
    const account = useAppSelector(getRegistrationAccount);

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess) {
            requestPhoneRegistration();
        }
    }, [formStatus, requestPhoneRegistration]);

    useEffect(() => {
        dispatch(
            registrationErrorUpdated({
                phone: null,
            }),
        );
    }, [dispatch, type]);

    const handlePhoneChange = (phoneValue?: string) => {
        dispatch(registrationFormUpdated({ phone: phoneValue }));
    };

    const handlePhoneClear = () => {
        dispatch(registrationFormUpdated({ phone: '' }));
    };

    const handleSubmit = (event: React.FormEvent<any> | undefined) => {
        event?.preventDefault();
        dispatch(
            phoneRegistrationSubmit({
                type,
                account,
                card,
                phone,
            }),
        );
    };

    const handlePhoneBlur = () => {
        if (phone.length > 1 && phone.length < PHONE_INPUT_MIN_LENGTH_RU_CHECKED) {
            dispatch(registrationErrorUpdated({ phone: validatePhoneNumber(phone) }));
        }
    };

    const handleChangeCard = () => {
        dispatch(registrationServerErrorsCleared());
    };

    const isSubmitButtonDisabled = () => {
        let isDisabled = false;

        isDisabled = Boolean(
            phone.replace(/\s+/g, '').length < PHONE_INPUT_MIN_LENGTH_RU_CHECKED ||
                formError.phone ||
                formStatus === FormStatus.SubmitProcess,
        );

        return isDisabled;
    };

    if (isBrowser) {
        return (
            <Form
                className={cn('phone-browser')}
                onSubmit={handleSubmit}
                noValidate={true}
                id='phone-form'
            >
                <ServerErrorNotificationsNew
                    errorMessage={serverErrors[0]?.message}
                    onClose={() => {
                        dispatch(serverErrorNotificationClosed(0));
                    }}
                />
                <BackButton onClick={handleChangeCard} />
                <div className='phone__title'>
                    <Typography.Title view='small' font='styrene' tag='div'>
                        Введите номер телефона
                    </Typography.Title>
                    <Typography.Text tag='div'>Тот, который вы сообщали банку</Typography.Text>
                </div>
                <PhoneInput
                    isNewUi={true}
                    hideLabel={true}
                    clear={true}
                    size='l'
                    onClear={handlePhoneClear}
                    id='phoneNumberInput'
                    width='available'
                    phone={phone}
                    maxLength={PHONE_INPUT_MAX_LENGTH}
                    onChange={handlePhoneChange}
                    onBlur={handlePhoneBlur}
                    error={formError.phone}
                />
                <Button
                    block={true}
                    type='submit'
                    view='primary'
                    disabled={isSubmitButtonDisabled()}
                    onClick={handleSubmit}
                    loading={formStatus === FormStatus.SubmitProcess}
                >
                    {ButtonNames.continue}
                </Button>
            </Form>
        );
    }

    return (
        <Form
            className={cn({ flex: 'space-between' })}
            onSubmit={handleSubmit}
            noValidate={true}
            id='phone-form'
        >
            <div className={cn('row')}>
                <Paragraph className={cn('phone-description')}>
                    Укажите номер телефона, который вы сообщали банку в последний раз:
                </Paragraph>
                <FormField size={FORM_FIELD_SIZE} id='phone-form-field'>
                    <InputCase>
                        <PhoneInput
                            size={FORM_PHONE_INPUT_SIZE}
                            id='phoneNumberInput'
                            width='available'
                            phone={phone}
                            maxLength={PHONE_INPUT_MAX_LENGTH}
                            onChange={handlePhoneChange}
                            onBlur={handlePhoneBlur}
                            error={formError.phone}
                        />
                    </InputCase>
                </FormField>
            </div>
            <div className={cn('row')}>
                <div className={cn('button-wrapper')}>
                    {serverErrors && serverErrors[0] && (
                        <div className={cn('error-text-wrapper')}>
                            <AttentionMark className={cn('error-icon')} colored={true} />
                            <div className={cn('error-text')}>{serverErrors[0].message}</div>
                        </div>
                    )}
                    <Button
                        block={true}
                        type='submit'
                        view='primary'
                        disabled={isSubmitButtonDisabled()}
                        onClick={handleSubmit}
                        loading={formStatus === FormStatus.SubmitProcess}
                    >
                        {ButtonNames.continue}
                    </Button>
                </div>
                <div className={cn('button-wrapper')}>
                    <Button
                        block={true}
                        type='button'
                        view='transparent'
                        onClick={handleChangeCard}
                    >
                        {ButtonNames.backToCardInput}
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export default PhoneLogin;
