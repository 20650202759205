import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { PasswordInput } from '@alfalab/core-components/password-input';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import {
    validationPasscodeAO,
    validationPasscodeNumericOnChange,
} from '#/src/lib/client-validation/authorization';
import { PINCODE_MAX_LENGTH } from '#/src/lib/form-controls-const';
import { FormStatus, PasscodeType } from '#/src/models';
import { isNewUi, selectClientId, selectIsMobile } from '#/src/store/redux/app/selectors';
import {
    selectPasscode,
    selectPasscodeActiveType,
    selectPasscodeError,
    selectPasscodeFormStatus,
    selectPasscodeTypes,
} from '#/src/store/redux/passcode';
import { passcodeErrorSet, passcodeSet } from '#/src/store/redux/passcode/slice';

import './alphanumeric.css';

const cn = createCn('passcode-alphanumeric');

// Общий компонент Numeric passcode и Alphanumeric passcode для АО Desctop
const AlphanumericPasscode = () => {
    const dispatch = useAppDispatch();

    const passcode = useAppSelector(selectPasscode);
    const error = useAppSelector(selectPasscodeError);
    const clientId = useAppSelector(selectClientId);
    const formStatus = useAppSelector(selectPasscodeFormStatus);
    const isMobile = useAppSelector(selectIsMobile);
    const newUi = useAppSelector(isNewUi);
    const activeTypePasscode = useAppSelector(selectPasscodeActiveType);
    const passcodeTypes = useAppSelector(selectPasscodeTypes);

    const [firstKeyPush, setFirstKeyPush] = useState(false);
    const inputFocus = useRef<HTMLInputElement>(null);
    const isAlphaMobile = isMobile && newUi;

    useEffect(() => {
        inputFocus.current?.focus();
    }, []);

    const onChange = (inputValue: string) => {
        if (!firstKeyPush) {
            trackUserEvent('Auth Page', 'Field Change', 'Enter Passcode', clientId, 'Alphanumeric');
            setFirstKeyPush(true);
        }

        dispatch(passcodeSet(inputValue));

        if (
            !isMobile &&
            activeTypePasscode === PasscodeType.Numeric &&
            !passcodeTypes.includes(PasscodeType.Alphanumeric)
        ) {
            const validation = validationPasscodeNumericOnChange(inputValue);

            if (validation === null) {
                dispatch(passcodeErrorSet(''));
            } else {
                dispatch(passcodeErrorSet(validation));
            }
        } else if (error) {
            dispatch(passcodeErrorSet(''));
        }
    };

    const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const isAlphanumericNumeric =
            !isMobile &&
            passcodeTypes.includes(PasscodeType.Numeric) &&
            passcodeTypes.includes(PasscodeType.Alphanumeric) &&
            activeTypePasscode !== PasscodeType.GraphicPattern;

        const validation = validationPasscodeAO(
            event.target.value,
            isAlphanumericNumeric ? 'AlphanumericNumeric' : activeTypePasscode,
        );

        if (validation !== null && !error) {
            dispatch(passcodeErrorSet(validation));
        }
    };

    return (
        <PasswordInput
            ref={inputFocus}
            placeholder={isAlphaMobile ? 'Секретный код' : undefined}
            label={isAlphaMobile ? undefined : 'Секретный код'}
            className={cn(isAlphaMobile ? 'input-mobile' : 'input-br')}
            size={isMobile ? (newUi && 48) || 64 : 72}
            block={true}
            autoFocus={!(isMobile && newUi)}
            autoComplete='off'
            maxLength={PINCODE_MAX_LENGTH}
            value={passcode}
            error={error}
            onBlur={!isMobile || !newUi ? onBlur : undefined}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
            disabled={formStatus === FormStatus.SubmitProcess}
        />
    );
};

export default AlphanumericPasscode;
