export default (v1?: string, v2?: string): number => {
    if (!v1 || !v2) {
        return NaN;
    }
    let v1parts: Array<string | number> = v1.split('.');
    let v2parts: Array<string | number> = v2.split('.');

    function isValidPart(x: string): boolean {
        return /^\d+$/.test(x);
    }

    if (!(v1parts as string[]).every(isValidPart) || !(v2parts as string[]).every(isValidPart)) {
        return NaN;
    }

    while (v1parts.length < v2parts.length) v1parts.push('0');
    while (v2parts.length < v1parts.length) v2parts.push('0');

    v1parts = v1parts.map(Number);
    v2parts = v2parts.map(Number);

    for (let i = 0; i < v1parts.length; ++i) {
        if (v2parts.length === i) {
            return 1;
        }

        if (v1parts[i] > v2parts[i]) {
            return 1;
        }
        if (v1parts[i] !== v2parts[i]) {
            return -1;
        }
    }

    return 0;
};
