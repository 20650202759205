import { parsePhoneNumber } from 'libphonenumber-js';

export function clearFormatting(formField?: string | null) {
    return formField && formField.replace(/(\+)*(\s)*/g, '');
}
// TODO убрать если нет планов использовать дальше.
export function externalFormatPhone(_phone: string, format: string) {
    // TODO обработать возвращение undefined.
    const phone = clearFormatting(_phone) as string;

    return phone.replace(/([0-9]{1})([0-9]{3})([0-9]{3})([0-9]{2})([0-9]{2})/, format);
}

/**
 * @description получает строчку/путь по которому нужно добавить свойство в объект типа "person.name"
 * вернет объект { person: { name: value } }
 * @param {Object} obj объект, к которому добавляем свойство
 * @param {String} path путь, по которому будет лежать значение в объекте
 * @param {*} value значение
 * @returns {Object} объект с добавленным значением
 */
export function set(obj: object, path: string, value: any) {
    let pathCopy: string | RegExpMatchArray | string[] = path;

    if (Object(obj) !== obj) return obj;
    if (!Array.isArray(path)) pathCopy = path.toString().match(/[^.[\]]+/g) || [] as string[];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    pathCopy.slice(0, -1).reduce((a, c, i) => {
        const aCopy = a;

        // eslint-disable-next-line no-return-assign
        return Object(aCopy[c]) === aCopy[c]
            ? aCopy[c]
            :
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-bitwise
            (aCopy[c] = Math.abs(pathCopy[i + 1]) >> 0 === +pathCopy[i + 1] ? [] : {});
    }, obj)[pathCopy[pathCopy.length - 1]] = value;

    return obj;
}

export function addSpacesToCardNumber(card?: string) {
    if (card) {
        const match = card.match(/^(\d{4})(\d{4})(\d{4})(\d{4})$/);

        if (match) {
            return `${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
        }
    }

    return card;
}

export function checkPhone(phone: string) {
    // регулярка проверяет номер на валидность, пример валидного номера
    // 8 (800) 200-00-00, +7 (495) 78-888-78, 8 800 200 00 00, 88002000000
    if (!/^\+? ?[0-9]+ ?\(?[0-9]*\)?(( |-)?[0-9]+)*$/.test(phone)) {
        return '';
    }
    try {
        const parsedPhone = parsePhoneNumber(phone, 'RU');

        return parsedPhone.isValid() ? parsedPhone.formatInternational() : phone;
    } catch (err) {
        return phone;
    }
}

function parsePhone(phone: string) {
    if (!/^\+[0-9]*$/.test(phone)) return phone.slice(0, -1);
    try {
        const parsedPhone = parsePhoneNumber(phone);

        return parsedPhone.isValid() ? parsedPhone.formatInternational() : phone;
    } catch (err) {
        return phone;
    }
}

export function formatPhoneNumber(phone: string) {
    let phoneNumber = clearFormatting(phone);

    phoneNumber = `+${phoneNumber}`;
    if (phoneNumber.length === 4) {
        phoneNumber =
            phoneNumber.charAt(1) === '7' && phoneNumber.charAt(2) === '8'
                ? `${phoneNumber.slice(0, 2)}${phoneNumber.slice(-1)}`
                : phoneNumber;
    }

    return parsePhone(phoneNumber);
}
