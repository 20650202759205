import { createSelector } from 'reselect';

import getCookie from '#/src/lib/get-cookie';
import { CookiesName } from '#/src/models';
import { ApplicationState } from '#/src/store/types';

export const getWebAuthnState = (state: ApplicationState) => state.WebAuthnAuthorization;

export const selectWebAuthnError = createSelector([getWebAuthnState], (webAuthn) => webAuthn.error);

export const getWebAuthnFlag = (): boolean => {
    // Скрываем на время SSR
    if (typeof document === 'undefined') return false;

    const webAuthnFlag = getCookie(CookiesName.webAuthnFlag);

    return webAuthnFlag === 'true';
};
