import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { SystemMessage } from '@alfalab/core-components/system-message';
import { LockClosedMIcon } from '@alfalab/icons-glyph/LockClosedMIcon';

import { BsBankContacts, ModalBankContacts } from '#/src/components/ui/dialog-bank-contacts';
import { useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { Routes } from '#/src/models';
import { selectClientId, selectIsMobile } from '#/src/store/redux/app/selectors';

import { cn } from '..';

const ErrorScreenPage: FC = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const isMobile = useAppSelector(selectIsMobile);
    const clientId = useAppSelector(selectClientId);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        trackUserEvent(
            'Auth Page',
            'Impression',
            'Viewing Page',
            clientId,
            'Auth Error TempPassword Screen',
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <SystemMessage className={cn('error-screen')} fullHeight={isMobile}>
                <div className={cn('error-screen-main')}>
                    <SystemMessage.Graphic>
                        <SuperEllipse size={80}>
                            <LockClosedMIcon
                                color='var(--color-light-neutral-translucent-1300)'
                            />
                        </SuperEllipse>
                    </SystemMessage.Graphic>
                    <SystemMessage.Title>
                        Вход по&nbsp;временному паролю недоступен
                    </SystemMessage.Title>
                    <SystemMessage.Subtitle>
                        Запросите новый временный пароль или&nbsp;войдите другим способом
                    </SystemMessage.Subtitle>
                </div>

                <SystemMessage.Controls direction='column'>
                    <Button
                        className={cn('btn')}
                        block={true}
                        view='primary'
                        onClick={() =>
                            navigate({
                                pathname: Routes.CARD_ACCOUNT,
                                search,
                            })
                        }
                    >
                        Войти другим способом
                    </Button>
                    <Button block={true} view='secondary' onClick={() => setOpen(true)}>
                        Позвонить в банк
                    </Button>
                    <Gap size='s' direction='vertical' />
                </SystemMessage.Controls>
            </SystemMessage>
            {isMobile ? (
                <BsBankContacts
                    title='Позвонить в банк'
                    open={open}
                    onClose={() => {
                        setOpen(false);
                    }}
                />
            ) : (
                <ModalBankContacts
                    title='Позвонить в банк'
                    open={open}
                    onClose={() => setOpen(false)}
                />
            )}
        </React.Fragment>
    );
};

export const ErrorScreenTempPass = ErrorScreenPage;
