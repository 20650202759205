import React, { FC, useEffect, useRef } from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import Form from 'arui-feather/form';
import FormField from 'arui-feather/form-field';
import IconSubmit from 'arui-feather/icon/ui/submit';
import IconButton from 'arui-feather/icon-button';
import Input from 'arui-feather/input';
import Spin from 'arui-feather/spin';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import {
    DIRECT_LOGIN_INPUT_MIN_LENGTH,
    DIRECT_PASSWORD_INPUT_MIN_LENGTH,
    FORM_BUTTON_SIZE,
    FORM_FIELD_SIZE,
    FORM_INPUT_SIZE,
} from '#/src/lib/form-controls-const';
import { ClientIds, FormStatus, RegistrationType } from '#/src/models';
import { useRequestOIDAuthByLoginMutation } from '#/src/store/api/authorization-api';
import { getQueryRedirectParams, selectInvestmentsUrl } from '#/src/store/redux/app/selectors';
import {
    getAuthorizationFormError,
    getAuthorizationFormStatus,
    getAuthorizationLogin,
    getAuthorizationPassword,
    getAuthorizationServerErrors,
} from '#/src/store/redux/authorization/selectors';
import {
    authorizationFormReset,
    authorizationFormUpdated,
    authorizationPasswordReset,
    authServerErrorNotificationClosed,
} from '#/src/store/redux/authorization/slice';

import AuthFactorSwitcher from '../ui/auth-factor-switcher';
import InputCase from '../ui/input-case';
import ServerErrorNotifications from '../ui/server-errors-notification/server-errors-notification';

import './login-password.css';

const cn = createCn('adirect');

const LoginPassword: FC = () => {
    const dispatch = useAppDispatch();
    const [requestOIDAuthByLogin] = useRequestOIDAuthByLoginMutation();

    const login = useAppSelector(getAuthorizationLogin);
    const formStatus = useAppSelector(getAuthorizationFormStatus);
    const error = useAppSelector(getAuthorizationFormError);
    const password = useAppSelector(getAuthorizationPassword);
    const serverErrors = useAppSelector(getAuthorizationServerErrors);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const investmentsUrl = useAppSelector(selectInvestmentsUrl);

    const loginRef: React.RefObject<typeof Input> = useRef(null);
    const passwordRef: React.RefObject<typeof Input> = useRef(null);

    useEffect(() => {
        const { login: queryLogin } = queryRedirectParams || {};

        if (queryLogin) {
            dispatch(
                authorizationFormUpdated({
                    login,
                }),
            );
            passwordRef.current?.focus();
        }

        return () => {
            dispatch(authorizationFormReset());
            dispatch(authorizationPasswordReset());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlenotificationClosed = () => {
        dispatch(authServerErrorNotificationClosed());
    };

    const resetFormError = () => {
        if (typeof error === 'object' && Object.keys(error).length) {
            dispatch(authorizationFormReset());
        }
    };

    const handleChangeLogin = (newLogin?: string) => {
        resetFormError();
        dispatch(
            authorizationFormUpdated({
                login: newLogin,
            }),
        );
    };

    const handleChangePassword = (newPassword?: string) => {
        resetFormError();
        dispatch(
            authorizationFormUpdated({
                password: newPassword,
            }),
        );
    };

    const isButtonDisabled = () =>
        login.length < DIRECT_LOGIN_INPUT_MIN_LENGTH ||
        password.length < DIRECT_PASSWORD_INPUT_MIN_LENGTH ||
        formStatus === FormStatus.SubmitProcess ||
        !!error.login;

    const handleSubmit = () => {
        if (password && login && !isButtonDisabled()) {
            requestOIDAuthByLogin({
                username: login,
                password,
                queryRedirectParams,
            });
        }
    };

    const handleOnKeyDown = (event?: React.KeyboardEvent<any>) => {
        if (event && event.key === 'Enter') {
            handleSubmit();
        }
    };

    const showLink =
        queryRedirectParams.client_id === ClientIds.investmentsMobile && investmentsUrl;

    return (
        <div className={cn('container')}>
            <Form
                data-test-id='authBlock'
                className={cn()}
                noValidate={true}
                onSubmit={handleSubmit}
            >
                <ServerErrorNotifications
                    serverErrors={serverErrors}
                    onnotificationClosed={handlenotificationClosed}
                />

                <AuthFactorSwitcher form={RegistrationType.Login} />
                <FormField size={FORM_FIELD_SIZE}>
                    <div className={cn('login-input')}>
                        <InputCase>
                            <Input
                                id='login-input-combined'
                                data-test-id='loginField'
                                width='available'
                                error={error.login}
                                resetError={false}
                                name='username'
                                value={login}
                                ref={loginRef}
                                label='Логин Альфа-Инвестиции'
                                size={FORM_INPUT_SIZE}
                                onChange={handleChangeLogin}
                            />
                        </InputCase>
                    </div>
                </FormField>
                <FormField size={FORM_FIELD_SIZE}>
                    <div className={cn('password-input')}>
                        <InputCase>
                            <Input
                                id='password-input-combined'
                                data-test-id='passField'
                                width='available'
                                error={error.password}
                                resetError={false}
                                type='password'
                                name='password'
                                value={password}
                                ref={passwordRef}
                                label='Пароль'
                                size={FORM_INPUT_SIZE}
                                rightAddons={
                                    <IconButton
                                        id='username-password-submit'
                                        data-test-id='passSubmitBtn'
                                        disabled={isButtonDisabled()}
                                        icon={
                                            formStatus === FormStatus.SubmitProcess ? (
                                                <Spin size={FORM_BUTTON_SIZE} visible={true} />
                                            ) : (
                                                <IconSubmit size={FORM_BUTTON_SIZE} />
                                            )
                                        }
                                        onClick={handleSubmit}
                                    />
                                }
                                onChange={handleChangePassword}
                                onKeyDown={handleOnKeyDown}
                            />
                        </InputCase>
                    </div>
                </FormField>
            </Form>
            {showLink ? (
                <Button
                    className={cn('button')}
                    view='transparent'
                    size='m'
                    href={investmentsUrl}
                    block={false}
                    colors='inverted'
                >
                    Получить логин и пароль
                </Button>
            ) : null}
        </div>
    );
};

export default LoginPassword;
