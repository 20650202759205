import React, { FC, useEffect } from 'react';
import { createCn } from 'bem-react-classname';

import CardInput from 'arui-feather/card-input';
import Form from 'arui-feather/form';
import FormField from 'arui-feather/form-field';
import IconSubmit from 'arui-feather/icon/ui/submit';
import IconButton from 'arui-feather/icon-button';
import Input from 'arui-feather/input';
import Label from 'arui-feather/label';
import Link from 'arui-feather/link';
import Spin from 'arui-feather/spin';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import {
    validateAccountNumber,
    validateCardNumber,
} from '#/src/lib/client-validation/registration';
import {
    ACCOUNT_INPUT_MAX_LENGTH,
    CARD_INPUT_MAX_LENGTH,
    FORM_BUTTON_SIZE,
    FORM_FIELD_SIZE,
    FORM_INPUT_SIZE,
    FORM_LINK_SIZE,
    FORM_SWITCHER_SIZE,
} from '#/src/lib/form-controls-const';
import { FormStatus, RegistrationType } from '#/src/models';
import { useRequestRegistrationMutation } from '#/src/store/api/registration-api';
import { selectClientId } from '#/src/store/redux/app/selectors';
import {
    getRegistrationAccount,
    getRegistrationCard,
    getRegistrationFormError,
    getRegistrationFormStatus,
    getRegistrationServerErrors,
    getRegistrationType,
} from '#/src/store/redux/registration/selectors';
import {
    registrationErrorUpdated,
    registrationFormUpdated,
    registrationSubmit,
    registrationTypeChanged,
    serverErrorNotificationClosed ,
} from '#/src/store/redux/registration/slice';

import InputCase from '../ui/input-case';
import ServerErrorNotifications from '../ui/server-errors-notification/server-errors-notification';

const cn = createCn('form-basic');

const CardAccountOptional: FC = () => {
    const dispatch = useAppDispatch();
    const [requestRegistration] = useRequestRegistrationMutation();

    const type = useAppSelector(getRegistrationType);
    const account = useAppSelector(getRegistrationAccount);
    const card = useAppSelector(getRegistrationCard);
    const formStatus = useAppSelector(getRegistrationFormStatus);
    const formError = useAppSelector(getRegistrationFormError);
    const serverErrors = useAppSelector(getRegistrationServerErrors);
    const clientId = useAppSelector(selectClientId);

    const handleCardValidate = (prevCard = '') => {
        if (prevCard !== card && card.length === CARD_INPUT_MAX_LENGTH) {
            const cardValidation = validateCardNumber(card);

            if (cardValidation === null) {
                trackUserEvent(
                    'Card Account Not Client Page',
                    'Click',
                    'Card number filled',
                    clientId,
                );
            } else {
                dispatch(registrationErrorUpdated({ card: cardValidation }));
            }
        } else if (prevCard !== card && formError.card) {
            dispatch(registrationErrorUpdated({ card: null }));
        }
    }

    const handleAccountValidate = (prevAccount = '') => {
        if (prevAccount !== account && account.length === ACCOUNT_INPUT_MAX_LENGTH) {
            const accountValidation = validateAccountNumber(account);

            if (accountValidation !== null) {
                dispatch(registrationErrorUpdated({ account: accountValidation }));
            }
        } else if (prevAccount !== account && formError.account) {
            dispatch(registrationErrorUpdated({ account: null }));
        }
    }

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess) {
            requestRegistration();
        }
    }, [formStatus, requestRegistration]);

    useEffect(() => {
        dispatch(registrationErrorUpdated({
            card: null,
            account: null,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    const switchText = type === RegistrationType.Card ? 'счёта' : 'карты';

    const handleRegistrationTypeChange = (event?: React.MouseEvent<any, MouseEvent>) => {
        event?.preventDefault();
        trackUserEvent(
            'Card Account Not Client Page',
            'Click',
            type === RegistrationType.Card
                ? 'Switch From Card To Account'
                : 'Switch From Account To Card',
            clientId,
        );
        dispatch(registrationTypeChanged(
            type === RegistrationType.Card
                ? RegistrationType.Account
                : RegistrationType.Card,
        ));
    }

    const handleCardChange = (cardValue = '') => {
        handleCardValidate(cardValue);
        dispatch(registrationFormUpdated({ card: cardValue.trim() }));
    }

    const handleCardBlur = () => {
        if (card.length > 0 && card.length < CARD_INPUT_MAX_LENGTH) {
            dispatch(registrationErrorUpdated({ card: validateCardNumber(card) }));
        }
    }

    const handleSubmit = () => {
        dispatch(registrationSubmit({
            type,
            account,
            card,
        }));
    }

    const isSubmitButtonDisabled = () => {
        let isDisabled = false;

        if (type === RegistrationType.Card) {
            isDisabled = Boolean(
                card.length < CARD_INPUT_MAX_LENGTH ||
                formError.card ||
                formStatus === FormStatus.SubmitProcess);
        } else if (type === RegistrationType.Account) {
            isDisabled = Boolean(
                account.length < ACCOUNT_INPUT_MAX_LENGTH ||
                formError.account ||
                formStatus === FormStatus.SubmitProcess);
        }

        return isDisabled;
    }

    const handleAccountChange = (accountValue = '') => {
        handleAccountValidate(accountValue);
        dispatch(registrationFormUpdated({ account: accountValue.trim() }));
    }

    const handleAccountBlur = () => {
        if (account.length > 0 && account.length < ACCOUNT_INPUT_MAX_LENGTH) {
            dispatch(registrationErrorUpdated({ account: validateAccountNumber(account) }));
        }
    }

    const handlenotificationClosed = (index: number) => {
        dispatch(serverErrorNotificationClosed(index));
    }

    const renderRegistrationByCard = () => (
        <div className={cn('card')}>
            <FormField size={FORM_FIELD_SIZE}>
                <InputCase>
                    <CardInput
                        size={FORM_INPUT_SIZE}
                        id='creditCardNumber'
                        width='available'
                        value={card}
                        placeholder='0000 0000 0000 0000'
                        maxLength={CARD_INPUT_MAX_LENGTH}
                        label='Номер карты Альфа-Банка'
                        onChange={handleCardChange}
                        onBlur={handleCardBlur}
                        error={formError.card}
                        resetError={false}
                    />
                </InputCase>
            </FormField>
        </div>
    )

    const renderRegistrationByAccount = () => (
        <FormField size={FORM_FIELD_SIZE}>
            <InputCase>
                <Input
                    size={FORM_INPUT_SIZE}
                    id='accountInput'
                    type='tel'
                    width='available'
                    value={account}
                    rightAddons={
                        <IconButton
                            id='submitButton'
                            disabled={isSubmitButtonDisabled()}
                            icon={
                                formStatus === FormStatus.SubmitProcess ? (
                                    <Spin size={FORM_BUTTON_SIZE} visible={true} />
                                ) : (
                                    <IconSubmit size={FORM_BUTTON_SIZE} />
                                )
                            }
                            type='submit'
                            onClick={handleSubmit}
                        />
                    }
                    onChange={handleAccountChange}
                    onBlur={handleAccountBlur}
                    label='Номер счёта в Альфа-Банке'
                    mask='1111 1111 1111 1111 1111'
                    placeholder='0000 0000 0000 0000 0000'
                    error={formError.account}
                    resetError={false}
                />
            </InputCase>
        </FormField>
    )

    return (
        <Form onSubmit={handleSubmit} noValidate={true} className={cn()}>
            <ServerErrorNotifications
                serverErrors={serverErrors}
                onnotificationClosed={handlenotificationClosed}
            />
            <Label size={FORM_LINK_SIZE}>
                По этому номеру телефона уже зарегистрирован
                <br />
                клиент Альфа-Банка. Пожалуйста,
                <br />
                введите данные карты или счёта
            </Label>
            {type === RegistrationType.Card
                ? renderRegistrationByCard()
                : renderRegistrationByAccount()}
            <div className={cn('change-type')}>
                <Link
                    size={FORM_SWITCHER_SIZE}
                    text={`Использовать номер ${switchText}`}
                    onClick={handleRegistrationTypeChange}
                />
            </div>
        </Form>
    );
}

export default CardAccountOptional;
