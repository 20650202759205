import React from 'react';
import { hydrateRoot } from 'react-dom/client';

import { createBrowserHistoryWithBasename } from '#/src/history';
import BrowserRoot from '#/src/routes/root';
import AppHtml, { AppHtmlProps } from '#/src/server/pages/app-html';
import { createStore } from '#/src/store';

import { init as initClientApi } from './lib/client-api';
import { collectTimings } from './middlewares/collect-metrics';

import './index.css';

if (typeof window !== 'undefined') {
    window.runApp = (initialAppProps: Omit<AppHtmlProps, 'children'>) => {
        const { appState } = initialAppProps;
        const store = createStore(appState);
        const { contextRoot, assetsPath } = appState.Settings;
        const webpackPublicPath = `${contextRoot}/${assetsPath}/`;

        // eslint-disable-next-line no-undef,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        __webpack_public_path__ = webpackPublicPath;
        createBrowserHistoryWithBasename(contextRoot);
        initClientApi(contextRoot);
        collectTimings(contextRoot);

        hydrateRoot(
            document,
            <AppHtml {...initialAppProps}>
                <BrowserRoot store={store} />
            </AppHtml>,
        );
    };
}
