import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import Button from 'arui-feather/button';
import Heading from 'arui-feather/heading';

import './explanation.css';

const cn = createCn('explanation');

const Explanation: FC = () => {
    const navigate = useNavigate();

    return (
        <div className={cn()}>
            <div className={cn('icon')} />
            <Heading size='l'>
                Зачем нужен <br /> единый логин?
            </Heading>
            <p className={cn('paragraph')}>
                Па́спорт граждани́на Росси́йской Федера́ции
                <br />
                (также в обиходе называется «внутренним»
                <br />
                или «внутрироссийским паспортом») — основной документ,
                <br />
                удостоверяющий личность гражданина России на
                <br />
                территории РФ.
            </p>
            <p className={cn('paragraph')}>
                Этот документ обязаны иметь все граждане России,
                <br />
                достигшие 14-летнего возраста и проживающие
                <br />
                на территории Российской Федерации. Для выездов
                <br />
                за пределы России (за исключением ряда стран
                <br />
                СНГ, а также Абхазии и Южной Осетии) гражданам
                <br />
                России выдаётся заграничный паспорт.
                <br />
            </p>
            <p className={cn('paragraph')}>Процедура займёт не больше 2 минут</p>
            <Button view='extra' size='m' className={cn('btn')} onClick={() => navigate(-1)}>
                Поехали
            </Button>
        </div>
    );
};

export default Explanation;
