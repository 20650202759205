import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { SystemMessage } from '@alfalab/core-components/system-message';
import { LockClosedMIcon } from '@alfalab/icons-glyph/LockClosedMIcon';

import { BsBankContacts } from '#/src/components/ui/dialog-bank-contacts';
import { useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { Routes } from '#/src/models';
import { selectClientId } from '#/src/store/redux/app/selectors';
import { selectTempPassChanelBlocked } from '#/src/store/redux/temp-password';

import { cn } from '..';

const ErrorScreenPage: FC = () => {
    const navigate = useNavigate();
    const { search } = useLocation();

    const tempPassBlocked = useAppSelector(selectTempPassChanelBlocked);
    const clientId = useAppSelector(selectClientId);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        trackUserEvent(
            'Auth Page',
            'Impression',
            'Viewing Page',
            clientId,
            tempPassBlocked ? 'Auth Block TempPassword Screen' : 'Auth Error TempPassword Screen',
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const textError = tempPassBlocked
        ? {
              title: 'Вход в\u00A0приложение временно заблокирован',
              subtitle:
                  'Для\u00A0восстановления доступа обратитесь с\u00A0паспортом в\u00A0любое отделение Альфа-Банка или\u00A0позвоните\u00A0нам',
          }
        : {
              title: 'Вход по\u00A0временному паролю недоступен',
              subtitle: 'Запросите новый временный пароль или\u00A0войдите другим способом',
          };

    return (
        <React.Fragment>
            <SystemMessage className={cn('error-screen')}>
                <div className={cn('error-screen-main')}>
                    <SystemMessage.Graphic>
                        <SuperEllipse size={80}>
                            <LockClosedMIcon color='var(--color-light-neutral-translucent-1300)' />
                        </SuperEllipse>
                    </SystemMessage.Graphic>
                    <SystemMessage.Title>{textError.title}</SystemMessage.Title>
                    <SystemMessage.Subtitle>{textError.subtitle}</SystemMessage.Subtitle>
                </div>

                <SystemMessage.Controls>
                    {!tempPassBlocked && (
                        <Button
                            block={true}
                            view='primary'
                            onClick={() => {
                                navigate({
                                    pathname: Routes.CARD_ACCOUNT,
                                    search,
                                });
                            }}
                        >
                            Войти другим способом
                        </Button>
                    )}
                    <Button block={true} view='secondary' onClick={() => setOpen(true)}>
                        Позвонить в банк
                    </Button>
                    <Gap size='m' direction='horizontal' />
                </SystemMessage.Controls>
            </SystemMessage>
            <BsBankContacts
                title='Позвонить в банк'
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            />
        </React.Fragment>
    );
};

export const ErrorScreenTempPass = ErrorScreenPage;
