import { useContext } from 'react';

import { ModalsContext } from './modals-context';

export const useModalsContext = () => {
    const context = useContext(ModalsContext);

    if (context === null) {
        throw new Error('useModalsContext should use in ModalsContext.Provider');
    }

    return context;
};
