import React from 'react';
import { createCn } from 'bem-react-classname';

import keyboardLayout from './lib/keyboard-layout';
import utilities from './lib/utilities';

import './keyboard.css';

function exchangeValueButtonToDefault(button: string) {
    const defaultButtons: Record<string, string> = utilities.getDefaultDisplay();

    if (defaultButtons[button]) {
        return defaultButtons[button];
    }

    return button;
}

interface Props {
    layoutName: string;
    onClick(event: any): void;
}

const cn = createCn('keyboard');

const Keyboard: React.FC<Props> = ({
    layoutName,
    onClick,
}) => {
    const handleOnClick = (event: any) => onClick(event?.target?.textContent);

    const renderButton = (string: string) => {
        const buttonsArray = string.split(' ');

        return buttonsArray.map((button) => (
            <span
                key={button.toString()}
                className={button === '{space}' ? cn('button space') : cn('button')}
                role='button'
                tabIndex={0}
                onClick={handleOnClick}
                onKeyUp={handleOnClick}
            >
                {exchangeValueButtonToDefault(button)}
            </span>
        ));
    }

    const renderRows = (array: string[]) => array.map((elem) => (
        <div key={elem.toString()} className={cn('row')}>
            {renderButton(elem)}
        </div>
    ))

    const keysTemplate = keyboardLayout(layoutName);
    const layout = renderRows(keysTemplate);

    return (
        <div data-test-id='passKeyboard' className={cn()}>
            {layout}
        </div>
    );
}

export default Keyboard;
