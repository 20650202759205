import { NavigatorNetworkInformation } from 'corporate-services/lib/collect-timings-types';
import { MetricsRequestParams, PartialMetric } from 'corporate-services/model/prometheus';

import factoryFetch from '#/src/lib/fetch-factory';
import { HttpMethod } from '#/src/utils';

const timingEndpoint = '/api/client-timings';

export function collectTimings(contextRoot: string) {
    // Некоторые метрики могут быть собраны несколько раз, поэтому мы просто храним их в объекте пока не соберем все
    let metrics: Record<string, PartialMetric> = {};

    function addToQueue(metric: PartialMetric) {
        metrics[metric.name] = metric;
    }

    // Собирает данные по network метрикам. Может не работать в части браузеров, но что поделаешь.
    function collectTimingData() {
        try {
            const navigationEntries = performance.getEntriesByType('navigation');

            for (let i = 0; i < navigationEntries.length; i += 1) {
                const navigationEntry = navigationEntries[i];

                if (isNavigationTiming(navigationEntry)) {
                    addToQueue({
                        name: 'domContentLoaded',
                        value: navigationEntry.domContentLoadedEventEnd,
                    });
                    addToQueue({
                        name: 'domComplete',
                        value: navigationEntry.domComplete,
                    });
                    addToQueue({
                        name: 'domInteractive',
                        value: navigationEntry.domInteractive,
                    });

                    return;
                }
            }
        } catch (e) {
            // do nothing
        }
    }

    collectTimingData();

    // Метрики мы будем отправлять только тогда, когда страница станет не активной. Это событие так же отлавливает закрытие вкладки
    window.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'hidden') {
            flushQueue();
        }
    });

    // Safari не всегда корректно отлавливает событие `visibilitychange` при закрытии вкладки, поэтому мы так же отлавливаем `pagehide`
    window.addEventListener('pagehide', flushQueue);

    const timingFullUrl = `${contextRoot}${timingEndpoint}`;

    function flushQueue() {
        if (Object.keys(metrics).length > 0) {
            collectTimingData();
            // оставляем только те поля, которые нам реально нужны, чтобы не гонять по сети лишнее
            const serializedMetrics = Object.keys(metrics).map((key) => {
                const metric = metrics[key];

                return {
                    name: metric.name,
                    value: metric.value,
                    rating: metric.rating,
                };
            });

            const valueToSubmit: MetricsRequestParams = {
                metrics: serializedMetrics,
                // https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/effectiveType - отсутствует в типах, но есть в реальности
                effectiveConnectionType:
                    (navigator as NavigatorNetworkInformation)?.connection?.effectiveType ??
                    'unknown',
            };

            const body = JSON.stringify(valueToSubmit);

            // По возможности используем sendBeacon, потому что он нормально работает при закрытии вкладки
            if (navigator.sendBeacon) {
                navigator.sendBeacon(timingFullUrl, body);
            } else {
                factoryFetch(timingFullUrl, HttpMethod.POST)(body);
            }

            metrics = {};
        }
    }
}

function isNavigationTiming(value: PerformanceEntry): value is PerformanceNavigationTiming {
    return value.entryType === 'navigation';
}
