import { createSelector } from 'reselect';

import { ApplicationState } from '#/src/store/types';

/** Функция получения состояния QR авторизации. */
export const selectQrState = (state: ApplicationState) => state.QRAuthorization;

export const selectQrIsEnabled = createSelector([selectQrState], (qr) => qr.isQREnabled);

export const selectQrCode = createSelector([selectQrState], (qr) => qr.QRCode);

export const selectQrWebSocketConnectionStatus = createSelector(
    [selectQrState],
    (qr) => qr.QRWebSocketConnectionStatus,
);

export const selectQrIntervalRequest = createSelector(
    [selectQrState],
    (qr) => qr.QRIntervalRequest,
);
