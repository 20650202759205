import React, { FC, useState } from 'react';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Link } from '@alfalab/core-components/link';
import { ModalMobile } from '@alfalab/core-components/modal/mobile';
import { ModalMobileProps } from '@alfalab/core-components/modal/typings';
import { Typography } from '@alfalab/core-components/typography';
import { BankMIcon } from '@alfalab/icons-glyph/BankMIcon';
import { PhoneBubbleMIcon } from '@alfalab/icons-glyph/PhoneBubbleMIcon';

import { BsBankContacts } from '#/src/components/ui/dialog-bank-contacts';

import { cn } from '..';

export const ModalAnotherWay: FC<ModalMobileProps> = ({ open, onClose }) => {
    const [openCallback, setOpenCallback] = useState(false);

    return (
        <ModalMobile open={open} onClose={onClose}>
            <ModalMobile.Header>
                Два способа войти, если не помните номер карты и счёта
            </ModalMobile.Header>
            <ModalMobile.Content className={cn('modal-another-way')}>
                <div className={cn('modal-another-way--item')}>
                    <SuperEllipse>
                        <PhoneBubbleMIcon color='#86868A' />
                    </SuperEllipse>
                    <div>
                        <Typography.TitleMobile tag='h3' view='xsmall'>
                            Позвонить в поддержку
                        </Typography.TitleMobile>
                        <Link view='default' href='tel:88002000000' underline={false}>
                            8 (800) 200-00-00
                        </Link>
                        <Gap size='2xs' />
                        <Typography.Text color='secondary' view='secondary-large'>
                            Оператор отправит вам смс с номером карты или счёта. Нужен будет
                            паспорт.
                        </Typography.Text>
                    </div>
                </div>

                <div className={cn('modal-another-way--item')}>
                    <SuperEllipse>
                        <BankMIcon color='#86868A' />
                    </SuperEllipse>
                    <div>
                        <Typography.TitleMobile tag='h3' view='xsmall'>
                            Прийти в офис банка с паспортом
                        </Typography.TitleMobile>
                        <Gap size='2xs' />
                        <Typography.Text color='secondary' view='secondary-large'>
                            Наш сотрудник восстановит номер карты или счёта.
                        </Typography.Text>
                    </div>
                </div>

                <Button
                    className={cn('modal-another-way--button')}
                    block={true}
                    view='primary'
                    onClick={() => {
                        setOpenCallback(true);
                    }}
                >
                    Позвонить в банк
                </Button>
            </ModalMobile.Content>
            <BsBankContacts
                title='Контакты банка'
                open={openCallback}
                onClose={() => setOpenCallback(false)}
            />
        </ModalMobile>
    );
};
