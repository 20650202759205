import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Input, InputProps } from '@alfalab/core-components/input';
import { PasswordInput } from '@alfalab/core-components/password-input';
import { Typography } from '@alfalab/core-components/typography';
import Form, { FormProps } from 'arui-feather/form';

import ServerErrorNotificationsNew from '#/src/components/ui/server-errors-notification/server-errors-notification-new';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { newValidateLogin, newValidatePassword } from '#/src/lib/client-validation/authorization';
import {
    LOGIN_INPUT_MAX_LENGTH,
    LOGIN_INPUT_MIN_LENGTH,
    PASSWORD_INPUT_MAX_LENGTH,
    PASSWORD_INPUT_MIN_LENGTH,
} from '#/src/lib/form-controls-const';
import validationDictionary from '#/src/lib/validation-dictionary';
import { FormStatus, Routes, ValidationType } from '#/src/models';
import {
    useGetAlternativeLoginMutation,
    useGetLoginInformationMutation,
    useUpdateAlternativeLoginOrPassMutation,
} from '#/src/store/api/registration-api';
import {
    getQueryRedirectParams,
    selectMultiFactorResponseParams,
    selectPassword,
} from '#/src/store/redux/app/selectors';
import { getAuthorizationLogin } from '#/src/store/redux/authorization/selectors';
import {
    getRegistrationFormError,
    getRegistrationFormStatus,
    getRegistrationServerErrors,
    selectRegistrationErrorUpdate,
    selectRegistrationNewLogin,
    selectRegistrationNewPassword,
    selectRegistrationRepeatedNewPassword,
} from '#/src/store/redux/registration/selectors';
import {
    registrationErrorUpdated,
    registrationFormReset,
    registrationFormUpdated,
    serverErrorNotificationClosed,
} from '#/src/store/redux/registration/slice';

import BackButton from '../../ui/back-button';

import './recovery-browser.css';

const cn = createCn('recovery-browser');

const RecoveryBrowser: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const [getAlternativeLogin] = useGetAlternativeLoginMutation();
    const [getLoginInformation] = useGetLoginInformationMutation();
    const [updateAlternativeLoginOrPass] = useUpdateAlternativeLoginOrPassMutation();

    const errorUpdate = useAppSelector(selectRegistrationErrorUpdate);
    const formStatus = useAppSelector(getRegistrationFormStatus);
    const error = useAppSelector(getRegistrationFormError);
    const serverErrors = useAppSelector(getRegistrationServerErrors);
    const previousMultiFactorResponseParams = useAppSelector(selectMultiFactorResponseParams);
    const login = useAppSelector(getAuthorizationLogin);
    const oldPassword = useAppSelector(selectPassword);
    const newLogin = useAppSelector(selectRegistrationNewLogin);
    const newPassword = useAppSelector(selectRegistrationNewPassword);
    const repeatedNewPassword = useAppSelector(selectRegistrationRepeatedNewPassword);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);

    const [isProcessing, setIsProcessing] = useState(true);
    const newLoginInput = useRef<HTMLInputElement>(null);
    const newPasswordInput = useRef<HTMLInputElement>(null);
    const repeatNewPasswordInput = useRef(null);

    const setInputState = (login: string, username: string, input: HTMLInputElement | null) => {
        if (!login) {
            dispatch(
                registrationFormUpdated({
                    newLogin: username,
                }),
            );
        }
        setIsProcessing(false);

        input?.focus();
    };

    useEffect(() => {
        getAlternativeLogin({
            login: previousMultiFactorResponseParams.username,
        });
        if (newLogin) {
            setInputState(
                newLogin,
                previousMultiFactorResponseParams.username,
                newPasswordInput.current,
            );
            setIsProcessing(false);
            newPasswordInput.current?.focus();
        } else {
            setIsProcessing(false);
            newLoginInput.current?.focus();
        }

        return () => {
            dispatch(registrationFormReset());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (errorUpdate) {
            navigate({
                pathname: Routes.CARD_ACCOUNT,
                search,
            });
        }
    }, [errorUpdate, navigate, search]);

    const handleValidateLogin = (login: string) => {
        const validationStatus = newValidateLogin(login);

        if (validationStatus !== null) {
            dispatch(
                registrationErrorUpdated({
                    newLogin: validationStatus,
                }),
            );
        }
    };

    const handleChangeLogin = (
        _event: React.ChangeEvent<HTMLInputElement>,
        payload: { value: string },
    ) => {
        const { value: newLogin } = payload;

        if (error.newLogin) {
            dispatch(
                registrationErrorUpdated({
                    newLogin: null,
                }),
            );
        }

        handleValidateLogin(newLogin);

        dispatch(
            registrationFormUpdated({
                newLogin,
            }),
        );
    };

    const handleClearLogin = () => {
        dispatch(
            registrationFormUpdated({
                newLogin: '',
            }),
        );
    };

    const handleValidatePassword = (password: string, validationType: string) => {
        const validationStatus = newValidatePassword(password);

        if (validationStatus !== null) {
            if (ValidationType.NewPassword === validationType) {
                dispatch(
                    registrationErrorUpdated({
                        newPassword: validationStatus,
                    }),
                );
            } else {
                dispatch(
                    registrationErrorUpdated({
                        repeatedNewPassword: validationStatus,
                    }),
                );
            }
        }
    };

    const validateNewPassword = (password: string) => {
        if (error.newPassword) {
            dispatch(
                registrationErrorUpdated({
                    newPassword: null,
                }),
            );
        }

        handleValidatePassword(password, ValidationType.NewPassword);

        if (login === password || newLogin === password) {
            dispatch(
                registrationErrorUpdated({
                    passwordsEqual: validationDictionary.LOGIN_EQUAL_PASSWORD,
                }),
            );

            return;
        }

        if (password === oldPassword) {
            dispatch(
                registrationErrorUpdated({
                    passwordsEqual: validationDictionary.OLD_PASSWORD_EQUAL_NEW,
                }),
            );
        } else {
            dispatch(
                registrationErrorUpdated({
                    passwordsEqual: null,
                }),
            );
        }
    };

    const validateRepeatedNewPassword = (password: string, newPass: string) => {
        if (newPass) {
            if (password.length !== 0 && password !== newPass) {
                dispatch(
                    registrationErrorUpdated({
                        repeatedNewPassword: validationDictionary.PASSWORD_EQUAL,
                    }),
                );
            } else {
                dispatch(
                    registrationErrorUpdated({
                        repeatedNewPassword: null,
                    }),
                );
            }
        }
    };

    const handleChangeNewPassword = (
        _event: React.ChangeEvent<HTMLInputElement>,
        payload: { value: string },
    ) => {
        const { value: password } = payload;

        validateNewPassword(password);

        dispatch(
            registrationFormUpdated({
                newPassword: password,
            }),
        );
    };

    const handleChangeRepeatedNewPassword = (
        _event: React.ChangeEvent<HTMLInputElement>,
        payload: { value: string },
        newPass: string,
    ) => {
        const { value: password } = payload;

        validateRepeatedNewPassword(password, newPass);

        dispatch(
            registrationFormUpdated({
                repeatedNewPassword: password,
            }),
        );
    };

    const isEqualWithAlternativeLogin = (loginValue: string) =>
        previousMultiFactorResponseParams.alternativeLogin === loginValue ||
        previousMultiFactorResponseParams.username === loginValue;

    const handleSubmit: FormProps['onSubmit'] = (e) => {
        e?.preventDefault();
        if (!isEqualWithAlternativeLogin(newLogin)) {
            getLoginInformation({ login: newLogin });
        }

        updateAlternativeLoginOrPass({
            login: previousMultiFactorResponseParams.username,
            alt_login: newLogin,
            new_password: newPassword,
            relying_party: queryRedirectParams.client_id,
            alt_log_changed: login !== newLogin,
            skip: false,
            queryRedirectParams,
            previousMultiFactorResponseParams,
        });
    };

    const handleBlurLogin: InputProps['onBlur'] = (e) => {
        if (
            newLogin &&
            newLogin.length >= LOGIN_INPUT_MIN_LENGTH &&
            !error.newLogin &&
            !isEqualWithAlternativeLogin(e.target.value)
        ) {
            getLoginInformation({ login: newLogin });
        }
    };

    const isSubmitButtonDisabled = () => {
        let isDisabled = false;

        isDisabled = Boolean(
            newPassword !== repeatedNewPassword ||
                login === newPassword ||
                newPassword === oldPassword ||
                repeatedNewPassword.length < newPassword.length ||
                newLogin.length < LOGIN_INPUT_MIN_LENGTH ||
                newPassword.length < PASSWORD_INPUT_MIN_LENGTH ||
                repeatedNewPassword.length < PASSWORD_INPUT_MIN_LENGTH ||
                error.newLogin ||
                error.newPassword ||
                error.repeatedNewPassword ||
                error.passwordsEqual,
        );

        return isDisabled;
    };

    return (
        <Form onSubmit={handleSubmit} noValidate={true} className={cn()}>
            <ServerErrorNotificationsNew
                errorMessage={serverErrors[0]?.message}
                onClose={() => {
                    dispatch(serverErrorNotificationClosed(0));
                }}
            />

            <div className={cn('btn-back')}>
                <BackButton />
            </div>

            <Typography.Title className={cn('title')} view='small' font='styrene' tag='h1'>
                Придумайте логин и пароль
            </Typography.Title>
            <Typography.Text
                view='primary-small'
                color='secondary'
                tag='span'
                className={cn('subtitle')}
            >
                Используйте от 6 до 10 букв латинского алфавита, минимум одну заглавную букву и одну
                цифру
            </Typography.Text>
            <Input
                ref={newLoginInput}
                error={error.newLogin}
                className={cn('input')}
                value={newLogin}
                label='Логин'
                size='xl'
                block={true}
                clear={true}
                onClear={handleClearLogin}
                onChange={handleChangeLogin}
                onBlur={handleBlurLogin}
                maxLength={LOGIN_INPUT_MAX_LENGTH}
                disabled={isProcessing}
            />
            <PasswordInput
                className={cn('input')}
                ref={newPasswordInput}
                value={newPassword}
                error={error.newPassword || error.passwordsEqual}
                label='Пароль'
                block={true}
                clear={false}
                size='xl'
                onChange={handleChangeNewPassword}
                maxLength={PASSWORD_INPUT_MAX_LENGTH}
                disabled={isProcessing}
            />
            <PasswordInput
                ref={repeatNewPasswordInput}
                className={cn('input')}
                block={true}
                clear={false}
                size='xl'
                value={repeatedNewPassword}
                error={error.repeatedNewPassword}
                label='Повторите пароль'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeRepeatedNewPassword(e, e.target, newPassword)
                }
                maxLength={PASSWORD_INPUT_MAX_LENGTH}
                disabled={isProcessing}
            />
            <Button
                view='primary'
                className={cn('button')}
                type='submit'
                block={true}
                disabled={isSubmitButtonDisabled()}
                onClick={handleSubmit}
                loading={formStatus === FormStatus.SubmitProcess}
            >
                Сохранить и войти
            </Button>
        </Form>
    );
};

export default RecoveryBrowser;
