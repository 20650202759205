import { createSlice } from '@reduxjs/toolkit';

import { CustomDesignInitialState } from '#/src/store/redux/custom-design/constants';

export const customDesignSlice = createSlice({
    name: 'customDesign',
    initialState: CustomDesignInitialState,
    reducers: {
        customDesignParamSet(state, action) {
            return {...state, ...action.payload}
        },
    }
})

export const {
    customDesignParamSet,
} = customDesignSlice.actions;

export default customDesignSlice.reducer
