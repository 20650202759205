import { useCallback, useEffect, useRef, useState } from 'react';

import Worker from '../worker';

type Props = {
    activateSession: (code: string) => Promise<string>;
    getFrame: () => ImageData | null;
};
type WorkerMsg = {
    data: {
        code: string;
        docType: string;
        data: {
            type: string;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            number: Record<string, any>;
        };
        requestType: string;
    };
};

const useWorkerInit = () => {
    const workerRef = useRef<Worker | null>(null);

    useEffect(() => {
        workerRef.current = new Worker();

        return () => {
            workerRef.current?.terminate();
        };
    }, []);

    return workerRef;
};

export const useWorker = ({ getFrame, activateSession }: Props) => {
    const [result, setResult] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    const workerRef = useWorkerInit();

    const retryRecognition = useCallback(() => {
        if (loading) {
            return;
        }

        setTimeout(
            () =>
                workerRef.current?.postMessage({
                    requestType: 'frame',
                    imageData: getFrame(),
                }),
            0,
        );
    }, [workerRef, getFrame, loading]);

    useEffect(() => {
        const worker = workerRef.current;

        if (!worker) {
            return;
        }
        const onMessage = (msg: WorkerMsg) => {
            const resultData = msg.data;

            switch (resultData.requestType) {
                case 'wasmEvent': {
                    if (resultData.data.type === 'ready') {
                        setLoading(false);
                    }
                    break;
                }
                case 'activation': {
                    activateSession(resultData.code).then((code) => {
                        workerRef.current?.postMessage({ requestType: 'activation', code });
                    });
                    break;
                }
                case 'feedMeMore': {
                    workerRef.current?.postMessage({
                        requestType: 'frame',
                        imageData: getFrame(),
                    });

                    break;
                }
                case 'result': {
                    if (resultData.docType === '') {
                        retryRecognition();
                    } else {
                        setResult(resultData.data?.number?.value);
                        workerRef.current?.postMessage({ requestType: 'reset' });
                    }

                    break;
                }
            }
        };

        worker.addEventListener('message', onMessage);

        // eslint-disable-next-line consistent-return
        return () => {
            if (worker) {
                worker.removeEventListener('message', onMessage);
            }
        };
    }, [workerRef, activateSession, getFrame, retryRecognition]);

    const recognizerFrame = useCallback(
        (imageData: ImageData | null) => {
            if (loading) {
                return;
            }
            workerRef.current?.postMessage({ requestType: 'frame', imageData });
        },
        [workerRef, loading],
    );

    const clearResult = useCallback(() => setResult(null), []);

    // Метод необходим для юнит тестирования хука в изоляции от работы воркера.
    // Позволяет инициировать успешную загрузку воркера, которую должен инициировать сам воркер
    const setLoadingMethodForTests = useCallback(
        (loadingState: boolean) => setLoading(loadingState),
        [],
    );

    return {
        result,
        clearResult,
        loading,
        /**
         * Use only for tests.
         *
         * @param {boolean} loadingState State of worker loading.
         */
        setLoadingMethodForTests,
        recognizerFrame,
    };
};
