import React from 'react';

import { useAppSelector } from '#/src/hooks';
import { PasscodeType } from '#/src/models';
import { selectIsMobile } from '#/src/store/redux/app/selectors';
import { selectPasscodeActiveType, selectPasscodeTypes } from '#/src/store/redux/passcode';

import { AlphanumericPasscode, GraphicPatternPasscode, NumericPasscode } from '../passcode-types';

const PasscodeComponentsDesktop = {
    [PasscodeType.Numeric]: <AlphanumericPasscode />,
    [PasscodeType.GraphicPattern]: <GraphicPatternPasscode />,
    [PasscodeType.Alphanumeric]: <AlphanumericPasscode />,
};

const PasscodeComponentsMobile = {
    [PasscodeType.Numeric]: <NumericPasscode />,
    [PasscodeType.GraphicPattern]: <GraphicPatternPasscode />,
    [PasscodeType.Alphanumeric]: <AlphanumericPasscode />,
};

const ActivePasscode = () => {
    const isMobile = useAppSelector(selectIsMobile);
    const activeTypePasscode = useAppSelector(selectPasscodeActiveType);
    const passcodeTypes = useAppSelector(selectPasscodeTypes);

    const PasscodeComponents = isMobile ? PasscodeComponentsMobile : PasscodeComponentsDesktop;

    if (passcodeTypes.length === 1) {
        return PasscodeComponents[passcodeTypes[0]];
    }

    return PasscodeComponents[activeTypePasscode];
};

export default ActivePasscode;
