export default class Utilities {
    static getDefaultDisplay() {
        return {
            '{bksp}': 'backspace',
            '{enter}': '< enter',
            '{shift}': 'shift',
            '{s}': 'shift',
            '{tab}': 'tab',
            '{lock}': 'caps',
            '{accept}': 'Submit',
            '{space}': ' ',
            '{//}': ' ',
        };
    }
}
