import React from 'react';

import DesignCheck from '../ui/design-check';

import MessengerPhoneAuth from './messenger-phone-auth';
import PhoneAuth from './phone-auth';
import PhoneAuthBrowser from './phone-auth-browser';
import PhoneAuthNew from './phone-auth-new';
import X5WebPhoneAuth from './x5-web-phone-auth';

export default (props: any) => (
    <DesignCheck
        defaultComponent={ <PhoneAuthBrowser { ...props } /> }
        defaultMobileComponent={ <PhoneAuthBrowser { ...props } /> }
        mobileComponent={ <PhoneAuthNew { ...props } /> }
        corpComponent={ <PhoneAuth { ...props } /> }
        bankMessengerComponent={ <MessengerPhoneAuth { ...props } /> }
        X5WebComponent={ <X5WebPhoneAuth { ...props } /> }
    />
);
