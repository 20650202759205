import React, { SVGProps } from 'react';

const Barcode: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="25"
      fill="none"
      viewBox="0 0 45 25"
      { ...props }
    >
      <path fill="#000" d="M4 0H8V25H4z" />
      <path fill="#000" d="M23 0H27V25H23z" />
      <path fill="#000" d="M10 0H14V25H10z" />
      <path fill="#000" d="M29 0H33V25H29z" />
      <path fill="#000" d="M38 0H42V25H38z" />
      <path fill="#000" d="M0 0H2V25H0z" />
      <path fill="#000" d="M19 0H21V25H19z" />
      <path fill="#000" d="M43 0H45V25H43z" />
      <path fill="#000" d="M16 0H17V25H16z" />
      <path fill="#000" d="M35 0H36V25H35z" />
    </svg>
);

export { Barcode };