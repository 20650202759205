import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FormStatus } from '#/src/models';
import { PasscodeAuthorizationInitialState } from '#/src/store/redux/passcode/constants';

import { PasscodeTypeValues } from './types';

export const passcodeAuthorizationSlice = createSlice({
    name: 'passcodeAuthorization',
    initialState: PasscodeAuthorizationInitialState,
    reducers: {
        passcodeRequest(state) {
            state.formStatus = FormStatus.SubmitProcess;
        },
        passcodeRequestResolved(state, action) {
            return {
                ...state,
                ...action.payload,
                formStatus: FormStatus.SubmitSuccess,
            };
        },
        passcodeErrorSet(state, action) {
            state.error = action.payload;
        },
        passcodeUpdated(
            state,
            action: PayloadAction<Partial<typeof PasscodeAuthorizationInitialState>>,
        ) {
            Object.assign(state, action.payload);
        },
        passcodeSet(state, action) {
            state.passcode = action.payload;
        },
        isAltAuthModalOpenSet(state, action) {
            state.isAltAuthModalOpen = action.payload;
        },
        isPasscodeAuthSet(state, action) {
            state.isPasscodeAuth = action.payload;
        },
        activeTypePasscodeSet(state, action: PayloadAction<PasscodeTypeValues>) {
            state.activeTypePasscode = action.payload;
        },
        showToastErrorSet(state, action: PayloadAction<boolean>) {
            state.showToastError = action.payload;
        },
    },
});

export const {
    passcodeRequest,
    passcodeRequestResolved,
    passcodeErrorSet,
    passcodeUpdated,
    passcodeSet,
    isAltAuthModalOpenSet,
    isPasscodeAuthSet,
    activeTypePasscodeSet,
    showToastErrorSet,
} = passcodeAuthorizationSlice.actions;

export default passcodeAuthorizationSlice.reducer;
