import React from 'react';
import { IconButton } from '@alfalab/core-components/icon-button';
import { InformationCircleLineMIcon } from '@alfalab/icons-glyph/InformationCircleLineMIcon';
import { useModalsContext } from '#/src/components/alternative-login/modals/use-modals-context';

export const AlternativeLoginButton = () => {
    const { onModalOpen } = useModalsContext();

    return (
        <IconButton
            icon={InformationCircleLineMIcon}
            onClick={() => onModalOpen('inform')}
        />
    );
}
