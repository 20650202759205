type Params = Record<string, string>;

function parseLocationHash(): Params {
    const hash = window.location.hash.substring(1);
    const params: Params = {};

    hash.split('&').forEach((item) => {
        const [key, value] = item.split('=');

        params[key] = value;
    });

    return params;
}

export default parseLocationHash;
