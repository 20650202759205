import React, { useState } from 'react';
import { createCn } from 'bem-react-classname';

import { BottomSheet } from '@alfalab/core-components/bottom-sheet';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';
import { VideoCameraOffMIcon } from '@alfalab/icons-glyph/VideoCameraOffMIcon';

import { getBrowserAndOS } from '#/src/lib/browser-detect';

import { AndroidText } from './android-text';
import { IosText } from './ios-text';

import './index.css';

type Props = { className?: string; dataTestId?: string };

const apps = <Typography.Text weight='bold'>Приложения</Typography.Text>;
const permissions = <Typography.Text weight='bold'>Разрешения</Typography.Text>;
const camera = <Typography.Text weight='bold'>Камера</Typography.Text>;
const allow = <Typography.Text weight='bold'>Разрешить</Typography.Text>;

export const CameraAccess: React.FC<Props> = ({ className, dataTestId }) => {
    const cn = createCn('camera-access', className);
    const [hintOpened, setHintOpened] = useState(false);

    const openHint = () => {
        setHintOpened(true);
    };

    const closeHint = () => {
        setHintOpened(false);
    };

    const { os } = getBrowserAndOS();

    return (
        <div className={cn('content')} data-test-id={dataTestId}>
            <SuperEllipse>
                <VideoCameraOffMIcon className={cn('icon')} />
            </SuperEllipse>

            <Typography.Title tag='div' view='xsmall' className={cn('head')}>
                Нет доступа к&nbsp;камере
            </Typography.Title>

            <Typography.Text view='component-primary' className={cn('text')}>
                Разрешите доступ к&nbsp;камере в&nbsp;настройках браузера.
                {(os === 'android' || os === 'iOS') && (
                    <Link view='primary' className={cn('link')} onClick={openHint}>
                        Подробнее
                    </Link>
                )}
            </Typography.Text>

            <BottomSheet
                open={hintOpened}
                hasCloser={true}
                title='Как дать доступ к&nbsp;камере'
                onClose={closeHint}
            >
                {os === 'iOS' ? (
                    <IosText camera={camera} allow={allow} />
                ) : (
                    <AndroidText
                        apps={apps}
                        camera={camera}
                        allow={allow}
                        permissions={permissions}
                    />
                )}
            </BottomSheet>
        </div>
    );
};
