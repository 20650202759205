import React from 'react';

import { Gap } from '@alfalab/core-components/gap';
import { GenericWrapper } from '@alfalab/core-components/generic-wrapper';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';
import PhoneMIcon from '@alfalab/icons-glyph/PhoneMIcon';

export const PhoneNumberSection = () => (
    <React.Fragment>
        <GenericWrapper justifyContent='between'>
            <GenericWrapper>
                <GenericWrapper column={true}>
                    <Typography.Text color='primary'>+7 495 788 88 78</Typography.Text>
                    <Typography.Text view='primary-medium' tag='div' color='secondary'>
                        В Москве и за границей
                    </Typography.Text>
                </GenericWrapper>
            </GenericWrapper>
            <GenericWrapper alignItems='center'>
                <Link href='tel:+74957888878' underline={false}>
                    <PhoneMIcon />
                </Link>
            </GenericWrapper>
        </GenericWrapper>
        <GenericWrapper>
            <Gap size='l' direction='vertical' />
        </GenericWrapper>
        <GenericWrapper justifyContent='between'>
            <GenericWrapper>
                <GenericWrapper column={true}>
                    <Typography.Text view='primary-medium' tag='div'>
                        8 800 200 00 00
                    </Typography.Text>
                    <Typography.Text view='primary-medium' tag='div' color='secondary'>
                        Для звонков по России
                    </Typography.Text>
                </GenericWrapper>
            </GenericWrapper>
            <GenericWrapper alignItems='center'>
                <Link href='tel:+78002000000' underline={false}>
                    <PhoneMIcon />
                </Link>
            </GenericWrapper>
        </GenericWrapper>
    </React.Fragment>
);
