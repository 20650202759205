import React from 'react';
import { createCn } from 'bem-react-classname';

import { Spinner } from '@alfalab/core-components/spinner';

import './index.css';

type Props = {
    transparent?: boolean;
    schema?: 'light' | 'dark';
    className?: string;
};

export const ScannerSpinner: React.FC<Props> = ({ transparent, schema = 'dark', className }) => {
    const styles = [
        className,
        transparent && 'transparent',
        schema === 'dark' ? 'dark' : 'light'
    ].filter(Boolean) as string[];
    const cn = createCn('scanner-spinner', ...styles);

    return (
        <div className={cn('component')}>
            <Spinner size='m' visible={true} colors={schema === 'dark' ? 'inverted' : 'default'} />
        </div>
    );
};
