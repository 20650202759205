import React from 'react';

import { Button } from '@alfalab/core-components/button';
import { ModalMobile } from '@alfalab/core-components/modal/mobile';
import { ModalMobileProps } from '@alfalab/core-components/modal/typings';
import { Typography } from '@alfalab/core-components/typography';

import { cn } from '..';

interface ModalProps extends ModalMobileProps {
  /** Обработчик "Войти другим способом" */
  onChange(): void;
}

export const Modal = ({ open, onClose, onChange }: ModalProps) => (
  <ModalMobile open={open} onClose={onClose}>
      <ModalMobile.Header>Забыли код?</ModalMobile.Header>
      <ModalMobile.Content>
          <Typography.Text>Войдите в Альфа-Онлайн другим способом и обновите секретный код</Typography.Text>
      </ModalMobile.Content>
      <ModalMobile.Footer className={cn('modal-footer')} sticky={true} layout='column'>
          <Button
              block={true}
              view='secondary'
              onClick={onClose}
          >
              Вернуться
          </Button>

          <Button
              block={true}
              view='primary'
              onClick={() => { onChange(); }}
          >
              Войти другим способом
          </Button>

      </ModalMobile.Footer>
  </ModalMobile>
);
