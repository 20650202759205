import React, { FC, memo } from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';

import { ErrorCode } from '#/src/components/error/error-code';

import './style.css';

export type HintProps = {
    /**
     * Отображать в мобильной версии экран компонента
     */
    mobile: boolean;
    onButtonClick: () => void;
};

const cn = createCn('sms-hint');

/*
    Используем кастомный экран для отображения trace-id
*/
const SmsHint: FC<HintProps> = ({ mobile, onButtonClick }) => {
    const textColor = mobile ? 'secondary' : 'primary';
    return (
        <div className={cn('container')}>
            <div className={cn('component')}>
                <Typography.TitleResponsive
                    tag='h3'
                    color={textColor}
                    view='small'
                    className={mobile ? cn('titleMobile') : cn('title')}
                >
                    Не&nbsp;приходит сообщение?
                </Typography.TitleResponsive>
                <Typography.Text
                    view='primary-medium'
                    color={textColor}
                    className={mobile ? cn('textMobile') : cn('text')}
                >
                    Если у вас изменился номер телефона, позвоните нам или обратитесь в&nbsp;любое
                    отделение банка.
                </Typography.Text>

                <div className={cn('phonesWrap')}>
                    <div className={mobile ? cn('phoneContentMobile') : cn('phoneWrap')}>
                        <Link href='tel:+78002000000' underline={false} className={cn('phoneLink')}>
                            8 800 200 00 00
                        </Link>
                        <Typography.Text
                            view='primary-medium'
                            color={textColor}
                            className={mobile ? cn('textMobile') : cn('text')}
                        >
                            {mobile
                                ? 'Для\u00A0звонков по\u00A0России'
                                : ' \u2014\u00A0для звонков по\u00A0России'}
                        </Typography.Text>
                    </div>

                    <div className={mobile ? cn('phoneContentMobile') : cn('phoneWrap')}>
                        <Link href='tel:+74957888878' underline={false} className={cn('phoneLink')}>
                            +7 495 78 888 78
                        </Link>
                        <Typography.Text
                            view='primary-medium'
                            color={textColor}
                            className={mobile ? cn('textMobile') : cn('text')}
                        >
                            {mobile
                                ? 'В\u00A0Москве и\u00A0за\u00A0границей'
                                : ' \u2014\u00A0в\u00A0Москве и\u00A0за\u00A0границей'}
                        </Typography.Text>
                    </div>
                </div>

                <Typography.Text
                    view='primary-medium'
                    color={textColor}
                    className={mobile ? cn('textMobile') : cn('text')}
                >
                    Если номер не&nbsp;менялся, возможно, перегружен сервис отправки сообщений.
                    Попробуйте повторить действие через несколько минут.
                </Typography.Text>

                <Button
                    size={mobile ? 'xs' : 's'}
                    view='secondary'
                    onClick={onButtonClick}
                    className={cn('hintButton')}
                >
                    Вернуться к&nbsp;вводу кода
                </Button>
            </div>
            <ErrorCode shortTrace={true} className={cn('code')} text='Код:' color='tertiary' />
        </div>
    );
};

export default memo(SmsHint);
