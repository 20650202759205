import { ClientLog } from '#/src/lib/client-logger';
import { IAmPhoneRedirectDTO } from '#/src/types/interfaces';
import { Endpoint, HttpMethod } from '#/src/utils';

import factoryFetch from './fetch-factory';

export function create(contextRoot: string) {
    const makeEndpoint = (endpoint: Endpoint, method = HttpMethod.POST) =>
        factoryFetch(`${contextRoot}${endpoint}`, method);

    const fetch = {
        activateScanner: makeEndpoint(Endpoint.SCANNER_ACTIVATOR_ACTIVATE),
        retrieveAmPhoneRedirect: makeEndpoint(Endpoint.AM_PHONE_REDIRECT),
        updateAmPhoneRedirect: makeEndpoint(Endpoint.AM_PHONE_REDIRECT_UPDATE),
        sendClientLog: makeEndpoint(Endpoint.CLIENT_LOGGING_ENDPOINT),
    };

    return {
        fetchActivateScanner(code: string) {
            return fetch.activateScanner({ code });
        },
        retrieveAmPhoneRedirectUrl(device_uuid: string) {
            return fetch.retrieveAmPhoneRedirect({ device_uuid });
        },
        updateAmPhoneRedirectUrl({ device_uuid }: IAmPhoneRedirectDTO) {
            return fetch.updateAmPhoneRedirect({ device_uuid });
        },
        sendClientLog(clientLog: ClientLog) {
            return fetch.sendClientLog(clientLog);
        },
    } as const;
}

export type ClientApi = ReturnType<typeof create>;

/* eslint-disable import/no-mutable-exports */

export let fetchers: ClientApi;

/* eslint-enable import/no-mutable-exports */

// should be called before using fetchers
export function init(contextRoot = '') {
    fetchers = create(contextRoot);
}
