import React from 'react';

import darkMode from '@alfalab/core-components/themes/dark';

interface Props {
    theme: string;
}

export default function DarkThemeInjector({ theme }: Props) {
    if (theme === 'dark') {
        return (
            <style>
                {`${darkMode}
                    .icon_size_l.icon_name_close.icon_theme_alfa-on-white {
                    background-image: url('https://alfabank.servicecdn.ru/icons/icon_close_l_white.svg');
                }

                    .modal {
                    background-color: var(--color-light-bg-primary);
                }

                    .modal .modal__closer-wrapper {
                    background-color: var(--color-light-bg-primary);
                }

                    .heading, .paragraph, .label {
                    color: var(--color-light-text-primary)
                }`}
            </style>
        );
    }

    return null;
}
