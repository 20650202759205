import React, { ReactNode, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { NavigationBar } from '@alfalab/core-components/navigation-bar';

import { useAppSelector, useIsomorphicLayoutEffect } from '#/src/hooks';
import { Routes } from '#/src/models';
import { selectIsAKeyAvailable } from '#/src/store/redux/akey/selectors';
import { selectIsAccountAuthEnabled, selectIsMobile } from '#/src/store/redux/app/selectors';
import {
    selectCustomDesignBackUrl,
    selectCustomDesignClient,
    selectIsAlfaIdDesign,
} from '#/src/store/redux/custom-design/selectors';
import { selectSmsConfirmationScreen } from '#/src/store/redux/sms-verification/selectors';

import { AlternativeLoginButton } from './alternative-login-button';
import { BackButton } from './back-button';
import { BecomeClientButton } from './become-client-button';
import { ChangeUserButton } from './change-user-button';
import { Logo } from './logo';

import './header.css';

const cn = createCn('browser-header');

// eslint-disable-next-line
const ROUTE_REGEX = /\/[^\/]+$/;

export const Header: React.FC = () => {
    const [leftAddons, setLeftAddons] = useState<ReactNode | undefined>(<Logo />);
    const [rightAddons, setRightAddons] = useState<ReactNode | undefined>();
    const [title, setTitle] = useState<string | undefined>();

    const navigate = useNavigate();
    const { pathname, search, state: locationState } = useLocation();
    const isAccountAuthEnabled = useAppSelector(selectIsAccountAuthEnabled);
    const isAKeyAvailable = useAppSelector(selectIsAKeyAvailable);
    const isMobile = useAppSelector(selectIsMobile);
    const smsConfirmationScreen = useAppSelector(selectSmsConfirmationScreen);
    const customDesignClient = useAppSelector(selectCustomDesignClient);
    const customDesignBackUrl = useAppSelector(selectCustomDesignBackUrl);
    const isAlfaIdDesign = useAppSelector(selectIsAlfaIdDesign);

    useIsomorphicLayoutEffect(() => {
        let left: ReactNode | undefined = <Logo />;
        let right: ReactNode | undefined;
        let title: string | undefined;

        const route = pathname.match(ROUTE_REGEX)?.[0];

        switch (route) {
            case Routes.AKEY:
            case Routes.WEBAUTHN: {
                if (customDesignClient) {
                    left = (
                        <BackButton onClick={() => window.location.replace(customDesignBackUrl)} />
                    );
                    right = <Logo />;
                } else {
                    left = undefined;
                    right = <ChangeUserButton />;
                }
                break;
            }
            case Routes.PINCODE: {
                if (isMobile && customDesignClient) {
                    left = (
                        <BackButton onClick={() => window.location.replace(customDesignBackUrl)} />
                    );
                    right = <Logo />;
                } else if (!isAlfaIdDesign) {
                    right = <ChangeUserButton />;
                }
                break;
            }
            case Routes.PHONE_AUTH:
            case Routes.LOGIN: {
                const asSecondaryPage = locationState?.asSecondaryPage;

                if (customDesignClient && isMobile) {
                    left = (
                        <BackButton onClick={() => window.location.replace(customDesignBackUrl)} />
                    );
                    right = <Logo />;
                } else if (asSecondaryPage) {
                    const backUrl = isAKeyAvailable ? Routes.AKEY : Routes.WEBAUTHN;

                    left = (
                        <BackButton
                            onClick={() => {
                                navigate({
                                    pathname: backUrl,
                                    search,
                                });
                            }}
                        />
                    );
                    title =
                        route === Routes.PHONE_AUTH
                            ? 'Введите номер телефона'
                            : 'Введите логин и пароль';
                } else if (!customDesignClient) {
                    right = <BecomeClientButton />;
                }
                break;
            }
            case Routes.CARD_ACCOUNT: {
                if (isMobile) {
                    left = <BackButton />;
                    title = isAccountAuthEnabled
                        ? 'Ещё нужен номер карты или счёта'
                        : 'Ещё нужен номер карты';
                    right = <AlternativeLoginButton />;
                }
                break;
            }
            case Routes.SMS: {
                if (isMobile) {
                    left = <BackButton />;
                    const isHintScreen = smsConfirmationScreen === 'HINT';

                    title = isHintScreen ? 'Не приходит сообщение?' : 'Введите код из сообщения';
                }
                break;
            }
            case Routes.TEMP_PASSWORD: {
                if (isMobile) {
                    left = <BackButton />;
                    title = 'Введите временный пароль';
                }
                break;
            }
            case Routes.PASSCODE: {
                if (isMobile) {
                    left = <BackButton />;
                    title = 'Введите секретный код';
                }
                break;
            }
        }

        setLeftAddons(left);
        setRightAddons(right);
        setTitle(title);
    }, [
        pathname,
        locationState,
        isMobile,
        smsConfirmationScreen,
        customDesignClient,
        isAlfaIdDesign,
    ]);

    if (isMobile) {
        return (
            <NavigationBar
                contentClassName={cn()}
                leftAddons={leftAddons}
                rightAddons={rightAddons}
                title={title}
                leftAddonsClassName={cn('left-addons')}
            />
        );
    }

    return (
        <header className={cn()}>
            {leftAddons}
            {rightAddons}
        </header>
    );
};
