import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';

import errorDictionary from '#/src/error-dictionary';
import { trackUserEvent } from '#/src/lib/analitycs';
import { checkPincodeParams } from '#/src/lib/check-pincode-pararms';
import { clientInfoLog } from '#/src/lib/client-logger';
import getCookie from '#/src/lib/get-cookie';
import { setBrowserSecret } from '#/src/lib/set-browser-secret';
import { initFP } from '#/src/lib/sinc-fingerprint';
import { getMobileSmsServerErrors } from '#/src/lib/validators';
import { CookiesName, LocalStorageName } from '#/src/models';
import { customFetchBaseQuery } from '#/src/store/base-query';
import {
    getQueryRedirectParams,
    selectClientId,
    selectMultiFactorResponseParams,
} from '#/src/store/redux/app/selectors';
import { selectFingerPrintCredentials } from '#/src/store/redux/fingerprint/selectors';
import { getRegistrationType } from '#/src/store/redux/registration/selectors';
import { selectSmsIsPush } from '#/src/store/redux/sms-verification/selectors';
import {
    smsPushVerificationStatusSet,
    smsVerificationRequested,
    smsVerificationRequestRejected,
    smsVerificationStatusRequested,
} from '#/src/store/redux/sms-verification/slice';
import { ApplicationState } from '#/src/store/types';
import { FinishRegistrationCredentials, SmsCredentials } from '#/src/types/interfaces';
import { Endpoint, HttpMethod } from '#/src/utils';

import { registrationBySmsRequested } from '../../redux/app/slice';

export type SMSRequestType = {
    code: string;
    options?: { mobile?: boolean };
};

export const smsVerificationApi = createApi({
    reducerPath: 'smsVerificationApi',
    baseQuery: customFetchBaseQuery(),
    endpoints: (build) => ({
        requestReferenceBySms: build.mutation<any, string | void>({
            queryFn: async (code, queryApi, _extraOptions, fetchWithBQ) => {
                const state = queryApi.getState() as ApplicationState;
                const { dispatch } = queryApi;

                const browserId = getCookie(CookiesName.browserId);
                const queryRedirectParams = state?.App?.queryRedirectParams;
                const previousMultiFactorResponseParams = state?.App?.multiFactorResponseParams;
                const isPush = selectSmsIsPush(state);
                const type = state?.Registration?.type;

                const body: SmsCredentials = {
                    browser_id: browserId,
                    queryRedirectParams,
                    previousMultiFactorResponseParams,
                    isPush,
                    type,
                    code: code || '',
                };

                const result = await fetchWithBQ({
                    url: Endpoint.OID_REFERENCE,
                    method: HttpMethod.POST,
                    body,
                });

                if (result.error) {
                    const errorResult = result.error.data as any;

                    dispatch(smsVerificationRequestRejected(errorResult.errors));
                    if (isPush) {
                        dispatch(smsPushVerificationStatusSet(false));
                    }

                    return { error: result.error };
                }

                const resultData = result.data as any;

                dispatch(registrationBySmsRequested(resultData.reference));

                if (isPush) {
                    dispatch(smsPushVerificationStatusSet(false));
                }

                return { data: resultData };
            },
        }),
        requestOIDSmsVerification: build.mutation<any, SMSRequestType>({
            queryFn: async (
                payload = { code: '', options: {} },
                queryApi,
                _extraOptions,
                fetchWithBQ,
            ) => {
                const { dispatch } = queryApi;

                await initFP(queryApi);

                const state = queryApi.getState() as ApplicationState;
                const browserId = getCookie(CookiesName.browserId);
                const fingerprint = selectFingerPrintCredentials(state);
                const queryRedirectParams = getQueryRedirectParams(state);
                const previousMultiFactorResponseParams = selectMultiFactorResponseParams(state);
                const type = getRegistrationType(state);
                const isPush = selectSmsIsPush(state);

                dispatch(smsVerificationRequested());
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const newBrowserSecret = state?.Pincode?.newBrowserSecret;
                const newBrowserSecretEnc = state?.Pincode?.newBrowserSecretEnc;
                const browserSecret = window?.localStorage?.getItem(LocalStorageName.browserSecret);
                const browserSecretDate = window?.localStorage?.getItem(
                    LocalStorageName.browserSecretDate,
                );
                const clientId = selectClientId(state);

                const logMessage = checkPincodeParams(
                    browserId,
                    browserSecret,
                    browserSecretDate,
                    newBrowserSecretEnc,
                    clientId,
                );

                await clientInfoLog(logMessage);

                const body: FinishRegistrationCredentials = {
                    queryRedirectParams,
                    previousMultiFactorResponseParams,
                    isPush,
                    type,
                    ...fingerprint,
                    browser_id: browserId,
                    new_browser_secret_enc: newBrowserSecretEnc,
                    browser_secret_date: browserSecretDate,
                    code: payload.code,
                };

                const result = await fetchWithBQ({
                    url: Endpoint.OID_FINISH_CUSTOMER_REGISTRATION,
                    method: HttpMethod.POST,
                    body,
                });

                if (result.error) {
                    const errorResult = result.error.data as any;
                    const { errors } = errorResult;
                    const errorsCopy = payload.options?.mobile
                        ? getMobileSmsServerErrors(errors)
                        : [...errors];

                    trackUserEvent(
                        'Finish Customer Registration Request',
                        'Click',
                        'Finish Customer Error',
                        body?.queryRedirectParams?.client_id,
                    );

                    dispatch(smsVerificationRequestRejected(errorsCopy));
                    if (
                        errors.length &&
                        (errors[0].message === errorDictionary.ERRCUR1012 ||
                            errors[0].message === errorDictionary.ERRLGT1011 ||
                            errors[0].message === errorDictionary.ERRCUR1041)
                    ) {
                        dispatch(smsVerificationStatusRequested(false));
                    }

                    return { error: result.error as FetchBaseQueryError };
                }

                const resultData = result.data as any;

                if (Array.isArray(resultData.errors)) {
                    dispatch(smsVerificationRequestRejected(resultData.errors));
                } else if (resultData.redirectUrl) {
                    if (newBrowserSecretEnc) {
                        setBrowserSecret(newBrowserSecret);
                    }
                }

                return { data: resultData };
            },
        }),
    }),
});

export const { useRequestOIDSmsVerificationMutation, useRequestReferenceBySmsMutation } =
    smsVerificationApi;
