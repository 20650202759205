import React, { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import Button from 'arui-feather/button';
import Form from 'arui-feather/form';
import FormField from 'arui-feather/form-field';
import Paragraph from 'arui-feather/paragraph';
import Spin from 'arui-feather/spin';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { validatePhoneNumber } from '#/src/lib/client-validation/registration';
import {
    FORM_BUTTON_SIZE,
    FORM_FIELD_SIZE,
    FORM_PHONE_INPUT_SIZE,
    PHONE_INPUT_MAX_LENGTH,
    PHONE_INPUT_MIN_LENGTH_RU,
} from '#/src/lib/form-controls-const';
import { ButtonNames, FormStatus, Routes } from '#/src/models';
import { useRequestPhoneRegistrationMutation } from '#/src/store/api/registration-api';
import {
    getRegistrationAccount,
    getRegistrationCard,
    getRegistrationFormError,
    getRegistrationFormStatus,
    getRegistrationPhone,
    getRegistrationServerErrors,
    getRegistrationType,
} from '#/src/store/redux/registration/selectors';
import {
    phoneRegistrationSubmit,
    registrationErrorUpdated,
    registrationFormUpdated,
    serverErrorNotificationClosed,
} from '#/src/store/redux/registration/slice';

import InputCase from '../../ui/input-case';
import PhoneInput from '../../ui/phone-input';
import ServerErrorNotifications from '../../ui/server-errors-notification/server-errors-notification';

const PHONE_INPUT_MIN_LENGTH_RU_CHECKED = PHONE_INPUT_MIN_LENGTH_RU;

const cn = createCn('form-basic');

const PhoneLogin: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const [requestPhoneRegistration] = useRequestPhoneRegistrationMutation();

    const type = useAppSelector(getRegistrationType);
    const phone = useAppSelector(getRegistrationPhone);
    const formStatus = useAppSelector(getRegistrationFormStatus);
    const formError = useAppSelector(getRegistrationFormError);
    const serverErrors = useAppSelector(getRegistrationServerErrors);
    const card = useAppSelector(getRegistrationCard);
    const account = useAppSelector(getRegistrationAccount);

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess) {
            requestPhoneRegistration();
        }
    }, [formStatus, requestPhoneRegistration]);

    const handleSubmit = (event?: React.FormEvent<any>) => {
        event?.preventDefault();
        dispatch(
            phoneRegistrationSubmit({
                type,
                account,
                card,
                phone,
            }),
        );
    };

    const handlePhoneChange = (phoneValue?: string) => {
        dispatch(
            registrationErrorUpdated({
                phone: null,
            }),
        );
        dispatch(registrationFormUpdated({ phone: phoneValue }));
    };

    const handlePhoneBlur = () => {
        if (phone.length > 1 && phone.length < PHONE_INPUT_MIN_LENGTH_RU_CHECKED) {
            dispatch(registrationErrorUpdated({ phone: validatePhoneNumber(phone) }));
        }
    };

    const handleChangeCard = () => {
        navigate({
            pathname: Routes.CARD_ACCOUNT,
            search,
        });
    };

    const isSubmitButtonDisabled = () =>
        Boolean(
            phone.replace(/\s+/g, '').length < PHONE_INPUT_MIN_LENGTH_RU_CHECKED ||
                formError.phone ||
                formStatus === FormStatus.SubmitProcess,
        );

    return (
        <Form
            className={cn({ flex: 'space-between' })}
            onSubmit={handleSubmit}
            noValidate={true}
            id='phone-form'
        >
            <div className={cn('row')}>
                <ServerErrorNotifications
                    serverErrors={serverErrors}
                    onnotificationClosed={(index) => {
                        dispatch(serverErrorNotificationClosed(index));
                    }}
                />
                <Paragraph className={cn('phone-description')}>
                    Укажите номер телефона, который вы сообщали банку в последний раз
                </Paragraph>
                <FormField size={FORM_FIELD_SIZE} id='phone-form-field'>
                    <InputCase>
                        <PhoneInput
                            size={FORM_PHONE_INPUT_SIZE}
                            id='phoneNumberInput'
                            width='available'
                            phone={phone}
                            maxLength={PHONE_INPUT_MAX_LENGTH}
                            onChange={handlePhoneChange}
                            onBlur={handlePhoneBlur}
                            error={formError.phone}
                        />
                    </InputCase>
                </FormField>
            </div>
            <div className={cn('row')}>
                <div className={[cn('button-wrapper'), cn({ mb: '15' })].join(' ')}>
                    <Button
                        size={FORM_BUTTON_SIZE}
                        text={ButtonNames.changeAccount}
                        type='button'
                        onClick={handleChangeCard}
                        width='available'
                    />
                </div>
                <div className={cn('button-wrapper')}>
                    <Button
                        size={FORM_BUTTON_SIZE}
                        text={ButtonNames.continue}
                        disabled={isSubmitButtonDisabled()}
                        type='submit'
                        onClick={handleSubmit}
                        view='extra'
                        width='available'
                        icon={
                            <Spin
                                size={FORM_BUTTON_SIZE}
                                visible={formStatus === FormStatus.SubmitProcess}
                            />
                        }
                    />
                </div>
            </div>
        </Form>
    );
};

export default PhoneLogin;
