type NativeEventObjType = {
    status: string;
};

export const submitNativeEvent = (eventObj: NativeEventObjType) => {
    const messageParam = JSON.stringify(eventObj);

    // Log for local
    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.info('WebView Event:\n', eventObj);
    }

    // iOS message
    if (window && window?.webkit?.messageHandlers?.PassportHandler?.postMessage) {
        window.webkit.messageHandlers.PassportHandler.postMessage(messageParam);
    }

    // Android message
    if (window && window?.PassportHandler?.postMessage) {
        window.PassportHandler.postMessage(messageParam);
    }
};
