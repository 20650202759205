import React from 'react';
import { useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import Button from 'arui-feather/button';
import Heading from 'arui-feather/heading';

import { FORM_BUTTON_SIZE } from '#/src/lib/form-controls-const';

import './forgot-login.css';

const cn = createCn('forgot-login');

const ForgotLogin: React.FC = () => {
    const navigate = useNavigate();

    const handleBtnClick = (event?: React.MouseEvent<any, MouseEvent> | undefined) => {
        event?.preventDefault();
        navigate(-1);
    };

    return (
        <div className={cn('wrapper')}>
            <Heading size='l' className={cn('header')}>
                Восстановление логина
            </Heading>

            <p className={cn('text')}>
                Ваш логин указан в документе <br />
                «Подтверждение о присоединении к Правилам взаимодействия участников <br />
                системы «Альфа-Бизнес Онлайн».
                <br />
                <br />
                Вы можете восстановить ваш логин, позвонив в службу поддержки.
                <br />
                Пожалуйста, подготовьте паспортные данные и кодовое слово.
            </p>

            <div className={cn('buttonWrapper')}>
                <Button
                    size={FORM_BUTTON_SIZE}
                    onClick={handleBtnClick}
                    className={cn('btn')}
                    text='Вернуться назад'
                />
            </div>
        </div>
    );
};

export default ForgotLogin;
