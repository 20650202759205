import React, { FC, LegacyRef, useEffect, useRef } from 'react';

import Label from 'arui-feather/label';

import { useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { FORM_LABEL_SIZE } from '#/src/lib/form-controls-const';
import { AlfaDirect } from '#/src/models';
import { selectClientId } from '#/src/store/redux/app/selectors';
import {
    getAuthorizationLogin,
    getAuthorizationPassword,
} from '#/src/store/redux/authorization/selectors';

const DemoUserSignin: FC = () => {
    const login = useAppSelector(getAuthorizationLogin);
    const password = useAppSelector(getAuthorizationPassword);
    const clientId = useAppSelector(selectClientId);

    const demoUserSignInForm: LegacyRef<HTMLFormElement> = useRef(null);

    useEffect(() => {
        trackUserEvent('Demo User Page', 'Click', 'Auth finish success', clientId);
        demoUserSignInForm?.current?.submit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Label size={FORM_LABEL_SIZE}>Пожалуйста, дождитесь перехода.</Label>
            <form method='get' ref={demoUserSignInForm} action={AlfaDirect.redirectUrl}>
                <input name='login' type='hidden' value={login} />
                <input name='password' type='hidden' value={password} />
                <input name='auth_type' type='hidden' value='demo-user' />
            </form>
        </div>
    );
};

export default DemoUserSignin;
