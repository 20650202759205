import { type FC, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { RegistrationType } from '#/src/models';
import { useFastRegistrationRequestMutation } from '#/src/store/api/registration-api';
import { getQueryRedirectParams } from '#/src/store/redux/app/selectors';
import { registrationTypeUpdated } from '#/src/store/redux/registration/slice';

const FastRegistration: FC = () => {
    const dispatch = useAppDispatch();
    const [fastRegistrationRequest] = useFastRegistrationRequestMutation();
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);

    useEffect(() => {
        dispatch(registrationTypeUpdated(RegistrationType.FastAuth));
        fastRegistrationRequest({ queryRedirectParams });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default FastRegistration;
