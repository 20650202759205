export default function isSecuredPassword(password: string): boolean {
    let groupValue = 0;
    const groups = [/[A-Z]/, /[a-z]/, /[0-9]/, /[-:!.,_ @#$%^&]/];

    if (password.length > 2) {
        for (let i = 0; i < password.length - 2; i++) {
            if (
                password.charAt(i) === password.charAt(i + 1) &&
                password.charAt(i) === password.charAt(i + 2)
            ) {
                return false;
            }
        }
    }

    groups.forEach((item) => {
        if (item.test(password)) {
            groupValue += 1;
        }
    });

    if (groupValue < 3) {
        return false;
    }

    return true;
}
