import React from 'react';
import { createCn } from 'bem-react-classname';

import { Typography } from '@alfalab/core-components/typography';

import InvestmentsLoginPassword from '#/src/components/investments/investmens-authorization/investments-login-password';

const cn = createCn('investments');

const InvestmentsLightFlow: React.FC = () => (
    <div className={cn('container')}>
        <Typography.Title
            color='primary'
            tag='h2'
            font='system'
            view='small'
            weight='medium'
            defaultMargins={true}
            className={cn('light-title')}
        >
            Привет! Введите свои
            <br />
            данные, чтобы войти
        </Typography.Title>
        <InvestmentsLoginPassword hideRecover={true} />
    </div>
);

export default InvestmentsLightFlow;
