import React from 'react';
import { createCn } from 'bem-react-classname';

import { useAppSelector } from '#/src/hooks';
import { isGlobalPreloaderVisible } from '#/src/store/redux/app/selectors';

import { LoaderBackground } from './loader-background';
import { LoaderForeground } from './loader-foreground';

import './loader.css';

export interface CustomLoaderProps {
    children: React.ReactNode;
}

const cn = createCn('loader');

const CustomLoader: React.FC<CustomLoaderProps> = ({ children }) => {
    const visible = useAppSelector(isGlobalPreloaderVisible);

    return (
        <React.Fragment>
            <div className={cn('container', { visible })}>
                <span className={cn('logo')}>
                    <LoaderBackground className={cn('background')} />
                    <LoaderForeground className={cn('foreground')} />
                </span>
            </div>

            {children}
        </React.Fragment>
    );
};

export const AppLoader = CustomLoader;
