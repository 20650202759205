import { clientErrorLog } from '#/src/lib/client-logger';

export const encryptBrowserSecret = async (
    browserSecret: string,
    publicKey: string,
): Promise<string> => {
    try {
        const { JSEncrypt } = await import('jsencrypt');

        const publicKeyVal = `-----BEGIN PUBLIC KEY-----${publicKey}-----END PUBLIC KEY-----`;

        const jsEncrypt = new JSEncrypt({ default_key_size: '2048', log: true });

        jsEncrypt.setPublicKey(publicKeyVal);

        const browserSecretEncrypted = jsEncrypt.encrypt(browserSecret);

        if (browserSecretEncrypted) {
            return browserSecretEncrypted;
        }

        const error = new Error('Ошибка подписания browser secret');

        clientErrorLog(error);

        return '';
    } catch (err) {
        const error = new Error(`Ошибка шифрования browser secret: ${err}`)

        clientErrorLog(error);

        return '';
    }
};
