import React, { FC } from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Typography } from '@alfalab/core-components/typography';

import './hint.css';

export interface HintProps {
    mobile?: boolean;
    onClose?(): void;
};

const cn = createCn('component');

export const Hint: FC<HintProps> = ({ mobile, onClose }) => (
        <div className={ cn() }>
            <Typography.Title className={ cn('headerText') } color="primary" tag="h1" view="small">
                Не приходит сообщение ?
            </Typography.Title>
            <Typography.Text view="primary-medium" color="primary" className={ cn('hintText') }>
                Если у&nbsp;вас изменился номер телефона, позвоните нам или обратитесь в&nbsp;любое
                отделение банка.
            </Typography.Text>

            <div className={ cn('phonesWrap') }>
                <div className={ cn('phoneWrap') }>
                    <a href="tel:+78002000000">8 800 200-00-00</a>
                    <Typography.Text view="primary-medium" color="primary">
                        { mobile
                            ? 'Для\u00A0 звонков по\u00A0России'
                            : ' \u2014\u00A0для звонков по\u00A0России' }
                    </Typography.Text>
                </div>

                <div className={ cn('phoneWrap') }>
                    <a href="tel:+74957888878">+7 495 78-888-78</a>
                    <Typography.Text view="primary-medium" color="primary">
                        { mobile
                            ? 'В\u00A0Москве и\u00A0за\u00A0границей'
                            : ' \u2014\u00A0в\u00A0Москве и\u00A0за\u00A0границей' }
                    </Typography.Text>
                </div>
            </div>

            <Typography.Text view="primary-medium" color="primary" className={ cn('hintText') }>
                Если номер не&nbsp;менялся, возможно, перегружен сервис отправки сообщений.
                Попробуйте повторить действие через несколько минут.
            </Typography.Text>

            <Button size={ mobile ? 'xs' : 's' } view="secondary" block={ true } onClick={ onClose }>
                Назад
            </Button>
        </div>
    );
