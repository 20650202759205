import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { useLocation } from 'react-router';
import { createCn } from 'bem-react-classname';

import { MaskedInput } from '@alfalab/core-components/masked-input';
import { Typography } from '@alfalab/core-components/typography';
import Button from 'arui-feather/button';
import Form from 'arui-feather/form';
import FormField from 'arui-feather/form-field';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import {
    FORM_BUTTON_SIZE,
    PHONE_INPUT_MIN_LENGTH,
    PHONE_INPUT_MIN_LENGTH_RU,
} from '#/src/lib/form-controls-const';
import { clearFormatting, formatPhoneNumber } from '#/src/lib/formatters';
import getPathnameEndpoint from '#/src/lib/get-pathname-endpoint';
import { enterDemoOnIOS } from '#/src/lib/passport-mobile-bridge';
import { phoneNumberValidate } from '#/src/lib/validators';
import { DEMO_PHONE_NUMBER, FormStatus, RegistrationType } from '#/src/models';
import { CatMessage } from '#/src/shared/cat-message';
import { useRequestPhoneRegistrationMutation } from '#/src/store/api/registration-api';
import { getQueryRedirectParams, selectIsVkMessenger } from '#/src/store/redux/app/selectors';
import {
    getRegistrationFormStatus,
    getRegistrationPhone,
    getRegistrationServerErrors,
    phoneAuthErrorMessage,
} from '#/src/store/redux/registration/selectors';
import {
    phoneRegistrationSubmit,
    registrationFormUpdated,
    registrationServerErrorsCleared,
    registrationTypeUpdated,
} from '#/src/store/redux/registration/slice';

import './messenger-phone-auth.css';

const cn = createCn('messenger-form-basic');

// prettier-ignore
const maskPhoneNumber = [
    /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/,
];

const MessengerPhoneAuth: FC = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [requestPhoneRegistration] = useRequestPhoneRegistrationMutation();
    const phone = useAppSelector(getRegistrationPhone);
    const formStatus = useAppSelector(getRegistrationFormStatus);
    const serverErrors = useAppSelector(getRegistrationServerErrors);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const errorMessage = useAppSelector(phoneAuthErrorMessage);
    const isVkMessenger = useAppSelector(selectIsVkMessenger);

    const currentRoute = getPathnameEndpoint(location.pathname);
    const [firstKeyPush, setFirstKeyPush] = useState(true);
    const [isOpenKeyboard, setIsOpenKeyboard] = useState(false);
    
    useEffect(
        () => {
            const isPhoneValid = phoneNumberValidate(queryRedirectParams.phone, {
                withLength: true,
            });

            dispatch(registrationTypeUpdated(RegistrationType.Phone));
            if (isPhoneValid) {
                dispatch(
                    registrationFormUpdated({
                        phone: formatPhoneNumber(queryRedirectParams.phone),
                    }),
                );
                requestPhoneRegistration();
                trackUserEvent(
                    'Auth Page',
                    'Impression',
                    'Viewing Page',
                    queryRedirectParams.client_id,
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess) {
            requestPhoneRegistration();
        }

        if (errorMessage) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'Phone Send',
                queryRedirectParams.client_id,
                `Error: ${errorMessage}`,
            );
        }
    }, [
        currentRoute,
        errorMessage,
        formStatus,
        queryRedirectParams.client_id,
        requestPhoneRegistration,
    ]);

    const handleFocus = () => isIOS && setIsOpenKeyboard(true);

    const handleBlur = () => isIOS && setIsOpenKeyboard(false);

    const handleSubmit = (event: React.FormEvent<any> | undefined) => {
        event?.preventDefault();

        trackUserEvent(
            'Auth Page',
            'Click',
            'Phone Send',
            queryRedirectParams.client_id,
            'Sending',
        );

        if (phone === DEMO_PHONE_NUMBER) {
            enterDemoOnIOS();
        } else {
            dispatch(
                phoneRegistrationSubmit({
                    type: RegistrationType.Phone,
                    phone,
                }),
            );
        }
        if (formStatus === FormStatus.ValidationSuccess) {
            requestPhoneRegistration();
        }
    };

    const handlePhoneChange = (receivedPhone: string) => {
        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Enter Phone Number',
                queryRedirectParams.client_id,
            );
            setFirstKeyPush(true);
        }

        dispatch(registrationFormUpdated({ phone: `+7 ${receivedPhone.trimEnd()}` }));
        dispatch(registrationServerErrorsCleared());
    };

    const isSubmitButtonDisabled = () => {
        const phoneNumber = clearFormatting(phone);

        if (!phoneNumber) return true;

        const PHONE_INPUT_MIN_LENGTH_RU_CHECKED =
            phoneNumber.charAt(0) === '7' ? PHONE_INPUT_MIN_LENGTH_RU : PHONE_INPUT_MIN_LENGTH;

        return phoneNumber.length < PHONE_INPUT_MIN_LENGTH_RU_CHECKED || !!errorMessage;
    };

    return (
        <Form
            className={cn({
                flex: 'space-between',
                open: isOpenKeyboard && !isVkMessenger,
                cutScreen: isVkMessenger,
            })}
            onSubmit={handleSubmit}
        >
            <div className={cn('content')}>
                <CatMessage />
                <Typography.Title className={cn('title')} tag='h1' view='small' font='styrene'>
                    Введите ваш номер телефона для входа
                </Typography.Title>
                <FormField className={cn('phone-field-wrapper')}>
                    <div className={cn('custom-phone-input')}>
                        <div className={cn('phone-code')}>+7</div>
                        <MaskedInput
                            fieldClassName={cn('phone-field')}
                            inputClassName={cn('phone-input')}
                            className={cn('phone-masked-input')}
                            mask={maskPhoneNumber}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            value={phone.replace('+7', '')}
                            type='tel'
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handlePhoneChange(e.target.value)
                            }
                        />
                    </div>
                    {serverErrors.length > 0 && (
                        <div className={cn('error-block')}>{serverErrors[0].message}</div>
                    )}
                </FormField>
            </div>
            <Button
                size={FORM_BUTTON_SIZE}
                width='available'
                className={cn({
                    button: !isSubmitButtonDisabled(),
                    disabledButton: isSubmitButtonDisabled(),
                })}
                type='submit'
                view='extra'
                disabled={isSubmitButtonDisabled()}
            >
                Вперед
            </Button>
        </Form>
    );
};

export default MessengerPhoneAuth;
