import { RegistrationType } from '#/src/models';
import { ValidationResult } from '#/src/types/interfaces';

import { ACCOUNT_INPUT_MAX_LENGTH, CARD_INPUT_MAX_LENGTH } from './form-controls-const';

const checkRegistrationValueType = (value: string, isAccountAuthEnabled: boolean): ValidationResult<RegistrationType> => {
    if (!value || value.length <= CARD_INPUT_MAX_LENGTH) {
        return RegistrationType.Card;
    }
    if (isAccountAuthEnabled && value.length <= ACCOUNT_INPUT_MAX_LENGTH) {
        return RegistrationType.Account;
    }

    return null;
};

export default checkRegistrationValueType;
