import { useLocation } from 'react-router';

import { useAppDispatch, useAppSelector, useIsomorphicLayoutEffect } from '#/src/hooks';
import { checkAcrValues } from '#/src/lib/check-acr-values';
import {
    getQueryRedirectParams,
    selectIsCorporateClientId,
    selectIsWhiteListAdminClientId,
} from '#/src/store/redux/app/selectors';
import { acrValuesSet } from '#/src/store/redux/app/slice';

export const useAcrValues = () => {
    const dispatch = useAppDispatch();
    const {pathname} = useLocation();

    const isWhiteListAdminClientId = useAppSelector(selectIsWhiteListAdminClientId);
    const isCorporateClientId = useAppSelector(selectIsCorporateClientId);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);

    useIsomorphicLayoutEffect(()=> {
        const newAcrValues = checkAcrValues(
            pathname,
            queryRedirectParams,
            {isCorporateClientId, isWhiteListAdminClientId}
        );

        if (newAcrValues && newAcrValues !== queryRedirectParams.acr_values) {
            dispatch( acrValuesSet( newAcrValues ) );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
}
