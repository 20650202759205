import React from 'react';
import { createCn } from 'bem-react-classname';

import LogoIcon from '../logo-icon';

import './logo-header.css';

const cn = createCn('logo-header');

export interface LogoHeaderProps {
    children?: React.ReactNode;
}

const LogoHeader: React.FC<LogoHeaderProps> = ({ children }) => (
    <div className={cn()}>
        <LogoIcon />
        {children}
    </div>
);

export default LogoHeader;
