import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import Label from 'arui-feather/label';
import Link from 'arui-feather/link';

import { useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { FORM_LABEL_SIZE_S, FORM_SWITCHER_SIZE } from '#/src/lib/form-controls-const';
import { ButtonNames, ClientIds, reason, Routes } from '#/src/models';
import {
    getQueryRedirectParams,
    selectBecomeClientUrl,
    selectGosUslugiAuthorizationUrl,
    selectIsCorporateClientId,
    selectIsWhiteListAdminClientId,
    selectLoginRecoverLink,
    selectMultiFactorResponseParams,
    selectPasswordRecoverLink,
} from '#/src/store/redux/app/selectors';

import './info-links.css';

interface OwnProps {
    errorUpdate?: boolean;
    form: 'login' | 'password';
}

const cn = createCn('info-links');

const InfoLinks: FC<OwnProps> = ({ errorUpdate, form }) => {
    const navigate = useNavigate();
    const { search } = useLocation();

    const isCorporateClientId = useAppSelector(selectIsCorporateClientId);
    const isWhiteListAdmin = useAppSelector(selectIsWhiteListAdminClientId);
    const previousMultiFactorResponseParams = useAppSelector(selectMultiFactorResponseParams);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const investmentUrl = useAppSelector(selectGosUslugiAuthorizationUrl);
    const clickUrl = useAppSelector(selectBecomeClientUrl);
    const passwordRecoverLink = useAppSelector(selectPasswordRecoverLink);
    const loginRecoverLink = useAppSelector(selectLoginRecoverLink);

    const isPassword = form === 'password';

    const handleForgotLoginPasswordClick = () => {
        if (queryRedirectParams.client_id) {
            trackUserEvent(
                'Auth Page',
                'Click',
                isPassword ? 'Forgot Password' : 'Forgot Login',
                queryRedirectParams.client_id,
            );
        }
    };

    const handleAuthByCardOrAccount = (event?: React.MouseEvent) => {
        event?.preventDefault();
        navigate({
            pathname: Routes.CARD_ACCOUNT,
            search,
        });
    };

    const renderTextAfterLogin = () => {
        switch (form) {
            case 'password':
                return (
                    isWhiteListAdmin && (
                        <Label data-test-id='loginBottomDesc' size={FORM_LABEL_SIZE_S}>
                            Введите пароль от учетной записи сотрудника Банка
                        </Label>
                    )
                );
            default:
                return isWhiteListAdmin
                    ? [
                          <Label data-test-id='loginBottomDesc' size={FORM_LABEL_SIZE_S}>
                              Введите пароль от учетной записи сотрудника Банка
                          </Label>,
                      ]
                    : null;
        }
    };

    const renderLoginLink = () => {
        const { client_id: clientId } = queryRedirectParams;
        const data = [];

        switch (clientId) {
            case ClientIds.investments:
                data.push({ text: ButtonNames.gosUslugi, url: investmentUrl });
                break;
            case ClientIds.clickWeb:
                data.push(
                    {
                        onClick: handleAuthByCardOrAccount,
                        text: 'Получить логин',
                    },
                    { text: ButtonNames.becomeClient, url: clickUrl },
                );
                break;
            case ClientIds.whiteListAdmin:
            default:
                data.push({
                    onClick: handleAuthByCardOrAccount,
                    text: 'Войти по номеру карты или счёта',
                });
                break;
        }
        if (!isCorporateClientId) {
            return data.map((item) => (
                <Link className={cn('link')} size={FORM_SWITCHER_SIZE} {...item} />
            ));
        }

        return null;
    };

    return (
        <React.Fragment>
            {isCorporateClientId ? (
                <div className={cn('info')} key='0'>
                    {errorUpdate ? (
                        <Label size={FORM_LABEL_SIZE_S} className={cn('errorUpdate')}>
                            При обновлении логина или пароля произошла
                            <br />
                            ошибка, введите свой
                            {previousMultiFactorResponseParams.reason === reason.expired
                                ? ' старый '
                                : ' временный '}
                            пароль
                        </Label>
                    ) : (
                        <React.Fragment>{renderTextAfterLogin()}</React.Fragment>
                    )}
                </div>
            ) : null}
            <div className={cn('links')} key='1'>
                {renderLoginLink()}
            </div>
            <div className={cn('links')} key='2'>
                {isCorporateClientId && !isWhiteListAdmin && !errorUpdate && (
                    <Link
                        data-test-id='loginForgotBtn'
                        size={FORM_SWITCHER_SIZE}
                        text={isPassword ? 'Забыли пароль?' : 'Забыли логин?'}
                        url={isPassword ? passwordRecoverLink : loginRecoverLink}
                        onClick={handleForgotLoginPasswordClick}
                    />
                )}
            </div>
        </React.Fragment>
    );
};

export default InfoLinks;
