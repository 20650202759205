import React, { FC } from 'react';
import { createCn } from 'bem-react-classname';

import { useAppSelector } from '#/src/hooks';
import { ClientIds } from '#/src/models';
import { getQueryRedirectParams, selectGameUrl, selectIsGameEnabled } from '#/src/store/redux/app/selectors';

import './game-coin.css';

const cn = createCn('coin-game');

const CoinGame: FC = () => {
    const isGameEnabled = useAppSelector(selectIsGameEnabled);
    const gameUrl = useAppSelector(selectGameUrl);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);

    if (isGameEnabled && queryRedirectParams.client_id === ClientIds.newClickWeb) {
        return (
            <iframe
                className={cn('container')}
                src={gameUrl}
                title='game'
                width={350}
                height={320}
            />
        );
    }

    return null;
};

export default CoinGame;
