import React, { useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { GenericWrapper } from '@alfalab/core-components/generic-wrapper';
import { MaskedInput } from '@alfalab/core-components/masked-input';
import { Typography } from '@alfalab/core-components/typography';
import Form from 'arui-feather/form';

import ErrorDictionary from '#/src/error-dictionary';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { validateCardNumber } from '#/src/lib/client-validation/registration';
import { CARD_INPUT_MAX_LENGTH } from '#/src/lib/form-controls-const';
import { FormStatus, RegistrationType } from '#/src/models';
import { useRequestRegistrationMutation } from '#/src/store/api/registration-api';
import { getQueryRedirectParams, selectIsX5Web } from '#/src/store/redux/app/selectors';
import {
    getRegistrationAccount,
    getRegistrationCard,
    getRegistrationFormError,
    getRegistrationFormStatus,
    getRegistrationServerErrors,
    getRegistrationType,
    selectIsSubmitButtonDisabled,
    selectRegistrationErrorUpdate,
} from '#/src/store/redux/registration/selectors';
import {
    errorUpdateMessageDeleted,
    registrationErrorUpdated,
    registrationFormUpdated,
    registrationServerErrorsCleared,
    registrationSubmit,
    registrationTypeChanged,
    registrationTypeUpdated,
} from '#/src/store/redux/registration/slice';

import { ArrowBack, Barcode, X5Label } from './icons';

import './x5-web-card-account.css';

const cn = createCn('x5-web-card-account');

const cardMask = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];

const Index = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [requestRegistration] = useRequestRegistrationMutation();
    const type = useAppSelector(getRegistrationType);
    const serverErrors = useAppSelector(getRegistrationServerErrors);
    const formStatus = useAppSelector(getRegistrationFormStatus);
    const card = useAppSelector(getRegistrationCard);
    const account = useAppSelector(getRegistrationAccount);
    const formError = useAppSelector(getRegistrationFormError);
    const isSubmitButtonDisabled = useAppSelector(selectIsSubmitButtonDisabled);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const isX5Web = useAppSelector(selectIsX5Web);
    const errorUpdate = useAppSelector(selectRegistrationErrorUpdate);

    const [firstKeyPush, setFirstKeyPush] = useState(false);
    const [isOpenKeyboard, setIsOpenKeyboard] = useState(false);
    const errorMessage: string = formError?.card || serverErrors?.[0]?.message;

    useEffect(() => {
        dispatch(registrationTypeUpdated(type));
        trackUserEvent('Auth Page', 'Impression', 'Viewing Page', queryRedirectParams.client_id);
        if (errorUpdate) {
            dispatch(
                registrationErrorUpdated({
                    card: ErrorDictionary.GO_THROUGH_AGAIN,
                }),
            );
            dispatch(errorUpdateMessageDeleted());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess) {
            requestRegistration();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formStatus]);

    useEffect(() => {
        if (formError.card) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'Card Account Send',
                queryRedirectParams.client_id,
                `Card error: ${formError.card}`,
            );
        }
    }, [formError, queryRedirectParams]);

    useEffect(() => {
        if (serverErrors.length) {
            serverErrors.forEach((error) => {
                trackUserEvent(
                    'Auth Page',
                    'Click',
                    'Card Account Send',
                    queryRedirectParams.client_id,
                    `Server error: ${error.message}`,
                );
            });
        }
    }, [serverErrors, queryRedirectParams]);

    useEffect(() => {
        const trackMessage = 'Card number filled';
        const validationError = {
            card: '',
        };

        if (card.length === CARD_INPUT_MAX_LENGTH) {
            const validationResult = validateCardNumber(card);

            if (validationResult === null) {
                trackUserEvent(
                    'Auth Page',
                    'Field Change',
                    'Fill Card Account',
                    queryRedirectParams.client_id,
                    trackMessage,
                );
            } else {
                validationError.card = validationResult;
                dispatch(registrationErrorUpdated(validationError));
            }
        }

        return () => {
            dispatch(registrationServerErrorsCleared());
        };
    }, [account, card, type, queryRedirectParams, dispatch]);

    const handleOnClick = (e: React.FormEvent<any> | undefined) => {
        e?.preventDefault();
        if (!isSubmitButtonDisabled) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'Card Account Send',
                queryRedirectParams.client_id,
                'Send',
            );
            dispatch(
                registrationSubmit({
                    type,
                    account,
                    card,
                }),
            );
        }
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        { value }: { value: string },
    ) => {
        dispatch(registrationServerErrorsCleared());
        const trimmedValue = value.trim();

        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Enter Card Account',
                queryRedirectParams.client_id,
                '',
            );
            setFirstKeyPush(true);
        }

        if (trimmedValue.length <= CARD_INPUT_MAX_LENGTH) {
            dispatch(
                registrationFormUpdated({
                    card: trimmedValue,
                }),
            );

            if (type !== RegistrationType.Card) {
                trackUserEvent(
                    'Auth Page',
                    'Switch',
                    'Authentication Type',
                    queryRedirectParams.client_id,
                    'From Account To Card',
                );
                dispatch(registrationTypeChanged(RegistrationType.Card));
            }
        }

        dispatch(registrationErrorUpdated({ card: undefined, account: undefined }));
    };

    const handleFocus = () => isIOS && setIsOpenKeyboard(true);

    const handleBlur = () => isIOS && setIsOpenKeyboard(false);

    return (
        <div className={cn('container', { cutScreen: isX5Web, open: isOpenKeyboard && !isX5Web })}>
            <Form onSubmit={handleOnClick} noValidate={true} className={cn()}>
                <div>
                    <GenericWrapper alignItems='center' justifyContent='between'>
                        <ArrowBack onClick={() => navigate(-1)} />
                        <Typography.Text tag='div' view='primary-medium' weight='medium'>
                            Личный кабинет Х5 Карты
                        </Typography.Text>
                        <Gap direction='horizontal' size='m' />
                    </GenericWrapper>
                    <Gap size='2xl' />
                    <div className={cn('bank-card')}>
                        <div className={cn('magnetic-line')}>
                            <Typography.Text view='secondary-medium'>
                                {errorMessage}
                            </Typography.Text>
                        </div>
                        <div className={cn('number-block')}>
                            <Typography.Text tag='div' view='secondary-medium'>
                                Введите номер карты
                            </Typography.Text>

                            <MaskedInput
                                placeholder='5486 0000 0000 0000'
                                block={true}
                                className={cn('input', { error: !!errorMessage })}
                                fieldClassName={cn('field')}
                                focusedClassName={cn('field')}
                                inputMode='numeric'
                                pattern='[0-9 ]*'
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                mask={cardMask}
                                onChange={handleInputChange}
                                value={type === RegistrationType.Card ? card : account}
                            />
                        </div>
                        <div className={cn('icons')}>
                            <Barcode />
                            <X5Label />
                        </div>
                    </div>
                    <Gap size='l' />
                </div>
                <Button
                    className={cn('button')}
                    view='primary'
                    block={true}
                    disabled={isSubmitButtonDisabled}
                    onClick={handleOnClick}
                    loading={formStatus === FormStatus.SubmitProcess}
                >
                    Продолжить
                </Button>
                <Gap size='m' />
                <Typography.Text tag='div' view='primary-small'>
                    Номер отображен на обратной
                    <br />
                    стороне Х5 Карты, где магнитная полоса
                </Typography.Text>
            </Form>
        </div>
    );
};

export default Index;
