import React from 'react';

import DesignCheck from '#/src/components/ui/design-check';

import LoginBrowser from './login-browser';
import Login from './login-old';

export default () => (
        <DesignCheck
            defaultComponent={<LoginBrowser/>} // !isCorporateClientId
            defaultMobileComponent={<LoginBrowser/>}
            mobileComponent={<Login/>}
            corpComponent={<Login/>}
        />
    )
