import { FormStatus } from '#/src/models';

import { BiometryAuthorizationState } from './types';

export const BiometryAuthorizationInitialState: BiometryAuthorizationState = {
    attemptsLeft: 0,
    isBiometryAuth: false,
    errorTitle: '',
    errorSubtitle: '',
    photo: '',
    serverErrorId: '',
    formStatus: FormStatus.Initial,
};
