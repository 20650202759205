import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
    FetchArgs,
    FetchBaseQueryArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import uuid from 'uuid';

import getCookie from '#/src/lib/get-cookie';
import getQueryParams from '#/src/lib/get-query-params';
import initializeTraceId from '#/src/lib/initialize-trace-id';
import { redirectTo } from '#/src/lib/redirect';
import { setUserLocalInfo } from '#/src/lib/user-info';
import { ResponseWithData } from '#/src/store/base-query/types';
import { ApplicationState } from '#/src/store/types';
import { HEADERS } from '#/src/utils';

import { setMultifactorResponse } from '../redux/app/slice';

const { [HEADERS.TRACE_ID]: traceId } = initializeTraceId();

export const customFetchBaseQuery =
    (
        params?: FetchBaseQueryArgs & { customRedirect: boolean },
    ): BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        Record<string, unknown>,
        FetchBaseQueryMeta
    > =>
    async (...baseQueryFnParams) => {
        const [, api] = baseQueryFnParams;
        const state = api.getState() as ApplicationState;
        const { contextRoot } = state.Settings;

        const baseQueryFn = fetchBaseQuery({
            ...params,
            baseUrl: contextRoot,
            prepareHeaders: (headers) => {
                headers.set(HEADERS.X_CSRF_TOKEN, getCookie('alfa-csrf'));
                headers.set(HEADERS.X_REQUEST_ID, uuid.v4());
                headers.set(HEADERS.TRACE_ID, traceId);

                return headers;
            },
        });

        const response = await baseQueryFn(...baseQueryFnParams);

        const responseData = response.data as ResponseWithData['data'];

        if (responseData?.xUserInfo) {
            setUserLocalInfo(responseData?.xUserInfo);
        }

        if (responseData?.redirectUrl && !params?.customRedirect) {
            const params = getQueryParams(responseData.redirectUrl);

            api.dispatch(setMultifactorResponse({ url: responseData.redirectUrl, params }));
            redirectTo(responseData.redirectUrl);
        }

        return response;
    };
