import React, { useEffect, useState } from 'react';
import { createCn } from 'bem-react-classname';

import Button from 'arui-feather/button';
import CardInput from 'arui-feather/card-input';
import Form from 'arui-feather/form';
import FormField from 'arui-feather/form-field';
import IconScanCard from 'arui-feather/icon/action/photo-card';
import IconButton from 'arui-feather/icon-button';
import Input from 'arui-feather/input';
import Spin from 'arui-feather/spin';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import {
    validateAccountNumber,
    validateCardNumber,
} from '#/src/lib/client-validation/registration';
import {
    ACCOUNT_INPUT_MAX_LENGTH,
    CARD_INPUT_MAX_LENGTH,
    FORM_BUTTON_SIZE,
    FORM_FIELD_SIZE,
    FORM_INPUT_SIZE,
} from '#/src/lib/form-controls-const';
import { ButtonNames, FormStatus, RegistrationType } from '#/src/models';
import {
    useRequestRegistrationMutation,
    useScanCardMutation,
} from '#/src/store/api/registration-api';
import {
    getQueryRedirectParams,
    selectIsAlfaDirectClientId,
    selectIsAppVersionHasScanner,
} from '#/src/store/redux/app/selectors';
import {
    getRegistrationAccount,
    getRegistrationCard,
    getRegistrationFormError,
    getRegistrationFormStatus,
    getRegistrationServerErrors,
    getRegistrationType,
    selectIsSubmitButtonDisabled,
} from '#/src/store/redux/registration/selectors';
import {
    registrationErrorUpdated,
    registrationFormUpdated,
    registrationSubmit,
    registrationTypeChanged,
    registrationTypeUpdated,
    serverErrorNotificationClosed,
} from '#/src/store/redux/registration/slice';
import addAttributes from '#/src/utils/decorators/add-attributes';

import AuthFactorSwitcher from '../../ui/auth-factor-switcher';
import InputCase from '../../ui/input-case';
import ServerErrorNotifications from '../../ui/server-errors-notification/server-errors-notification';

import '../../app/form-basic.css';

const ScanCardButton = addAttributes(IconButton, [
    { name: 'aria-label', value: ButtonNames.scanCard },
]);

const LoginButton = addAttributes(Button, [{ name: 'aria-label', value: ButtonNames.continue }]);

const cn = createCn('form-basic');

interface Props {
    errorMessage?: string;
}

const CardAccount: React.FC<Props> = ({ errorMessage }) => {
    const dispatch = useAppDispatch();
    const [requestRegistration] = useRequestRegistrationMutation();
    const [scanCard] = useScanCardMutation();
    const type = useAppSelector(getRegistrationType);
    const account = useAppSelector(getRegistrationAccount);
    const card = useAppSelector(getRegistrationCard);
    const formStatus = useAppSelector(getRegistrationFormStatus);
    const formError = useAppSelector(getRegistrationFormError);
    const serverErrors = useAppSelector(getRegistrationServerErrors);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const isAlfaDirectClientId = useAppSelector(selectIsAlfaDirectClientId);
    const isAppVersionHasScanner = useAppSelector(selectIsAppVersionHasScanner);
    const isSubmitButtonDisabled = useAppSelector(selectIsSubmitButtonDisabled);

    const [firstKeyPush, setFirstKeyPush] = useState(false);

    useEffect(() => {
        dispatch(registrationTypeUpdated(type));
        trackUserEvent('Auth Page', 'Impression', 'Viewing Page', queryRedirectParams.client_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess) {
            requestRegistration();
        }
    }, [formStatus, requestRegistration]);

    useEffect(() => {
        if (errorMessage) {
            trackUserEvent('Auth Page', 'Click', 'Card Account Send', `Error: ${errorMessage}`);
        }
    }, [errorMessage]);

    const handleCardValidate = (cardValue: string) => {
        if (cardValue.length === CARD_INPUT_MAX_LENGTH) {
            const cardValidation = validateCardNumber(cardValue);

            if (cardValidation === null) {
                trackUserEvent(
                    'Auth Page',
                    'Field Change',
                    'Fill Card Account',
                    queryRedirectParams.client_id,
                    'Card number filled',
                );
            }
            dispatch(registrationErrorUpdated({ card: cardValidation }));
        } else if (formError.card) {
            dispatch(registrationErrorUpdated({ card: null }));
        }
    };
    const handleAccountValidate = (accountValue: string) => {
        if (accountValue.length === ACCOUNT_INPUT_MAX_LENGTH) {
            const accountValidation = validateAccountNumber(accountValue);

            if (accountValidation === null) {
                trackUserEvent(
                    'Auth Page',
                    'Field Change',
                    'Fill Card Account',
                    queryRedirectParams.client_id,
                    'Account filled',
                );
            } else {
                dispatch(registrationErrorUpdated({ account: accountValidation }));
            }
        } else if (formError.account) {
            dispatch(registrationErrorUpdated({ account: null }));
        }
    };

    useEffect(() => {
        handleCardValidate(card);
        handleAccountValidate(account);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [card, account]);

    const switchText = type === RegistrationType.Card ? 'счёта' : 'карты';

    const SwitchButton = addAttributes(Button, [
        { name: 'aria-label', value: `Использовать номер ${switchText}` },
    ]);

    const handleSubmit = (event: React.FormEvent | undefined) => {
        event?.preventDefault();
        trackUserEvent(
            'Auth Page',
            'Click',
            'Card Account Send',
            queryRedirectParams.client_id,
            'Send',
        );
        dispatch(
            registrationSubmit({
                type,
                account,
                card,
            }),
        );
    };

    const handleCardScanner = (event?: React.MouseEvent) => {
        event?.preventDefault();
        scanCard();
    };

    const handleRegistrationTypeChange = (event?: React.MouseEvent) => {
        event?.preventDefault();
        trackUserEvent(
            'Auth Page',
            'Switch',
            'Authentication Type',
            queryRedirectParams.client_id,
            type === RegistrationType.Card ? 'From Card To Account' : 'From Account To Card',
        );
        dispatch(
            registrationTypeChanged(
                type === RegistrationType.Card ? RegistrationType.Account : RegistrationType.Card,
            ),
        );
    };

    const handleCardChange = (cardValue?: string) => {
        dispatch(registrationFormUpdated({ card: cardValue?.trim() }));

        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Enter Card Account',
                queryRedirectParams.client_id,
            );
            setFirstKeyPush(true);
        }
    };

    const handleAccountChange = (accountValue?: string) => {
        dispatch(registrationFormUpdated({ account: accountValue?.trim() }));

        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Enter Card Account',
                queryRedirectParams.client_id,
            );
            setFirstKeyPush(true);
        }
    };
    const handlenotificationClosed = (index: number) => {
        dispatch(serverErrorNotificationClosed(index));
    };

    const handleCardBlur = () => {
        if (card.length > 0 && card.length < CARD_INPUT_MAX_LENGTH) {
            dispatch(registrationErrorUpdated({ card: validateCardNumber(card) }));
        }
    };

    const handleAccountBlur = () => {
        if (account.length > 0 && account.length < ACCOUNT_INPUT_MAX_LENGTH) {
            dispatch(registrationErrorUpdated({ account: validateAccountNumber(account) }));
        }
    };

    const renderRegistrationByCard = () => (
        <div className={cn('card')}>
            <FormField size={FORM_FIELD_SIZE}>
                <InputCase>
                    <CardInput
                        size={FORM_INPUT_SIZE}
                        id='creditCardNumber'
                        width='available'
                        value={card}
                        placeholder='0000 0000 0000 0000'
                        maxLength={CARD_INPUT_MAX_LENGTH}
                        label='Номер карты Альфа-Банка'
                        onChange={(cardValue) => handleCardChange(cardValue)}
                        onBlur={handleCardBlur}
                        error={formError.card}
                        resetError={false}
                        rightAddons={
                            isAppVersionHasScanner && (
                                <ScanCardButton
                                    text={ButtonNames.scanCard}
                                    id='scanCardButton'
                                    icon={<IconScanCard />}
                                    onClick={handleCardScanner}
                                />
                            )
                        }
                    />
                </InputCase>
            </FormField>
        </div>
    );

    const renderRegistrationByAccount = () => (
        <FormField size={FORM_FIELD_SIZE}>
            <InputCase>
                <Input
                    size={FORM_INPUT_SIZE}
                    id='accountInput'
                    type='tel'
                    width='available'
                    value={account}
                    onChange={(accountValue) => handleAccountChange(accountValue)}
                    onBlur={handleAccountBlur}
                    label='Номер счёта в Альфа-Банке'
                    mask='1111 1111 1111 1111 1111'
                    placeholder='0000 0000 0000 0000 0000'
                    error={formError.account}
                    resetError={false}
                />
            </InputCase>
        </FormField>
    );

    return (
        <Form onSubmit={handleSubmit} noValidate={true} className={cn({ flex: 'space-between' })}>
            <div className={cn('row')}>
                <ServerErrorNotifications
                    serverErrors={serverErrors}
                    onnotificationClosed={handlenotificationClosed}
                />
                {isAlfaDirectClientId ? <AuthFactorSwitcher form={type} /> : null}
                {type === RegistrationType.Card
                    ? renderRegistrationByCard()
                    : renderRegistrationByAccount()}
            </div>
            <div className={cn('row')}>
                {isAlfaDirectClientId ? null : (
                    <div className={[cn('button-wrapper'), cn({ mb: '15' })].join(' ')}>
                        <SwitchButton
                            size={FORM_BUTTON_SIZE}
                            area-label={`Использовать номер ${switchText}`}
                            text={`Использовать номер ${switchText}`}
                            type='button'
                            width='available'
                            onClick={handleRegistrationTypeChange}
                        />
                    </div>
                )}
                <div className={cn('button-wrapper')}>
                    <LoginButton
                        size={FORM_BUTTON_SIZE}
                        text={ButtonNames.continue}
                        disabled={isSubmitButtonDisabled}
                        type='submit'
                        onClick={handleSubmit}
                        view='extra'
                        width='available'
                        icon={
                            <Spin
                                size={FORM_BUTTON_SIZE}
                                visible={formStatus === FormStatus.SubmitProcess}
                            />
                        }
                    />
                </div>
            </div>
        </Form>
    );
};

export default CardAccount;
