import { FC, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { RegistrationType } from '#/src/models';
import { useFullNameRegistrationRequestMutation } from '#/src/store/api/registration-api';
import { getQueryRedirectParams } from '#/src/store/redux/app/selectors';
import { registrationTypeUpdated } from '#/src/store/redux/registration/slice';

const FullNameRegistration: FC = () => {
    const dispatch = useAppDispatch();
    const [fullNameRegistrationRequest] = useFullNameRegistrationRequestMutation();
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);

    useEffect(() => {
        if(queryRedirectParams.acr_values) {
            dispatch(registrationTypeUpdated(RegistrationType.Name));
            fullNameRegistrationRequest({ queryRedirectParams });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryRedirectParams]);

    return null;
};

export default FullNameRegistration;
