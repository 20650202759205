export function isString(arg: unknown): arg is string {
    return typeof arg === 'string';
}

export function isObject(arg: unknown): arg is Record<string, unknown> {
    return arg instanceof Object;
}

export function hasOwnProperty<O extends Record<string, unknown>, P extends PropertyKey>(
    obj: O,
    prop: P,
): obj is O & Record<P, unknown> {
    return Object.prototype.hasOwnProperty.call(obj, prop);
}
