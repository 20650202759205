import React from 'react';
import { useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Typography } from '@alfalab/core-components/typography';

import { ErrorLogo } from '#/src/components/ui/error-logo';
import { ButtonNames } from '#/src/models';

import { ErrorCode } from '../error-code';

import './error-alfa-online.css';

const cn = createCn('page-error-alfa-online');

export const ErrorAlfaOnline = () => {
    const navigate = useNavigate();

    return (
        <div className={cn()}>
            <div className={cn('content')}>
                <ErrorLogo />
                <Typography.Title tag='h1' className={cn('title')}>
                    Не получилось войти
                </Typography.Title>

                <p className={cn('description')}>
                    Попробуйте ещё раз сейчас или позже.
                    <br />
                    Если ошибка повторится, позвоните нам
                    <br />8 800 200 00 00
                </p>
                <ErrorCode className={cn('code')} color='tertiary' shortTrace={true} />
                <Button
                    className={cn('button')}
                    type='submit'
                    view='secondary'
                    block={true}
                    onClick={() => navigate(-1)}
                >
                    {ButtonNames.tryAgain}
                </Button>
            </div>
        </div>
    );
};
