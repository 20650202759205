import { clientInfoLog } from '#/src/lib/client-logger';
import getCookie from '#/src/lib/get-cookie';
import { isBrowserEnv } from '#/src/lib/is-browser-env';
import { CookiesName, LocalStorageName } from '#/src/models';

export const setBrowserSecret = (secret: string) => {
    if (isBrowserEnv) {
        window.localStorage.setItem(LocalStorageName.browserSecret, secret);
        window.localStorage.setItem(LocalStorageName.browserSecretDate, `${Date.now()}`);
        const browserId = getCookie(CookiesName.browserId)
            ? getCookie(CookiesName.browserId)
            : 'not found';

        const checkSecret = window.localStorage.getItem(LocalStorageName.browserSecret);

        if (checkSecret === secret) {
            clientInfoLog(`BrowserId: ${browserId} browserSecret set in localstorage`);
        } else {
            clientInfoLog(`BrowserId: ${browserId} browserSecret not set in localstorage`);
        }
    }
};
