import { createSelector } from 'reselect';

import {
    LOGIN_BUTTON_MIN_LENGTH_DISABLED,
    LOGIN_INPUT_MAX_LENGTH,
    PASSWORD_BUTTON_MIN_LENGTH_DISABLED,
} from '#/src/lib/form-controls-const';
import { FormStatus } from '#/src/models';
import { ApplicationState } from '#/src/store/types';

export const getAuthorizationState = (state: ApplicationState) => state.Authorization;

export const getAuthorizationLogin = createSelector(
    [getAuthorizationState],
    (authorization) => authorization.login,
);
export const getAuthorizationMaskedLogin = createSelector(
    [getAuthorizationState],
    (authorization) => authorization.maskedLogin,
);
export const getAuthorizationPassword = createSelector(
    [getAuthorizationState],
    (authorization) => authorization.password,
);
export const getAuthorizationPasswordType = createSelector(
    [getAuthorizationState],
    (authorization) => authorization.passwordType,
);
export const getAuthorizationFormError = createSelector(
    [getAuthorizationState],
    (authorization) => authorization.formError,
);
export const getAuthorizationFormStatus = createSelector(
    [getAuthorizationState],
    (authorization) => authorization.formStatus,
);
export const getAuthorizationServerErrors = createSelector(
    [getAuthorizationState],
    (authorization) => authorization.serverErrors,
);
export const selectAuthorizationAuthType = createSelector(
    [getAuthorizationState],
    (authorization) => authorization.type,
);
export const selectAuthorizationPcUserId = createSelector(
    [getAuthorizationState],
    (authorization) => authorization.pcUserId,
);
export const selectAuthorizationIsLimitsClick = createSelector(
    [getAuthorizationState],
    (authorization) => authorization.isLimitsClick,
);

export const selectIsSubmitButtonDisabled = createSelector(
    [
        getAuthorizationLogin,
        getAuthorizationPassword,
        getAuthorizationFormError,
        getAuthorizationFormStatus,
        getAuthorizationServerErrors,
    ],
    (login, password, formError, formStatus, serverErrors) =>
        login.length < LOGIN_BUTTON_MIN_LENGTH_DISABLED ||
        login.length > LOGIN_INPUT_MAX_LENGTH ||
        password.length < PASSWORD_BUTTON_MIN_LENGTH_DISABLED ||
        formStatus === FormStatus.SubmitProcess ||
        serverErrors.length !== 0 ||
        !!formError.login ||
        !!formError.password,
);
