import React from 'react';

import DesignCheck from '#/src/components/ui/design-check';

import Error from './error/error';
import { ErrorAlfaOnline } from './error-alfa-online';

export default () => (
    <DesignCheck
        defaultComponent={<ErrorAlfaOnline />}
        defaultMobileComponent={<ErrorAlfaOnline />}
        mobileComponent={<ErrorAlfaOnline />}
        corpComponent={<Error />}
    />
);
