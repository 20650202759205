import { Middleware } from 'redux';

const DEBOUNCE_TIME = 200;
let timer: NodeJS.Timeout;

const delayedActionMiddleware: Middleware = () => (next) => (action: any) => {
    if (action.type === 'app/preloaderHidden') {
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            next(action);
        }, DEBOUNCE_TIME);

        return;
    }

    if (action.type === 'app/preloaderShown') {
        if (timer) {
            clearTimeout(timer);
        }
    }
    // eslint-disable-next-line
    return next(action);
};

export default delayedActionMiddleware;
