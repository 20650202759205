type QueryParams = {
    [key: string]: string;
};
export type AppOptions = {
    iosAppVersionRedirect: string;
    iosAppIdRedirect: string;
};

export const isBrokenAmVersion = (queryParams: QueryParams, appOptions: AppOptions) => {
    const { device_app_version: deviceAppVersion, applicationId } = queryParams;

    return !!(
        applicationId === appOptions.iosAppIdRedirect &&
        deviceAppVersion === appOptions.iosAppVersionRedirect
    );
};
