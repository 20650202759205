import React, { FC, FormEvent, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import Form from 'arui-feather/form';
import FormField from 'arui-feather/form-field';
import IconSubmit from 'arui-feather/icon/ui/submit';
import IconButton from 'arui-feather/icon-button';
import Input from 'arui-feather/input';
import Spin from 'arui-feather/spin';

import BusinessLinks from '#/src/components/ui/business-links';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { validateLogin } from '#/src/lib/client-validation/authorization';
import {
    CORP_LOGIN_BUTTON_MIN_LENGTH_DISABLED,
    CORP_LOGIN_INPUT_MAX_LENGTH,
    FORM_BUTTON_SIZE,
    FORM_FIELD_SIZE,
    FORM_INPUT_SIZE,
    LOGIN_BUTTON_MIN_LENGTH_DISABLED,
    LOGIN_INPUT_MAX_LENGTH,
} from '#/src/lib/form-controls-const';
import { FormStatus, Routes, ValidationType } from '#/src/models';
import {
    getQueryRedirectParams,
    selectFirstVisitedLogin,
    selectIsCorporateClientId,
    selectMobileAppDownloadLink,
} from '#/src/store/redux/app/selectors';
import { keyboardLayoutChecked, pageFirstVisited } from '#/src/store/redux/app/slice';
import {
    getAuthorizationFormError,
    getAuthorizationFormStatus,
    getAuthorizationLogin,
    getAuthorizationMaskedLogin,
    getAuthorizationServerErrors,
} from '#/src/store/redux/authorization/selectors';
import {
    authorizationErrorUpdated,
    authorizationFormReset,
    authorizationFormUpdated,
    authorizationValidated,
    authServerErrorNotificationClosed,
} from '#/src/store/redux/authorization/slice';

import addAttributes from '../../../utils/decorators/add-attributes';
import InfoLinks from '../../ui/info-links';
import InputCase from '../../ui/input-case';
import ServerErrorNotifications from '../../ui/server-errors-notification/server-errors-notification';

import './login.css';

const AttributedIconButton = addAttributes(IconButton, [{ name: 'aria-label', value: 'Далее' }]);

const cn = createCn('form-basic');

const Login: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();

    const login = useAppSelector(getAuthorizationLogin);
    const maskedLogin = useAppSelector(getAuthorizationMaskedLogin);
    const formStatus = useAppSelector(getAuthorizationFormStatus);
    const error = useAppSelector(getAuthorizationFormError);
    const serverErrors = useAppSelector(getAuthorizationServerErrors);
    const isCorporateClientId = useAppSelector(selectIsCorporateClientId);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const firstVisitedStatus = useAppSelector(selectFirstVisitedLogin);
    const mobileAppDownloadLink = useAppSelector(selectMobileAppDownloadLink);

    const minLengthButtonDisabled = isCorporateClientId
        ? CORP_LOGIN_BUTTON_MIN_LENGTH_DISABLED
        : LOGIN_BUTTON_MIN_LENGTH_DISABLED;
    const maxLoginLength = isCorporateClientId
        ? CORP_LOGIN_INPUT_MAX_LENGTH
        : LOGIN_INPUT_MAX_LENGTH;
    const [firstKeyPush, setFirstKeyPush] = useState(false);
    const loginRef: React.Ref<typeof Input> = useRef(null);

    const handleValidateLogin = (value: string) => {
        const validationStatus = validateLogin(value, isCorporateClientId);

        if (validationStatus !== null) {
            dispatch(
                authorizationValidated({
                    validationStatus,
                    validationType: ValidationType.Login,
                }),
            );
        }
    };

    const handleChangeLogin = (value?: string) => {
        let loginValue = value || '';

        if (maskedLogin && maskedLogin !== value) {
            if (loginValue.length === maskedLogin.length + 1) {
                // typing
                dispatch(
                    authorizationFormUpdated({
                        maskedLogin: '',
                    }),
                );
                loginValue = loginValue.slice(-1);
            } else if (loginValue.length > maskedLogin.length + 5) {
                // pasting
                dispatch(
                    authorizationFormUpdated({
                        maskedLogin: '',
                    }),
                );
                loginValue = loginValue.slice(maskedLogin.length);
            } else {
                dispatch(
                    authorizationFormUpdated({
                        maskedLogin: '',
                    }),
                );
            }
        }

        dispatch(
            authorizationFormUpdated({
                login: loginValue,
            }),
        );

        if (error.login) {
            dispatch(
                authorizationErrorUpdated({
                    login: '',
                }),
            );
        }

        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Fill Username',
                queryRedirectParams.client_id,
            );
            setFirstKeyPush(true);
        }

        handleValidateLogin(loginValue);

        dispatch(keyboardLayoutChecked({ character: loginValue.slice(-1), input: 'login' }));
    };

    const handleKeyDown = (event?: React.KeyboardEvent) => {
        if (maskedLogin && (event?.keyCode === 8 || event?.keyCode === 43)) {
            dispatch(
                authorizationFormUpdated({
                    maskedLogin: '',
                    login: '',
                }),
            );
        }
    };

    const handleSubmit = (event?: FormEvent) => {
        event?.preventDefault();
        const validationStatus = validateLogin(login, isCorporateClientId);

        if (validationStatus !== null) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'Username Send',
                queryRedirectParams.client_id,
                'Error Username Validation',
            );
        }

        if (validationStatus === null) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'Username Send',
                queryRedirectParams.client_id,
                'Send',
            );

            navigate({
                pathname: Routes.PASSWORD,
                search,
            });
        }
    };

    const isButtonDisabled = () =>
        login.length < minLengthButtonDisabled ||
        login.length > maxLoginLength ||
        formStatus === FormStatus.SubmitProcess ||
        !!error.login;

    useEffect(() => {
        loginRef.current?.focus();
        if (!firstVisitedStatus) {
            dispatch(pageFirstVisited('login'));
            trackUserEvent(
                'Auth Page',
                'Impression',
                'Viewing Page',
                queryRedirectParams.client_id,
            );
        }

        return () => {
            dispatch(authorizationFormReset());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Form
                data-test-id='authBlock'
                className={cn()}
                noValidate={true}
                onSubmit={handleSubmit}
            >
                <ServerErrorNotifications
                    serverErrors={serverErrors}
                    onnotificationClosed={() => {
                        dispatch(authServerErrorNotificationClosed());
                    }}
                />
                <FormField size={FORM_FIELD_SIZE}>
                    <div className={cn('login-input')}>
                        <InputCase>
                            <Input
                                id='login-input'
                                data-test-id='loginField'
                                width='available'
                                error={error.login}
                                resetError={false}
                                name='username'
                                value={maskedLogin || login}
                                maxLength={maxLoginLength}
                                ref={loginRef}
                                label='Логин'
                                size={FORM_INPUT_SIZE}
                                rightAddons={
                                    <AttributedIconButton
                                        id='login-submit'
                                        data-test-id='loginSubmitBtn'
                                        disabled={isButtonDisabled()}
                                        icon={
                                            formStatus === FormStatus.SubmitProcess ? (
                                                <Spin size={FORM_BUTTON_SIZE} visible={true} />
                                            ) : (
                                                <IconSubmit size={FORM_BUTTON_SIZE} />
                                            )
                                        }
                                        onClick={handleSubmit}
                                    />
                                }
                                onChange={(value) => handleChangeLogin(value)}
                                onKeyDown={handleKeyDown}
                            />
                        </InputCase>
                    </div>
                </FormField>
                <div>
                    <InfoLinks form='login' />
                    {isCorporateClientId && (
                        <BusinessLinks mobileAppDownloadLink={mobileAppDownloadLink} />
                    )}
                </div>
            </Form>
        </div>
    );
};

export default Login;
