import React, { useEffect, useRef } from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Typography } from '@alfalab/core-components/typography';
import Form from 'arui-feather/form';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { ButtonNames, FormStatus, PasscodeType } from '#/src/models';
import { useRequestPasscodeAuthorizationMutation } from '#/src/store/api/passcode-api';
import { selectClientId, selectIsMobile } from '#/src/store/redux/app/selectors';
import {
    selectIsPasscodeButtonDisabled,
    selectPasscodeActiveType,
    selectPasscodeAttemptsLeft,
    selectPasscodeErrorPage,
    selectPasscodeFormStatus,
} from '#/src/store/redux/passcode';
import { passcodeUpdated } from '#/src/store/redux/passcode/slice';

import BackButton from '../../ui/back-button';
import StatusMessage from '../../ui/status-message';
import ActivePasscode from '../active-passcode';
import SelectTypePasscode from '../select-type-passcode';
import ToastError from '../toast-error';

import { ErrorScreenPasscodeAO } from './components/error-screen';

import './passcode-browser.css';

export const cn = createCn('passcode-browser');

const PasscodeBrowser = () => {
    const dispatch = useAppDispatch();

    const attemptsLeft = useAppSelector(selectPasscodeAttemptsLeft);
    const formStatus = useAppSelector(selectPasscodeFormStatus);
    const buttonDisabled = useAppSelector(selectIsPasscodeButtonDisabled);
    const goToErrorPage = useAppSelector(selectPasscodeErrorPage);
    const clientId = useAppSelector(selectClientId);
    const activeTypePasscode = useAppSelector(selectPasscodeActiveType);
    const isMobile = useAppSelector(selectIsMobile);

    const [requestPasscodeAuthorization] = useRequestPasscodeAuthorizationMutation();

    const containerRef = useRef(null);

    const classMap = {
        [PasscodeType.Alphanumeric]: 'numeric-container',
        [PasscodeType.Numeric]: 'numeric-container',
        [PasscodeType.GraphicPattern]: isMobile
            ? 'graphic-lock-container'
            : 'graphic-lock-container-desktop',
    };

    useEffect(() => {
        trackUserEvent('Auth Page', 'Impression', 'Viewing Page', clientId);

        return () => {
            dispatch(
                passcodeUpdated({
                    error: '',
                    showToastError: false,
                    passcode: '',
                    attemptsLeft: 2,
                    serverErrorId: '',
                }),
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = () => {
        requestPasscodeAuthorization();
    };

    if (!attemptsLeft) {
        if (goToErrorPage) {
            return <ErrorScreenPasscodeAO />;
        }

        return <StatusMessage />;
    }

    return (
        <div className={cn('container')}>
            <div
                className={cn(isMobile ? 'toast-сontainer' : 'toast-сontainer-desktop')}
                ref={containerRef}
            >
                <ToastError anchorElement={containerRef.current} />
            </div>
            {!isMobile ? (
                <div className={cn('back-button')}>
                    <BackButton />
                </div>
            ) : null}
            <div className={cn(isMobile ? 'title-wrapper-mobile' : '')}>
                {!isMobile ? (
                    <Typography.Title className={cn('title')} view='small' font='styrene' tag='div'>
                        Введите секретный код
                    </Typography.Title>
                ) : null}

                <Typography.Text view='primary-medium' tag='div'>
                    Вы придумали его и&nbsp;использовали для входа на&nbsp;этом или&nbsp;другом
                    устройстве
                </Typography.Text>
            </div>
            <Form
                className={cn('form')}
                noValidate={true}
                onSubmit={handleClick}
                autocomplete={false}
            >
                <div className={cn(classMap[activeTypePasscode])}>
                    <ActivePasscode />
                    <div
                        className={cn(
                            activeTypePasscode === PasscodeType.GraphicPattern
                                ? 'button-wrapper-patternlock'
                                : 'button_wrapper',
                        )}
                    >
                        <Button
                            type='submit'
                            block={true}
                            view='primary'
                            disabled={buttonDisabled}
                            loading={formStatus === FormStatus.SubmitProcess}
                        >
                            {ButtonNames.continue}
                        </Button>
                        <SelectTypePasscode />
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default PasscodeBrowser;
