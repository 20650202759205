export const getCanvasCropArea = (canvas: HTMLCanvasElement, width: number): ImageData | null => {
    if (!canvas) return null;

    const context = canvas.getContext('2d');

    const cropped =
        context?.getImageData(
            (canvas.width - width) / 2,
            (canvas.height - width / 2) / 2,
            width,
            width / 2,
        ) || null;

    return cropped;
};
