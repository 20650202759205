import React from 'react';

import DesignCheck from '#/src/components/ui/design-check';

import TempPasswordBrowser from './temp-password-browser';
import TempPasswordMobile from './temp-password-mobile';

const TempPassword = () => (
    <DesignCheck
        defaultComponent={<TempPasswordBrowser/>}
        defaultMobileComponent={<TempPasswordBrowser/>}
        mobileComponent={<TempPasswordMobile/>}
    />
);

export default TempPassword;
