import React from 'react';

import { List } from '@alfalab/core-components/list';
import { Typography } from '@alfalab/core-components/typography';

type Props = {
    camera: React.ReactElement;
    allow: React.ReactElement;
};

export const IosText: React.FC<Props> = ({ camera, allow }) => (
    <List tag='ol'>
        <Typography.Text>Перейдите в&nbsp;настройки телефона.</Typography.Text>
        <Typography.Text>Выберите название вашего браузера, например, Safari.</Typography.Text>
        <Typography.Text>Выберите пункт меню {camera}.</Typography.Text>
        <Typography.Text>Нажмите {allow}.</Typography.Text>
    </List>
);