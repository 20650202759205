export function luhn(value: string): boolean {
    const result = value
        .split('')
        .reverse()
        .map((item, index) => parseInt(item, 10) * ((index % 2) + 1))
        .join('')
        .split('')
        .map((item) => parseInt(item, 10))
        .reduce((a, b) => a + b, 0);

    return !(result % 10);
}
