import React, { useEffect, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { CameraAccess } from '../camera-access';
import { ScannerSpinner } from '../scanner-spinner';

import './index.css';

type Props = {
    ready: boolean;
    permissionDenied: boolean;
    result: boolean;
};

const CHANGE_FRAME_COLOR_DURATION = 1000;
const FIRST_ATTEMPT_DURATION = 6000;
const SECOND_ATTEMPT_DURATION = 12000;

const cn = createCn('camera-body');

export const CameraBody = React.forwardRef<HTMLCanvasElement, Props>(
    ({ ready, permissionDenied, result }, ref) => {
        const [frameClass, setFrameClass] = useState('');

        useEffect(() => {
            let firstAttempt: NodeJS.Timeout | undefined;
            let secondAttempt: NodeJS.Timeout | undefined;

            if (ready && !firstAttempt) {
                firstAttempt = setTimeout(() => {
                    if (!result) {
                        setFrameClass((` ${cn('failed')}`));
                        setTimeout(() => {
                            setFrameClass('');
                        }, CHANGE_FRAME_COLOR_DURATION);
                    }
                }, FIRST_ATTEMPT_DURATION);
            }

            if (ready && !secondAttempt) {
                secondAttempt = setTimeout(() => {
                    if (!result) {
                        setFrameClass((` ${cn('failed')}`));
                        setTimeout(() => {
                            setFrameClass('');
                        }, CHANGE_FRAME_COLOR_DURATION);
                    }
                }, SECOND_ATTEMPT_DURATION);
            }

            if (result) {
                setFrameClass(` ${cn('passed')}`);
                clearTimeout(firstAttempt);
                clearTimeout(secondAttempt);
            }

            return () => {
                clearTimeout(firstAttempt);
                clearTimeout(secondAttempt);
            };
        }, [ready, result]);

        return (
            <React.Fragment>
                {!ready && <ScannerSpinner schema={permissionDenied ? 'light' : 'dark'} />}
                {ready && permissionDenied && (
                    <CameraAccess className={cn('noAccess')} dataTestId='scanner-no-access' />
                )}
                <div
                    className={`${cn('videoCrop')} ${cn(
                        !ready || permissionDenied ? 'videoHidden' : '',
                    )}`}
                >
                    <canvas ref={ref} className={cn('video')} />
                    <div className={`${cn('frame')}${frameClass}`}>
                        <div className={`${cn('topLeft')}${frameClass}`} />
                        <div className={`${cn('topRight')}${frameClass}`} />
                        <div className={`${cn('bottomLeft')}${frameClass}`} />
                        <div className={`${cn('bottomRight')}${frameClass}`} />
                    </div>
                </div>
            </React.Fragment>
        );
    },
);
