// RSA/ECB/PKCS1Padding

export async function rsaEncrypt(code: string, serverPublicKey: string, nonce: string): Promise<string> {
    const crypto = await import('crypto');

    const publicKey = `-----BEGIN PUBLIC KEY-----${serverPublicKey}-----END PUBLIC KEY-----`;
    const buffer = Buffer.from(code + nonce, 'utf8');
    const encrypted = crypto.publicEncrypt(
        { key: publicKey, padding: crypto.constants.RSA_PKCS1_PADDING },
        buffer,
    );

    return encrypted.toString('base64');
}
