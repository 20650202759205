import React, { useState } from 'react';
import { createCn } from 'bem-react-classname';

import Page from 'arui-private/core/page';

import DarkThemeInjector from '#/src/components/ui/dark-theme-injector';
import { useAppSelector, useIsomorphicLayoutEffect } from '#/src/hooks';
import { getQueryRedirectParams } from '#/src/store/redux/app/selectors';

import './app-mobile.css';

interface Props {
    children?: React.ReactNode;
}

const cn = createCn('app');

const AppMobile: React.FC<Props> = ({ children }) => {
    const [theme, setTheme] = useState('');

    const queryRedirectParams = useAppSelector(getQueryRedirectParams);

    useIsomorphicLayoutEffect(() => {
        if (queryRedirectParams.theme === 'dark') {
            setTheme('dark');
        }
    }, [queryRedirectParams]);

    return (
        <Page className={cn('webview')} data-test-id="page">
            <div className="content">
                {theme && <DarkThemeInjector theme={theme} />}
                {children}
            </div>
        </Page>
    );
};

export default AppMobile;
