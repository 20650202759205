import React, { FC } from 'react';

import { Gap } from '@alfalab/core-components/gap';
import { GenericWrapper } from '@alfalab/core-components/generic-wrapper';
import { ModalDesktop } from '@alfalab/core-components/modal/desktop';
import { ModalMobileProps } from '@alfalab/core-components/modal/typings';

import { PhoneNumberSection } from '../phone-number-section';

export const ModalBankContacts: FC<ModalMobileProps & { title: string }> = ({
    title,
    open,
    onClose,
}) => (
    <ModalDesktop open={open} onClose={onClose}>
        <ModalDesktop.Header>{title}</ModalDesktop.Header>
        <ModalDesktop.Content>
            <GenericWrapper>
                <Gap size='l' direction='vertical' />
            </GenericWrapper>
            <PhoneNumberSection />
        </ModalDesktop.Content>
    </ModalDesktop>
);
