import errorDictionary from '../error-dictionary';

import { validateAuthorization } from './client-validation/authorization';
import { validateRegistration } from './client-validation/registration';
import { validateSmsVerification } from './client-validation/sms-verification';
import { PHONE_INPUT_MAX_LENGTH, PHONE_INPUT_MIN_LENGTH } from './form-controls-const';

interface ErrorDefault {
    title: string;
    detail: string;
    errorWith: unknown;
}

interface ErrorWithBase extends ErrorDefault {
    base_id: string;
    message: {
        en: string;
    };
}

interface ErrorCommon extends ErrorDefault {
    message: string;
}

type ErrorReceived = ErrorWithBase | ErrorCommon;

interface Error {
    errorWith?: unknown;
    isOpen: boolean;
    message: string;
}

export const validators = {
    validateAuthorization,
    validateRegistration,
    validateSmsVerification,
};

export function getServerError(error: ErrorReceived): Error {
    if ('base_id' in error) {
        return {
            message: error.message.en,
            isOpen: true,
        };
    }

    return {
        message: error.detail || error.title || error.message || '',
        isOpen: true,
        errorWith: error.errorWith,
    };
}

export function getClientError(error: ErrorReceived): { message: string } {
    if ('base_id' in error && error.base_id) {
        return {
            message: error.message.en,
        };
    }

    return {
        message: error.message as string,
    };
}

interface Options {
    withLength?: boolean;
}

export function phoneNumberValidate(phone: string, opts: Options = {}): boolean | null {
    if (!phone || typeof phone !== 'string') {
        return null;
    }
    const clearPhone = phone.replace(/(\s)*/g, '');
    const phoneLength = opts.withLength
        ? `{${PHONE_INPUT_MIN_LENGTH},${PHONE_INPUT_MAX_LENGTH}}`
        : '*';
    const phoneRegExp = new RegExp(`^[+]?[0-9]${phoneLength}$`);

    return phoneRegExp.test(clearPhone);
}

export function getMobileSmsServerErrors(errors: Error[] = []) {
    const errorsCopy = [...errors];
    const error = errors[0];

    if (
        error &&
        (error.message === errorDictionary.ERRCUR1041 ||
            error.message === errorDictionary['Too many attempts'] ||
            error.message === errorDictionary.ERRCUR1012)
    ) {
        return errorsCopy;
    }
    errorsCopy[0].message = errorDictionary.SMS_DEFAULT;

    return errorsCopy;
}
