import React from 'react';
import { createCn } from 'bem-react-classname';

import { Typography } from '@alfalab/core-components/typography';

import './assistant-dialog.css';

const cn = createCn('assistant-dialog');

interface Props {
    text: string;
    hideIcon?: boolean;
}

const assistantDialog: React.FC<Props> = ({ text, hideIcon }) => (
    <div>
        {!hideIcon && <div className={cn('avatar')} />}

        <Typography.TitleMobile
            view='small'
            font='system'
            color='primary'
            className={cn('text')}
            tag='h1'
        >
            {text}
        </Typography.TitleMobile>
    </div>
);

export default assistantDialog;
