import React from 'react';
import { createCn } from 'bem-react-classname';

import { CustomButton } from '@alfalab/core-components/custom-button';
import { Typography } from '@alfalab/core-components/typography';

import { useAppSelector } from '#/src/hooks';
import { selectBecomeClientUrl } from '#/src/store/redux/app/selectors';

import { RocketIcon } from './rocket-icon';

import './styles.css';

const cn = createCn('become-client-button');

export const BecomeClientButton = () => {
    const becomeClientUrl = useAppSelector(selectBecomeClientUrl);

    return (
        <CustomButton
            href={becomeClientUrl}
            dataTestId='header-button'
            shape='rounded'
            backgroundColor='var(--color-light-neutral-translucent-100)'
            contentColor='black'
            size={32}
            className={cn()}
            leftAddons={<RocketIcon />}
        >
            <Typography.Text tag='span' view='primary-small' weight='medium'>
                Стать клиентом
            </Typography.Text>
        </CustomButton>
    );
};
