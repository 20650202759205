import { createSlice } from '@reduxjs/toolkit';

import { getServerError, validators } from '#/src/lib/validators';
import { FormStatus } from '#/src/models';
import { SmsVerificationInitialState } from '#/src/store/redux/sms-verification/constants';

export const smsVerificationSlice = createSlice({
    name: 'sms-verification',
    initialState: SmsVerificationInitialState,
    reducers: {
        smsVerificationFormUpdated(state, action) {
            return { ...state, ...action.payload };
        },
        smsVerificationSubmit(state, action) {
            const formError = validators.validateSmsVerification.validateForm(action.payload);

            if (formError.code) {
                state.formStatus = FormStatus.SubmitError;
                state.formError = formError;
            } else {
                state.formStatus = FormStatus.ValidationSuccess;
                state.formError = { code: '' };
            }
        },
        smsVerificationRequested(state) {
            state.formStatus = FormStatus.SubmitProcess;
        },
        smsVerificationRequestRejected(state, action) {
            state.formStatus = FormStatus.SubmitError;
            state.serverErrors = Array.isArray(action.payload)
                ? action.payload.map((error) => getServerError(error))
                : [];
        },
        smsServerErrorNotificationClosed(state) {
            state.serverErrors = [];
        },
        smsVerificationStatusRequested(state, action) {
            state.requestStatus = action.payload;
        },
        smsVerificationServerErrorsReset(state) {
            state.serverErrors = [];
        },
        smsPushVerificationStatusSet(state, action) {
            state.isPush = action.payload;
        },
        smsConfirmationScreenUpdated(state, action) {
            state.confirmationScreen = action.payload;
        },
    },
});

export const {
    smsVerificationFormUpdated,
    smsVerificationSubmit,
    smsVerificationRequested,
    smsVerificationRequestRejected,
    smsServerErrorNotificationClosed,
    smsVerificationStatusRequested,
    smsVerificationServerErrorsReset,
    smsPushVerificationStatusSet,
    smsConfirmationScreenUpdated,
} = smsVerificationSlice.actions;

export default smsVerificationSlice.reducer;
