import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { Button } from '@alfalab/core-components/button';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { SystemMessage } from '@alfalab/core-components/system-message';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { LockClosedMIcon } from '@alfalab/icons-glyph/LockClosedMIcon';

import { BsBankContacts } from '#/src/components/ui/dialog-bank-contacts';
import StatusMessage from '#/src/components/ui/status-message';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { Routes, ServerErrors } from '#/src/models';
import { selectClientId } from '#/src/store/redux/app/selectors';
import { selectBiometryAttemptsLeft, selectBiometryErrorText } from '#/src/store/redux/biometry';
import { scanFaceModule } from '#/src/store/redux/biometry/fetchAuhorizeBiometry';

import { cn } from '..';

type ErrorPageProps = {
    iconBackgroundColor: string;
    icon: React.JSX.Element;
    buttons: React.JSX.Element;
    footer?: React.JSX.Element;
};

const ErrorPage = (props: ErrorPageProps) => {
    const { errorTitle, errorSubtitle } = useAppSelector(selectBiometryErrorText);
    const { iconBackgroundColor, icon, buttons, footer } = props;

    return (
        <React.Fragment>
            <SystemMessage className={cn('error-screen')}>
                <div className={cn('error-screen-main')}>
                    <SystemMessage.Graphic>
                        <SuperEllipse size={80} backgroundColor={iconBackgroundColor}>
                            {icon}
                        </SuperEllipse>
                    </SystemMessage.Graphic>
                    <SystemMessage.Title>{errorTitle}</SystemMessage.Title>
                    <SystemMessage.Subtitle>{errorSubtitle}</SystemMessage.Subtitle>
                </div>

                {buttons}
            </SystemMessage>
            {footer}
        </React.Fragment>
    );
};

const BiomErrorPage = ({ onClick }: { onClick: () => void }) => {
    const dispatch = useAppDispatch();

    const scanFace = () => {
        dispatch(scanFaceModule());
    };

    return (
        <ErrorPage
            iconBackgroundColor='var(--color-light-graphic-negative)'
            icon={<CrossMIcon color='var(--color-static-neutral-translucent-0)' />}
            buttons={
                <SystemMessage.Controls>
                    <Button block={true} view='primary' onClick={scanFace}>
                        Попробовать ещё раз
                    </Button>

                    <Button block={true} view='secondary' onClick={onClick}>
                        Войти другим способом
                    </Button>
                </SystemMessage.Controls>
            }
        />
    );
};

const BiomBlockedErrorPage = ({ onClick }: { onClick: () => void }) => {
    const [open, setOpen] = useState(false);

    return (
        <ErrorPage
            iconBackgroundColor=''
            icon={<LockClosedMIcon color='var(--color-light-bg-primary-inverted)' />}
            buttons={
                <SystemMessage.Controls>
                    <Button block={true} view='primary' onClick={onClick}>
                        Войти другим способом
                    </Button>

                    <Button block={true} view='secondary' onClick={() => setOpen(true)}>
                        Позвонить в банк
                    </Button>
                </SystemMessage.Controls>
            }
            footer={
                <BsBankContacts
                    title='Позвонить в банк'
                    open={open}
                    onClose={() => {
                        setOpen(false);
                    }}
                />
            }
        />
    );
};

export const ErrorScreenBiometry = ({ serverErrorId }: { serverErrorId: string }) => {
    const navigate = useNavigate();
    const { search } = useLocation();

    const clientId = useAppSelector(selectClientId);
    const attemptsLeft = useAppSelector(selectBiometryAttemptsLeft);

    useEffect(() => {
        trackUserEvent(
            'Auth Page',
            'Impression',
            'Viewing Page',
            clientId,
            'Auth Error Biometry Screen',
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToCardAccount = () => {
        navigate({
            pathname: Routes.CARD_ACCOUNT,
            search,
        });
    };

    if (serverErrorId === ServerErrors.PHOTO_VERIFICATION_IS_FAILED) {
        if (attemptsLeft === 0) return <BiomBlockedErrorPage onClick={goToCardAccount} />;

        return <BiomErrorPage onClick={goToCardAccount} />;
    }

    return <StatusMessage />;
};
