import { createBrowserHistory, createMemoryHistory, Path } from 'history';

const IS_BROWSER = typeof window !== 'undefined';

export const history = IS_BROWSER ? createBrowserHistory() : createMemoryHistory();

type HistoryFunc = typeof history.push | typeof history.replace;

function appendBaseName(
    contextRoot: string,
    to: Partial<Path> | string,
    state: Record<'pathname', string>,
    callback: HistoryFunc,
) {
    if (typeof to === 'string') {
        to = contextRoot + to;
    } else if (state !== undefined && state.pathname) {
        to.pathname = contextRoot + state.pathname;
    }
    // typeof to === 'object' && to.pathname работает с basename из Router

    return callback(to, state);
}

export const createBrowserHistoryWithBasename = (contextRoot: string) => {
    const { push, replace } = history;

    history.push = (to, state = undefined) => appendBaseName(contextRoot, to, state, push);
    history.replace = (to, state = undefined) => appendBaseName(contextRoot, to, state, replace);
};
