import { useCallback, useEffect, useRef, useState } from 'react';

import { useIsomorphicLayoutEffect } from '#/src/hooks';

type Props = {
    cameraLoading: boolean;
    workerLoading: boolean;
    permissionDenied: boolean;
    close: () => void;
    handleCameraInit: () => void;
    getFrame: () => ImageData | null;
    recognizerFrame: (data: ImageData | null) => void;
    setCardNumber: (number: string) => void;
    result: string | null;
};

// Время задержки перед следующей попыткой сканирования
const DELAY_AFTER_NON_RECOGNITION_CODE = 500;
const DELAY_BEFORE_CLOSE = 1000;
const SCAN_HINT_TEXT = 'Поместите номер карты в центр рамки для\xA0распознавания';
const SCAN_ERROR_TEXT = 'Не удалось отсканировать, пробуем ещё раз';

export const useScanner = ({
    close,
    cameraLoading,
    workerLoading,
    getFrame,
    result,
    recognizerFrame,
    handleCameraInit,
    setCardNumber,
    permissionDenied,
}: Props) => {
    const timeout = useRef<NodeJS.Timeout | null>(null);
    const startScanTime = useRef<number>();

    const ready = !workerLoading && !cameraLoading;
    const darkBackgroundMode = !permissionDenied;

    // const ready = false;
    // const darkBackgroundMode = false;

    const [showManualEnterButton, setShowManualEnterButton] = useState(false);

    useEffect(() => {
        if (ready && permissionDenied) {
            setShowManualEnterButton(true);
        }
        if (!ready || !permissionDenied) {
            setShowManualEnterButton(false);
        }
    }, [permissionDenied, ready]);

    const delayRecognizerFrame = useCallback(() => {
        timeout.current = setTimeout(() => {
            startScanTime.current = Date.now();
            recognizerFrame(getFrame());
        }, DELAY_AFTER_NON_RECOGNITION_CODE);
    }, [getFrame, recognizerFrame]);

    useEffect(
        () => () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        },
        [],
    );

    useIsomorphicLayoutEffect(() => {
        handleCameraInit();
    }, [handleCameraInit]);

    useEffect(() => {
        if (ready && !permissionDenied) {
            delayRecognizerFrame();
        }
    }, [delayRecognizerFrame, getFrame, permissionDenied, ready]);

    const [hint, setHint] = useState(SCAN_HINT_TEXT);

    useEffect(() => {
        let firstAttempt: NodeJS.Timeout | undefined;
        let secondAttempt: NodeJS.Timeout | undefined;

        if (ready && !firstAttempt) {
            firstAttempt = setTimeout(() => {
                if (!result) {
                    setHint(SCAN_ERROR_TEXT);
                }
            }, 6000);
        }

        if (ready && !secondAttempt) {
            secondAttempt = setTimeout(() => {
                if (!result) {
                    setShowManualEnterButton(true);
                }
            }, 12000);
        }

        if (result) {
            clearTimeout(firstAttempt);
            clearTimeout(secondAttempt);

            setTimeout(() => {
                close();
                setCardNumber(result);
            }, DELAY_BEFORE_CLOSE);
        }

        return () => {
            clearTimeout(firstAttempt);
            clearTimeout(secondAttempt);
        };
    }, [ready, result, close, setCardNumber]);

    return {
        ready,
        hint,
        showManualEnterButton,
        darkBackgroundMode,
    };
};
