import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import CheckBox from 'arui-feather/checkbox';

import { Routes } from '#/src/models';

import './offer.css';

const cn = createCn('offer');

const Offer: FC = () => {
    const navigate = useNavigate();
    const { search } = useLocation();

    const handleOnChange = (isChecked?: boolean) => {
        if (isChecked) {
            navigate({
                pathname: Routes.FULL_NAME_NOT_CLIENT,
                search,
            });
        }
    };

    return (
        <div className={cn()}>
            <div>
                <div className='row'>
                    <div className='column'>
                        <CheckBox text='Назад' type='button' size='m' onChange={handleOnChange} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Offer;
