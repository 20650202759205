import React, { useState } from 'react';
import { createCn } from 'bem-react-classname';

import { BottomSheet } from '@alfalab/core-components/bottom-sheet';
import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { BaseOption, OptionsList } from '@alfalab/core-components/select/shared';
import { Typography } from '@alfalab/core-components/typography';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { PasscodeType } from '#/src/models';
import { isNewUi, selectGetDeviceOSName, selectIsMobile } from '#/src/store/redux/app/selectors';
import {
    selectOptionsPasscodeTypes,
    selectPasscodeActiveType,
    selectShowSwitchTypePasscodeButton,
} from '#/src/store/redux/passcode';
import { activeTypePasscodeSet, passcodeUpdated } from '#/src/store/redux/passcode/slice';
import { PasscodeTypeValues } from '#/src/store/redux/passcode/types';

import './select-type-passcode.css';

const cn = createCn('select-type-passcode');

const SelectTypePasscode = () => {
    const dispatch = useAppDispatch();

    const isMobile = useAppSelector(selectIsMobile);
    const newUi = useAppSelector(isNewUi);
    const options = useAppSelector(selectOptionsPasscodeTypes);
    const showSwitchButton = useAppSelector(selectShowSwitchTypePasscodeButton);
    const activeTypePasscode = useAppSelector(selectPasscodeActiveType);
    const osName = useAppSelector(selectGetDeviceOSName);

    const [open, setOpen] = useState(false);

    const handleSwitchClick = () => {
        // Проверка для АО так как там 1 input для Numeric и AlphaNumeric

        if (isMobile) {
            setOpen(true);
        } else {
            dispatch(passcodeUpdated({ error: '', passcode: '', showToastError: false }));
            if (activeTypePasscode === PasscodeType.GraphicPattern) {
                dispatch(activeTypePasscodeSet(PasscodeType.Numeric));
            } else {
                dispatch(activeTypePasscodeSet(PasscodeType.GraphicPattern));
            }
        }
    };

    return (
        <React.Fragment>
            {showSwitchButton && (
                <Button
                    className={cn(isMobile && newUi ? 'btn-mobile' : 'btn')}
                    block={true}
                    view={isMobile && newUi ? 'secondary' : 'text'}
                    onMouseDown={handleSwitchClick}
                >
                    У меня другой тип кода
                </Button>
            )}
            <BottomSheet
                contentClassName={cn('bottom-sheet-content')}
                title={
                    <Typography.Title view='small' tag='div'>
                        Тип кода
                    </Typography.Title>
                }
                open={open}
                onClose={() => setOpen(false)}
                hasCloser={true}
            >
                <OptionsList
                    scrollbarClassName={cn('list')}
                    visibleOptions={3}
                    open={true}
                    Option={BaseOption}
                    options={options}
                    getOptionProps={(option, index) => ({
                        index,
                        className: cn('option'),
                        option,
                        innerProps: {
                            id: `${index}`,
                            onClick: () => {
                                dispatch(activeTypePasscodeSet(option.key as PasscodeTypeValues));
                                dispatch(
                                    passcodeUpdated({
                                        error: '',
                                        passcode: '',
                                        showToastError: false,
                                    }),
                                );
                                setOpen(false);
                            },
                        },
                        selected: activeTypePasscode === option.key,
                    })}
                    setSelectedItems={() => {}}
                    toggleMenu={() => {}}
                />
                {osName === 'iOS' && newUi && <Gap size={20} direction='vertical' />}
            </BottomSheet>
        </React.Fragment>
    );
};

export default SelectTypePasscode;
