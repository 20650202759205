import React, { FC, useEffect, useRef, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { PasswordInput } from '@alfalab/core-components/password-input';
import { PureCell } from '@alfalab/core-components/pure-cell';
import { Typography } from '@alfalab/core-components/typography';
import KeyMIcon from '@alfalab/icons-glyph/KeyMIcon';
import Form from 'arui-feather/form';

import { BsBankContacts } from '#/src/components/ui/dialog-bank-contacts';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { validationTempPasswordAlfaMobile } from '#/src/lib/client-validation/authorization';
import { TEMP_PASS_MAX_LENGTH } from '#/src/lib/form-controls-const';
import { ButtonNames, FormStatus } from '#/src/models';
import { useRequestTempPasswordAuthorizationMutation } from '#/src/store/api/temp-password-api';
import { selectClientId } from '#/src/store/redux/app/selectors';
import {
    selectIsTempPasswordError,
    selectTempPassword,
    selectTempPasswordAttemptsLeft,
    selectTempPasswordErrorPage,
    selectTempPasswordFormStatus,
} from '#/src/store/redux/temp-password';
import {
    tempPasswordErrorSet,
    tempPasswordSet,
    tempPasswordUpdated,
} from '#/src/store/redux/temp-password/slice';

import StatusMessage from '../../ui/status-message';

import { ErrorScreenTempPass } from './components/error-screen';

import './temp-password-mobile.css';

export const cn = createCn('temp-password-mobile');

const TempPasswordMobile: FC = () => {
    const dispatch = useAppDispatch();
    const [requestTempPasswordAuthorization] = useRequestTempPasswordAuthorizationMutation();
    const error = useAppSelector(selectIsTempPasswordError);
    const password = useAppSelector(selectTempPassword);
    const attemptsLeft = useAppSelector(selectTempPasswordAttemptsLeft);
    const formStatus = useAppSelector(selectTempPasswordFormStatus);
    const validServerError = useAppSelector(selectTempPasswordErrorPage);
    const clientId = useAppSelector(selectClientId);

    const [firstKeyPush, setFirstKeyPush] = useState(false);
    const [open, setOpen] = useState(false);
    const inputFocus = useRef<HTMLInputElement>(null);

    useEffect(
        () => {
            trackUserEvent('Auth Page', 'Impression', 'Viewing Page', clientId);

            return () => {
                dispatch(
                    tempPasswordUpdated({
                        error: '',
                        tempPassword: '',
                        attemptsLeft: 2,
                        serverErrorId: '',
                    }),
                );
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    const handleClick = () => {
        inputFocus?.current?.blur();

        const validation = validationTempPasswordAlfaMobile(password);

        if (validation === null) {
            requestTempPasswordAuthorization();
        } else {
            dispatch(tempPasswordErrorSet(validation));
        }
    };

    const handleChange = (inputValue: string) => {
        if (!firstKeyPush) {
            trackUserEvent('Auth Page', 'Field Change', 'Enter TempPassword', clientId);
            setFirstKeyPush(true);
        }

        dispatch(tempPasswordSet(inputValue));

        if (error) {
            dispatch(tempPasswordErrorSet(''));
        }
    };

    if (!attemptsLeft) {
        if (validServerError) {
            return <ErrorScreenTempPass />;
        }

        return <StatusMessage />;
    }

    return (
        <React.Fragment>
            <Form
                className={cn('container')}
                noValidate={true}
                onSubmit={handleClick}
                autocomplete={false}
            >
                <div>
                    <Typography.TitleMobile
                        className={cn('title')}
                        view='small'
                        tag='div'
                        color='primary'
                    >
                        Введите временный пароль
                    </Typography.TitleMobile>
                    <Typography.Text view='primary-medium' tag='p' color='primary'>
                        Чтобы получить пароль, позвоните в&nbsp;поддержку, обратитесь в&nbsp;офис
                        банка или&nbsp;спросите у&nbsp;сотрудника на&nbsp;встрече
                    </Typography.Text>

                    <PasswordInput
                        className={cn('input')}
                        autoComplete='one-time-code'
                        size='s'
                        placeholder='Пароль'
                        block={true}
                        maxLength={TEMP_PASS_MAX_LENGTH}
                        disabled={formStatus === FormStatus.SubmitProcess}
                        onChange={(e: any) => handleChange(e.target.value)}
                        value={password}
                        error={error}
                        ref={inputFocus}
                    />

                    <PureCell
                        type='button'
                        direction='horizontal'
                        verticalPadding='default'
                        onClick={() => setOpen(true)}
                    >
                        <PureCell.Graphics>
                            <SuperEllipse size={48} className={cn('icon')}>
                                <KeyMIcon color='var(--color-light-neutral-translucent-1300)' />
                            </SuperEllipse>
                        </PureCell.Graphics>
                        <PureCell.Content>
                            <PureCell.Main>
                                <PureCell.Text titleColor='primary' view='component-primary'>
                                    Получить временный пароль
                                </PureCell.Text>
                            </PureCell.Main>
                        </PureCell.Content>
                    </PureCell>
                </div>

                <Button
                    block={true}
                    view='primary'
                    onClick={handleClick}
                    disabled={!!error}
                    loading={formStatus === FormStatus.SubmitProcess}
                >
                    {ButtonNames.continue}
                </Button>
            </Form>
            <BsBankContacts
                title='Получить временный пароль'
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            />
        </React.Fragment>
    );
};

export default TempPasswordMobile;
