import React, { useState } from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Typography } from '@alfalab/core-components/typography';
import { CrossMWhiteIcon } from '@alfalab/icons-glyph/CrossMWhiteIcon';
import { MobilePhoneMIcon } from '@alfalab/icons-glyph/MobilePhoneMIcon';

import UpdateBannerImage from './update-banner-image';

import './update-banner.css';

type Props = {
    url: string;
    className?: string;
};

const cn = createCn('update-banner');

const UpdateBanner = ({ url, className = '' }: Props) => {
    const [visibility, setVisibility] = useState(true);
    const onClose = () => setVisibility(false);

    return visibility ? (
        <div className={`${cn()} ${className}`}>
            <div className={cn('background-image')}>
                <UpdateBannerImage />
            </div>
            <Button view='text' className={cn('close')} onClick={onClose}>
                <CrossMWhiteIcon />
            </Button>
            <div className={cn('content')}>
                <Button
                    className={cn('button')}
                    view='transparent'
                    href={url}
                    target='_blank'
                    colors='inverted'
                    leftAddons={<MobilePhoneMIcon width='18px' color='#fff' />}
                    size='xs'
                >
                    Установить
                </Button>
                <Typography.Text weight='bold' view='primary-small' className={cn('title')}>
                    Установите приложение
                </Typography.Text>
                <Typography.Text view='secondary-large'>
                    Новая версия Альфа-Бизнес
                    <br />
                    уже доступна.
                </Typography.Text>
            </div>
        </div>
    ) : null;
};

export default UpdateBanner;
