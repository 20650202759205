import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { cameraInit, cancelAnimation } from '../utils/camera-helpers';
import { getCanvasCropArea } from '../utils/canvas-helpers';

type Props = {
    canvasRef: RefObject<HTMLCanvasElement>;
};

type UseScannerReturn = {
    loading: boolean;
    permissionDenied: boolean;
    getFrame: () => ImageData|null;
    handleCameraInit: () => void;
};

const CROP_AREA_SIZE = 344;

export const useCamera = ({ canvasRef }: Props): UseScannerReturn => {
    const [loading, setLoading] = useState(false);
    const [permissionDenied, setPermissionDenied] = useState(true);

    const streamRef = useRef<MediaStream | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    const handleInitStream = useCallback(async (): Promise<MediaStream | null> => {
        if (streamRef.current) {
            return streamRef.current;
        }

        setLoading(true);

        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: {
                        ideal: 'environment',
                    },
                },
            });

            setPermissionDenied(false);
            streamRef.current = stream;

            return stream;
        } catch (e) {
            setPermissionDenied(true);

            return null;
        }
    }, []);

    const handleCameraInit = useCallback(async () => {
        const stream = await handleInitStream();

        if (stream) {
            await cameraInit(stream, canvasRef, videoRef);
        }

        setLoading(false);
    }, [canvasRef, handleInitStream]);

    // TODO - проверить нужно ли это
    // window.onfocus = handleCameraInit;

    useEffect(() => {
        const video = document.createElement('video');

        video.setAttribute('playsinline', 'true');
        video.setAttribute('muted', 'true');

        videoRef.current = video;

        return () => {
            videoRef.current?.remove();
            const stream = streamRef.current;

            if (stream) {
                const tracks = stream.getTracks();

                tracks.forEach((track) => {
                    track.stop();
                });
            }
            cancelAnimation();
        };
    }, []);

    const getFrame = useCallback(
        () => getCanvasCropArea(canvasRef.current as HTMLCanvasElement, CROP_AREA_SIZE),
        [canvasRef],
    );

    return {
        loading,
        permissionDenied,
        getFrame,
        handleCameraInit,
    };
};
