import React from 'react';

import Notification from 'arui-feather/notification';

import './server-error-notification.css';

interface ServerError {
    message: string;
    isOpen: boolean;
}

interface Props {
    serverErrors: ServerError[];
    onnotificationClosed?(index: number): void;
}

const ServerErrorNotifications: React.FC<Props> = ({
    serverErrors,
    onnotificationClosed,
}) => {
    const handlenotificationClosed = (index: number) => {
        if (onnotificationClosed) {
            onnotificationClosed(index);
        }
    }

    const errors = serverErrors;

    if (errors && errors.length) {
        return (
            <Notification
                key="validate-notification"
                id='server-error-notification'
                visible={errors[0].isOpen}
                stickTo='right'
                status='error'
                // position={ [10, 10] }
                autoCloseDelay={30000}
                onCloseTimeout={() => {
                    handlenotificationClosed(0);
                }}
                onCloserClick={() => {
                    handlenotificationClosed(0);
                }}
            >
                {errors[0].message}
            </Notification>
        );
    }

    return null
}

export default ServerErrorNotifications;
