import React from 'react';

import { AlfaBankSignBoxMColorIcon } from '@alfalab/icons-logo/AlfaBankSignBoxMColorIcon';
import { AlfaIdEnMColorIcon } from '@alfalab/icons-logo/AlfaIdEnMColorIcon';
import { AlfaPay2024MColorIcon } from '@alfalab/icons-logo/AlfaPay2024MColorIcon';

import { useAppSelector } from '#/src/hooks';
import { selectIsMobile } from '#/src/store/redux/app/selectors';
import {
    selectCustomDesignClient,
    selectCustomDesignTitle,
} from '#/src/store/redux/custom-design/selectors';
import { BANK_URL } from '#/src/utils/constants';

export const Logo: React.FC = () => {
    const isMobile = useAppSelector(selectIsMobile);
    const customDesignTitle = useAppSelector(selectCustomDesignTitle);
    const customDesignClient = useAppSelector(selectCustomDesignClient);
    const isAlfaId = customDesignClient === 'alfaId';

    if (customDesignTitle) {
        if (isAlfaId) {
            return (
                <AlfaIdEnMColorIcon
                    data-test-id='alfa-id-logo'
                    {...(isMobile ? { width: '90', height: '36' } : { width: '75', height: '40' })}
                    style={{ marginRight: isMobile ? '12px' : '0' }}
                />
            );
        }

        return (
            <AlfaPay2024MColorIcon
                data-test-id='header-logo'
                {...(isMobile ? { width: '125', height: '30' } : { width: '150', height: '36' })}
                style={{ marginRight: isMobile ? '12px' : '0' }}
            />
        );
    }

    return (
        <a href={BANK_URL} data-test-id='header-logo' style={{ display: 'contents' }}>
            <AlfaBankSignBoxMColorIcon
                {...(isMobile ? { width: '40', height: '40' } : { width: '48', height: '48' })}
            />
        </a>
    );
};
