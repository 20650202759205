import React from 'react';

import DesignCheck from '#/src/components/ui/design-check';

import PasscodeBrowser from './passcode-browser';
import PasscodeMobile from './passcode-mobile';

const Passcode = () => (
    <DesignCheck
        defaultComponent={<PasscodeBrowser />}
        defaultMobileComponent={<PasscodeBrowser />}
        mobileComponent={<PasscodeMobile />}
    />
);

export default Passcode;
