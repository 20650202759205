import React from 'react';

import InvestmentsRegistration from '#/src/components/investments/investents-registration';
import InvestmentsAuthorization from '#/src/components/investments/investmens-authorization';
import InvestmentsLightFlow from '#/src/components/investments/investments-light-flow';
import InvestmentsPassword from '#/src/components/investments/investments-password';
import LoginPassword from '#/src/components/login-password';
import { useAppSelector } from '#/src/hooks';
import {
    getQueryRedirectParams,
    isInvestmentsNewDesign,
    isInvestmentsNewVersion,
    selectGetDeviceOSName,
} from '#/src/store/redux/app/selectors';

import './investments.css';

const Investments: React.FC = () => {
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const isInvestAppDesign = useAppSelector(isInvestmentsNewDesign);
    const isNewVersion = useAppSelector(isInvestmentsNewVersion);
    const deviceOS = useAppSelector(selectGetDeviceOSName);

    const checkIOSVersion = isInvestAppDesign && deviceOS === 'iOS' && isNewVersion;
    // Сделано костыльно так, как у Инвестов не на всех версиях Андроида передается device_os_version
    const checkAndroidVersion = isInvestAppDesign && deviceOS !== 'iOS';

    // Новое приложение инвестов
    if (checkIOSVersion || checkAndroidVersion) {
        if (queryRedirectParams.light_flow === 'true') {
            return <InvestmentsLightFlow />;
        }

        if (queryRedirectParams.registration === 'true') {
            return <InvestmentsRegistration />;
        }

        if (queryRedirectParams.login) {
            return <InvestmentsPassword />;
        }

        return <InvestmentsAuthorization />;
    }

    // Старое iOS приложение инвестов
    if (isInvestAppDesign && deviceOS === 'iOS' && !isNewVersion) {
        return <InvestmentsAuthorization />;
    }

    // Десктоп версия инвестов
    return <LoginPassword />;
};

export default Investments;
