import validationDictionary from '../validation-dictionary';

type ValidationErrors = Record<string, string | null> & {
    code: string;
};

function validateCode(code: string): string {
    const codeValue = code && code.trim();

    if (codeValue.length === 0) {
        return validationDictionary.REQUIRED;
    }

    return '';
}

function validateForm(form: Record<string, string>) {
    const error: ValidationErrors = {
        code: '',
    };

    error.code = validateCode(`${form.code}`);

    return error;
}

export const validateSmsVerification = {
    validateForm,
};
