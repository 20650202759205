import {
    GRAPHIC_PATTERN_MIN_LENGTH,
    PASSCODE_ALFANUMERIC_MIN_LENGTH,
    PINCODE_MIN_LENGTH,
} from '#/src/lib/form-controls-const';
import validationDictionary from '#/src/lib/validation-dictionary';
import { FormStatus, PasscodeType } from '#/src/models';
import { PasscodeAuthorizationState } from '#/src/store/redux/passcode/types';

export const PasscodeAuthorizationInitialState: PasscodeAuthorizationState = {
    attemptsLeft: 2,
    passcode: '',
    passcodeTypes: [],
    activeTypePasscode: PasscodeType.Numeric,
    asymmetricEncryptionAlgorithm: '',
    serverPublicKey: '',
    nonce: '',
    serverErrorId: '',
    formStatus: FormStatus.Initial,
    error: '',
    isAltAuthModalOpen: false,
    isPasscodeAuth: false,
    showToastError: false,
};

export const passcodeValidationErrors = {
    [PasscodeType.Numeric]: {
        minLength: PINCODE_MIN_LENGTH,
        validError: validationDictionary.PASSCODE_NUMERIC_LENGTH,
    },
    [PasscodeType.GraphicPattern]: {
        minLength: GRAPHIC_PATTERN_MIN_LENGTH,
        validError: validationDictionary.PASSCODE_GRAPHIC_PATTERN_LENGTH,
    },
    [PasscodeType.Alphanumeric]: {
        minLength: PASSCODE_ALFANUMERIC_MIN_LENGTH,
        validError: validationDictionary.PASSCODE_ALFANUMERIC_LENGTH,
    },
    AlphanumericNumeric: {
        minLength: PINCODE_MIN_LENGTH,
        validError: 'Должен состоять от 4 до 8 символов',
    },
};
