const ENVIRONMENT = process.env.NODE_ENV || '';

export const SettingsInitialState = {
    contextRoot: '',
    headerContacts: {
        capital: null,
        other: null,
    },
    env: ENVIRONMENT,
    chat: {
        MOCKS: '',
        ATTACHMENTS_PROXY: '',
        CONNECT: '',
        AUTH_SESSION: '',
        SEND: '',
        READ: '',
        TOPIC_MESSAGES: '',
        TOPIC_ERRORS: '',
    },
};
