import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getEventBus } from '@alfa-bank/newclick-core/utils/window-utils';
import { EventMap } from '@alfa-bank/newclick-types';
import type { EventBus as IEventBus } from '@alfa-bank/newclick-types/events-bus';

import { useAppSelector, useIsomorphicLayoutEffect } from '#/src/hooks';
import factoryFetch from '#/src/lib/fetch-factory';
import { getSettingsState } from '#/src/store/redux/setting/selectors';

import { ModalsContext, ModalsContextType, ModalType } from './modals-context';

type Props = {
    children: React.ReactNode;
};

const voipDeeplink = '/universal_support_call';

export const ModalsContextProvider: React.FC<Props> = ({ children }) => {
    const [currentModal, setCurrentModal] = useState<ModalType | null>(null);
    const [chatAuthSessionId, setChatSessionId] = useState<string>();
    const [eventBus, setEventBus] = useState<Readonly<IEventBus<EventMap>>>();

    const settings = useAppSelector(getSettingsState);

    const getSession = factoryFetch(settings.chat.AUTH_SESSION);


    const onModalOpen = useCallback((type: ModalType | null) => {
        setCurrentModal(type);
    }, []);

    const onSetChatSessionId = (session: string | undefined) => {
        setChatSessionId(session);
    };

    const openVoip = useCallback(() => {
        eventBus?.emit('CHANGE_SIDE_PANEL_STATE', {
            sidePanelId: 'Voip',
            state: 'open',
        });
    }, [eventBus]);

    const openDeeplink = useCallback(
        (arg?: string) => {
            if (arg === voipDeeplink) {
                openVoip();
            }
        },
        [openVoip],
    );

    const openChat = useCallback(() => {
        (window as any).handleDeepLink = openDeeplink;
        getSession()
            .then((data) => {
                onSetChatSessionId(data.sessionId);
            })
            .then(() => {
                onModalOpen('chat');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openDeeplink]);

    useIsomorphicLayoutEffect(() => {
        setEventBus(getEventBus());
    }, []);

    useEffect(() => {
        const handleChangeSidePanelState = ({
            sidePanelId,
        }: EventMap['CHANGE_SIDE_PANEL_STATE']) => {
            if (!sidePanelId) {
                return;
            }

            if (sidePanelId === 'chat') {
                openChat();

                return;
            }

            onModalOpen(sidePanelId as ModalType);
        };

        eventBus?.subscribe('CHANGE_SIDE_PANEL_STATE', handleChangeSidePanelState);

        return () => {
            eventBus?.unsubscribe('CHANGE_SIDE_PANEL_STATE', handleChangeSidePanelState);
        };
    }, [eventBus, onModalOpen, openChat]);

    const context: ModalsContextType = useMemo(
        () => ({
            currentModal,
            onModalOpen,
            chatAuthSessionId,
            onSetChatSessionId,
            openChat,
            openVoip,
        }),
        [chatAuthSessionId, currentModal, onModalOpen, openChat, openVoip],
    );

    return <ModalsContext.Provider value={context}>{children}</ModalsContext.Provider>;
};
