import { Middleware } from 'redux';
import request from 'superagent';

const crashReporter: Middleware = (store) => (next) => (action) => {
    try {
        return next(action);
    } catch (e: any) {
        if (typeof window !== 'undefined') {
            request
                .post(`${store.getState().Settings.contextRoot}/api/logError`)
                .send({
                    message: e.message,
                    lineNumber: e.lineNumber,
                    columnNumber: e.columnNumber,
                    stack: e.stack,
                })
                .end();
        }

        // eslint-disable-next-line no-console
        console.error('Caught an exception!', e);
        throw e;
    }
};

export default crashReporter;
