export const activateDevSession = async (activationCode: string) => {
    const response = await fetch('https://web-test.moscow.alfaintra.net/proxy/qr-reader-api/activation', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'AUTH-USER-ID': 'AYMKML',
        }, // text/plain requests don't trigger a CORS preflight.
        body: JSON.stringify({ code: activationCode }),
    });
    const { code } = await response.json();

    return code;
};