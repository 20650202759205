import { createSlice } from '@reduxjs/toolkit';

import { SettingsInitialState } from '#/src/store/redux/setting/constants';

export const SettingsSlice = createSlice({
    name: 'settings',
    initialState: SettingsInitialState,
    reducers: {}
})

export default SettingsSlice.reducer;
