import React from 'react';

import DesignCheck from '#/src/components/ui/design-check';

import RecoveryBrowser from './recovery-browser';
import Recovery from './recovery-old';

export default (props: any) => (
    <DesignCheck
        defaultComponent={<RecoveryBrowser {...props} />}
        defaultMobileComponent={<RecoveryBrowser {...props} />}
        mobileComponent={<Recovery {...props} />}
        corpComponent={<Recovery {...props} />}
    />
);
