import React, { FC } from 'react';
import { createCn } from 'bem-react-classname';

import { Toast, ToastProps } from '@alfalab/core-components/toast';
import { ToastMobile } from '@alfalab/core-components/toast/mobile';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { PASSCODE_TIME_CLEAR } from '#/src/lib/form-controls-const';
import { PasscodeType } from '#/src/models';
import { selectIsMobile } from '#/src/store/redux/app/selectors';
import {
    selectPasscodeActiveType,
    selectPasscodeError,
    selectPasscodeShowToastError,
} from '#/src/store/redux/passcode';
import { passcodeUpdated } from '#/src/store/redux/passcode/slice';

import './toast-error.css';

const cn = createCn('toast-error');

const ToastError: FC<Pick<ToastProps, 'anchorElement'>> = ({ anchorElement }) => {
    const dispatch = useAppDispatch();

    const error = useAppSelector(selectPasscodeError);
    const showToastError = useAppSelector(selectPasscodeShowToastError);
    const activeTypePasscode = useAppSelector(selectPasscodeActiveType);
    const isMobile = useAppSelector(selectIsMobile);

    const hasNotToastError =
        activeTypePasscode === PasscodeType.Alphanumeric ||
        (!isMobile && activeTypePasscode === PasscodeType.Numeric);

    const onClose = () => {
        dispatch(passcodeUpdated({ showToastError: false, passcode: '' }));
    };

    if (hasNotToastError) {
        return null;
    }
    const ToastComponent = isMobile ? ToastMobile : Toast;

    return (
        <ToastComponent
            dataTestId='toast-error'
            title={error}
            className={cn('toast')}
            titleClassName={cn(isMobile ? 'title' : '')}
            open={showToastError}
            block={true}
            useAnchorWidth={true}
            anchorElement={anchorElement}
            position='top'
            badge='negative-alert'
            onClose={onClose}
            autoCloseDelay={PASSCODE_TIME_CLEAR}
        />
    );
};

export default ToastError;
