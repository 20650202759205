import { SmsVerificationState } from '#/src/store/redux/sms-verification/types';

export const SmsVerificationInitialState: SmsVerificationState = {
    code: '',
    requestStatus: true,
    formStatus: '',
    formError: {
        code: '',
    },
    isPush: true,
    serverErrors: [],
    redirectUrl: '',
    redirectQueryParams: {},
    confirmationScreen: 'INITIAL',
}
