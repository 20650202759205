import { HEADERS } from '#/src/utils';

import { isBrowserEnv } from './is-browser-env';

type UserLocaInfo = {
    name: string;
};

export const getUserLocalInfo = (): UserLocaInfo => {
    if (isBrowserEnv) {
        const base64Data = window.localStorage.getItem(HEADERS.USER_INFO);

        if (base64Data) {
            return JSON.parse(decodeURIComponent(escape(atob(base64Data))));
        }
    }

    return { name: '' };
};

export const setUserLocalInfo = (base64Data: string) => {
    window.localStorage.setItem(HEADERS.USER_INFO, base64Data);
};
