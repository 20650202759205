import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TempPasswordAuthorizationInitialState } from '#/src/store/redux/temp-password/constants';

export const tempPasswordAuthorizationSlice = createSlice({
    name: 'webAuthn',
    initialState: TempPasswordAuthorizationInitialState,
    reducers: {
        tempPasswordSet(state, action: PayloadAction<string>) {
            state.tempPassword = action.payload;
        },
        tempPasswordErrorSet(state, action) {
            state.error = action.payload;
        },
        tempPasswordUpdated(
            state,
            action: PayloadAction<Partial<typeof TempPasswordAuthorizationInitialState>>,
        ) {
            Object.assign(state, action.payload);
        },
    },
});

export const { tempPasswordSet, tempPasswordErrorSet, tempPasswordUpdated } =
    tempPasswordAuthorizationSlice.actions;

export default tempPasswordAuthorizationSlice.reducer;
