import React, { LegacyRef, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import { Typography } from '@alfalab/core-components/typography';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { fetchers } from '#/src/lib/client-api';
import { clientErrorLog } from '#/src/lib/client-logger';
import type { AppOptions } from '#/src/lib/is-broken-am-version';
import { isBrokenAmVersion } from '#/src/lib/is-broken-am-version';
import { ClientIds } from '#/src/models';
import {
    getQueryRedirectParams,
    selectIosAppIdRedirect,
    selectIosAppVersionRedirect,
    selectMultiFactorResponseParams,
    selectMultiFactorResponseRedirectUrl,
} from '#/src/store/redux/app/selectors';
import { preloaderHidden, preloaderShown } from '#/src/store/redux/app/slice';
import { getRegistrationRegType } from '#/src/store/redux/registration/selectors';
import { QueryRedirectParams } from '#/src/types/interfaces';

/**
 * Запоминаем устройство клиента при входе по-номеру телефона
 * чтобы при некорректном редиректе обратно мы могли обработать этот кейс
 * @param queryRedirectParams
 */
export const setWebviewFix = async (
    queryRedirectParams: QueryRedirectParams,
    appOptions: AppOptions,
) => {
    try {
        const {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            device_uuid,
        } = queryRedirectParams;

        if (isBrokenAmVersion(queryRedirectParams, appOptions)) {
            await fetchers.updateAmPhoneRedirectUrl({ device_uuid });
        }
    } catch (e: unknown) {
        const error = new Error(`Webview fix error: ${e}`);

        clientErrorLog(error);
    }
};

const FinishSignIn: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const authType = useAppSelector(getRegistrationRegType);
    const formParams = useAppSelector(selectMultiFactorResponseParams);
    const iosAppVersionRedirect = useAppSelector(selectIosAppVersionRedirect);
    const iosAppIdRedirect = useAppSelector(selectIosAppIdRedirect);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const redirectUrl = useAppSelector(selectMultiFactorResponseRedirectUrl);

    const arrayKeys = Object.keys(formParams);
    const finishSignInForm: LegacyRef<HTMLFormElement> = useRef(null);
    const hideLoader = queryRedirectParams.client_id === ClientIds.investmentsMobile;

    useEffect(() => {
        if (!hideLoader) {
            dispatch(preloaderShown());
        }
        if (redirectUrl) {
            (async () => {
                await setWebviewFix(queryRedirectParams, {
                    iosAppIdRedirect,
                    iosAppVersionRedirect,
                });
            })();
            trackUserEvent(
                'Auth Page',
                'Click',
                'Card Account Send',
                queryRedirectParams.client_id,
                'Auth finish success',
            );
            finishSignInForm?.current?.submit();
        } else {
            dispatch(preloaderHidden());
            navigate(-1);
        }

        return () => {
            dispatch(preloaderHidden());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <form method='get' ref={finishSignInForm} action={redirectUrl}>
            {hideLoader && (
                <Typography.TitleResponsive
                    view='large'
                    font='system'
                    weight='bold'
                    defaultMargins={false}
                    tag='h2'
                >
                    Пожалуйста, дождитесь перехода.
                </Typography.TitleResponsive>
            )}
            {arrayKeys.map((item) => (
                <input key={item} name={item} type='hidden' value={formParams[item]} />
            ))}
            <input name='auth_type' type='hidden' value={authType} />
        </form>
    );
};

export default FinishSignIn;
