import React, { FC, useCallback, useEffect, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { Notification } from '@alfalab/core-components/notification';

import { useAppSelector } from '#/src/hooks';
import initializeTraceId from '#/src/lib/initialize-trace-id';
import { selectIsMobile } from '#/src/store/redux/app/selectors';
import { HEADERS } from '#/src/utils';

import './server-errors-notification-new.css';

interface Props {
    errorMessage: string;
    onClose?(): void;
}

const cn = createCn('notification-new');
const ServerErrorNotificationsNew: FC<Props> = ({ errorMessage, onClose }) => {
    const isMobile = useAppSelector(selectIsMobile);
    const [shortTrace, setShortTrace] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const hideNotification = useCallback(() => {
        if (onClose) {
            onClose();
        }
        setIsVisible(false);
    }, [onClose]);

    useEffect(() => {
        const headers = initializeTraceId();

        setShortTrace(headers[HEADERS.TRACE_ID].split('-')[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (errorMessage) {
            setIsVisible(!!errorMessage);
        }

        return setIsVisible(!!errorMessage);
    }, [errorMessage]);

    if (errorMessage) {
        const text = errorMessage.split('\n');
        let title: string | undefined = 'Ошибка';

        if (text.length > 1) {
            title = text.shift()?.replace('.', '');
        }

        return (
            <Notification
                dataTestId='notification'
                badge='negative'
                title={title}
                offset={isMobile ? 16 : 108}
                visible={isVisible}
                onClose={hideNotification}
                autoCloseDelay={300000}
                onCloseTimeout={hideNotification}
                className={cn()}
            >
                {text} <br />
                {shortTrace && `Код ошибки: ${shortTrace}`}
            </Notification>
        );
    }

    return null;
};

export default ServerErrorNotificationsNew;
