import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import IconAccountDefault from 'arui-feather/icon/banking/account-default';
import IconCard from 'arui-feather/icon/banking/card';
import IconUser from 'arui-feather/icon/category/category-user';
import IconButton from 'arui-feather/icon-button';

import { useAppDispatch } from '#/src/hooks';
import { RegistrationType, Routes } from '#/src/models';
import { registrationTypeChanged } from '#/src/store/redux/registration/slice';

import './auth-factor-switcher.css';

const cn = createCn('factor-switcher');

interface Props {
    form: RegistrationType;
}

const AuthFactorSwitcher: FC<Props> = ({ form }) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const handleIconCardClick = () => {
        dispatch(registrationTypeChanged(RegistrationType.Card));
        navigate({
            pathname: Routes.CARD_ACCOUNT,
            search: location.search.toString(),
        });
    };

    const handleIconLoginClick = () => {
        navigate({
            pathname: Routes.AD_USERNAME_PASSWORD,
            search: location.search.toString(),
        });
    };

    const handleIconAccountClick = () => {
        dispatch(registrationTypeChanged(RegistrationType.Account));
        navigate({
            pathname: Routes.CARD_ACCOUNT,
            search: location.search.toString(),
        });
    };

    return (
        <div className={cn()}>
            <IconButton
                id='username-password-switcher'
                className={`${cn('icon')} ${form === 'LOGIN' ? 'active' : null}`}
                icon={<IconUser />}
                onClick={handleIconLoginClick}
            />
            <IconButton
                id='card-switcher'
                className={`${cn('icon')} ${form === 'CARD' ? 'active' : null}`}
                icon={<IconCard />}
                onClick={handleIconCardClick}
            />
            <IconButton
                id='account-switcher'
                className={`${cn('icon')} ${form === 'ACCOUNT' ? 'active' : null}`}
                icon={<IconAccountDefault />}
                onClick={handleIconAccountClick}
            />
        </div>
    );
};

export default AuthFactorSwitcher;
