import React, { FC } from 'react';

import { Toast, ToastProps } from '@alfalab/core-components/toast';
import { ToastMobile } from '@alfalab/core-components/toast/mobile';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { selectPincodeError, selectPincodeShowError } from '#/src/store/pincode/selectors';
import { pincodeUpdated } from '#/src/store/pincode/slice';
import { selectIsMobile } from '#/src/store/redux/app/selectors';

const CLOSE_DELAY = 2 * 1000;

export const ToastError: FC<Pick<ToastProps, 'anchorElement'>> = ({ anchorElement }) => {
    const dispatch = useAppDispatch();

    const showError = useAppSelector(selectPincodeShowError);
    const errorMessage = useAppSelector(selectPincodeError);
    const isMobile = useAppSelector(selectIsMobile);

    const onClose = () => {
        dispatch(pincodeUpdated({ showError: false, code: '' }));
    };

    const ToastComponent = isMobile ? ToastMobile : Toast;

    return (
        <ToastComponent
            dataTestId='toast-error'
            title={errorMessage}
            open={showError}
            block={true}
            useAnchorWidth={true}
            anchorElement={anchorElement}
            position='top'
            badge='negative-alert'
            onClose={onClose}
            autoCloseDelay={CLOSE_DELAY}
        />
    );
};
