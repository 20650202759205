import React, { FC, useState } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { Update } from 'history';

import { history } from '#/src/history';
import { useIsomorphicLayoutEffect } from '#/src/hooks';
import { checkContextRoot } from '#/src/lib/check-context-root';
import RouterRoutes from '#/src/routes/routes';
import { StoreType } from '#/src/store';

interface RootType {
    store: StoreType;
}

const BrowserRoot: FC<RootType> = ({ store }) => {
    const [state, setState] = useState({ location: history.location, action: history.action });
    const {contextRoot} = store.getState().Settings;
    const routerLocation = checkContextRoot(state.location, contextRoot);

    useIsomorphicLayoutEffect(() => {
        const unListen = history.listen((update: Update) => {
            setState({ location: update.location, action: update.action });
        });

        return unListen;
    }, []);

    return (
        <Provider store={store}>
            <Router
                basename={contextRoot}
                location={routerLocation}
                navigator={history}
                navigationType={state.action}
            >
                <RouterRoutes />
            </Router>
        </Provider>
    );
};

export default BrowserRoot;
