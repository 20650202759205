import React, { ChangeEvent, useState } from 'react';
import { createCn } from 'bem-react-classname';

import IconHidden from 'arui-feather/icon/action/password-hide';
import IconVisible from 'arui-feather/icon/action/password-show';
import IconKeyboard from 'arui-feather/icon/entity/keyboard';
import IconButton from 'arui-feather/icon-button';
import Input, { InputProps } from 'arui-feather/input';

import addAttributes from '#/src/utils/decorators/add-attributes';

import './input-password.css';

interface Props extends InputProps {
    visibleKeyboard: (value: boolean) => void;
    visibleIcon: boolean;
    onInputChange: (value: string, event: ChangeEvent) => void;
    password: string;
    showEye: boolean;
    onBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event?: React.FocusEvent<any>) => void;
    inputRef?: React.Ref<any>;
}

const AttributedIconButton = addAttributes(IconButton, [
    { name: 'aria-label', value: 'Показать клавиатуру' },
]);

const cn = createCn('input-password');

const InputPassword: React.FC<Props> = (props) => {

    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
    const [isKeyboardIconVisible, setIsKeyboardIconVisible] = useState(props.visibleIcon);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePassVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible)
    };

    const handleChange = (value?: string, event?: ChangeEvent<any>) => {
        if (event) {
            props.onInputChange(value || '', event);
        }
    };

    const handleIconClick = () => {
        props.visibleKeyboard(isKeyboardVisible);
        setIsKeyboardVisible(!isKeyboardVisible);
    };

    const handleInputBlur = (event?: React.FocusEvent<HTMLInputElement>) => {
        if (props.onBlur) {
            const target = event?.relatedTarget as HTMLInputElement;

            props.onBlur(event);

            if (target.id === 'password-show-hide') {
                togglePassVisibility();
            }
        }
    };

    const handleInputFocus = (event?: React.FocusEvent<any>) => {
        setIsKeyboardIconVisible(true);

        if (props.onFocus && event) {
            props.onFocus(event);
        }
    };

    const inputProps = {
        ...props,
    };

    return (
        <div className={cn()}>
            <Input
                type={isPasswordVisible ? 'text' : 'password'}
                data-test-id='passField'
                value={props.password}
                icon={
                    <React.Fragment>
                        {isKeyboardIconVisible && props.showEye ? (
                            <AttributedIconButton
                                id='password-show-hide'
                                disabled={!(props.password.length > 0)}
                                icon={
                                    isPasswordVisible ? (
                                        <IconVisible size='m' />
                                    ) : (
                                        <IconHidden size='m' />
                                    )
                                }
                                onClick={togglePassVisibility}
                            />
                        ) : null}
                        {isKeyboardIconVisible ? (
                            <AttributedIconButton
                                data-test-id='passKeyboardBtn'
                                tabIndex={-1}
                                onMouseDown={handleIconClick}
                                id='keyboard-icon'
                            >
                                <IconKeyboard size='m' />
                            </AttributedIconButton>
                        ) : null}
                    </React.Fragment>
                }
                onBlur={handleInputBlur}
                onChange={handleChange}
                onFocus={handleInputFocus}
                ref={props.inputRef}
                {...inputProps}
            />
        </div>
    );
}

export default InputPassword;
