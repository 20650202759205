import React, { FC } from 'react';
import { useNavigate } from 'react-router';

import { ChevronLeftMediumMIcon } from '@alfalab/icons-glyph/ChevronLeftMediumMIcon';

interface Props {
    onClick?: () => void;
}

export const BackButton: FC<Props> = ({ onClick }) => {
    const navigate = useNavigate();
    const handleClick = onClick || (() => navigate(-1));

    return (
        <div style={{ paddingRight: '8px' }} data-test-id='back-button'>
            <ChevronLeftMediumMIcon onClick={handleClick} />
        </div>
    );
};
