import React from 'react';
import { createCn } from 'bem-react-classname';

import { MarkdownMobile } from '@alfalab/core-components/markdown/mobile';

import UpdateAppImage from './update-app-image';

import './update-banner.css';

const cn = createCn('update-banner');

export const UpdateAppBunner = () => (
    <div className={cn('container')}>
        <div className={cn('image-app-update')}>
            <UpdateAppImage />
        </div>

        <MarkdownMobile>
            {`
# Обновите приложение до\u00A0актуальной версии
Обновление Альфа-Мобайл необходимо для улучшения безопасности и\u00A0исправления возможных ошибок. Новая версия доступна на\u00A0сайте.
			`}
        </MarkdownMobile>
    </div>
);
